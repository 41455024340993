import { Layout, Tabs } from 'antd'

import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import 'moment/locale/vi'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks'
import { tabConfigPrice } from '../../helpers/constants'
import ContentFee from './Content'
import { useEffect } from 'react'
const { Content } = Layout
const TabPane = Tabs.TabPane

const PriceManagement = observer((props: any) => {
   const { t } = useTranslation()
   const CommonConfigStore = useStore('CommonConfigStore')
   const UserStore = useStore('UserStore')

   const onChangeTab = (key: string) => {
      CommonConfigStore.setActiveKeyMain(key)
   }

   useEffect(() => {
      if (CommonConfigStore.activeKeyMain == tabConfigPrice.seller) {
         //get list seller neu chua co
         if (!UserStore.listSeller.rows || UserStore.listSeller.rows.length == 0) {
            UserStore.getSeller()
         }
      } else {
         if (!UserStore.listFulfillment.rows || UserStore.listFulfillment.rows.length == 0) {
            UserStore.getFulfillment()
         }
      }
   }, [CommonConfigStore.activeKeyMain])
   return (
      <Content {...props}>
         <Tabs activeKey={CommonConfigStore.activeKeyMain} onChange={onChangeTab}>
            <TabPane tab={t(GLOBAL_CLIENT.mSeller)} key={tabConfigPrice.seller}>
               <ContentFee {...props} idTab={tabConfigPrice.seller} key={tabConfigPrice.seller} listFilter={[{id:'',name:'All',},...UserStore.listSeller.rows]} />
            </TabPane>
            <TabPane tab={t(GLOBAL_CLIENT.mFulfillment)} key={tabConfigPrice.fulfillment}>
               <ContentFee {...props} idTab={tabConfigPrice.fulfillment} key={tabConfigPrice.fulfillment} listFilter={[{id:'',name:'All',},...UserStore.listFulfillment.rows]}  />
            </TabPane>
         </Tabs>
      </Content>
   )
})

export default PriceManagement
