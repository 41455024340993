import { useEffect } from 'react'
import { Route, Redirect, withRouter, RouteProps, Switch, useHistory } from 'react-router-dom'
import 'moment/locale/vi'
import { observer } from 'mobx-react-lite'
import { useStore } from 'src/hooks'
import { LOCAL_STORAGE, ROLE, ROLES, STATUS_LOGIN } from './helpers/constants'
import { Loading } from 'src/common'
import LayoutAccountant from 'src/screens/layouts/containers/LayoutAccountant'
import LoginScreen from 'src/screens/authenticate/LoginScreen'
import SignupCreatorScreen from 'src/screens/authenticate/SignupCreatorScreen'
import SignupDesignerScreen from 'src/screens/authenticate/SignupDesignerScreen'
import ForgotPasswordScreen from 'src/screens/authenticate/ForgotPasswordScreen'
import VerifyEmailScreen from 'src/screens/verify-email/VerifyEmailScreen'
import ResendEmailScreen from 'src/screens/verify-email/ResendEmailScreen'
import LayoutAdmin from 'src/screens/layouts/containers/LayoutAdmin'
import { isEmpty, noop } from 'lodash'
import ResetPasswordScreen from 'src/screens/authenticate/ResetPasswordScreen'
import LayoutSeller from './screens/layouts/containers/LayoutSeller'
import LayoutFulfillment from './screens/layouts/containers/LayoutFulfillment'
import LayoutManagerStock from './screens/layouts/containers/LayoutManagerStock'
import LayoutManagerOrder from './screens/layouts/containers/LayoutManagerOrder'
import LayoutNcc from './screens/layouts/containers/LayoutNcc'
interface ProtectedRouteProps extends RouteProps {
   // tslint:disable-next-line:no-any
   component: any
   isSignedIn: number
   role?: string
   currentRole: string
}

const App = observer((props) => {
   const AuthStore: any = useStore('AuthStore')
   const CommonConfigStore = useStore('CommonConfigStore')
   const role: any = AuthStore.data_role
   const history = useHistory()

   useEffect(() => {
      if (process.env.REACT_APP_ENV !== 'DEV') {
         // eslint-disable-next-line no-console
         console.log = noop
         // eslint-disable-next-line no-console
         console.warn = noop
         // eslint-disable-next-line no-console
         console.error = noop
      }
      handle_fetchData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      // AuthStore.isLogin = STATUS_LOGIN.LOGIN_SUCCESS;
   }, [])

   const getCommonData = async () => {
      if (role) {
         // if (size(CommonConfigStore.designTypesList) === 0 && role !== ROLE.ACCOUNTANT) {
         //    await CommonConfigStore.getDesignTypeList()
         // }
         // if (size(CommonConfigStore.productTypesList) === 0 && role !== ROLE.ACCOUNTANT) {
         //    await CommonConfigStore.getProductTypeList()
         // }
         if (role == ROLES.FULFILLMENT || role == ROLES.SELLER||role==ROLES.ADMIN) {
            await CommonConfigStore.getConfigDesigner()
         }
      }
   }

   useEffect(() => {
      if (AuthStore.isLogin === STATUS_LOGIN.LOGIN_SUCCESS) {
         AuthStore.action_getRole(history).then((res: any) => {
            if (!res) {
               // JobsStore.resetJobs()
               return
            }
            getCommonData()
         })
      }
   }, [AuthStore.isLogin, AuthStore.data_role, AuthStore, history])

   useEffect(() => {
      window.addEventListener('resize', () => {
         AuthStore.width_screen = window.innerWidth
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const handle_fetchData = async () => {
      // if (!CommonConfigStore.linkSupport) {
      //    CommonConfigStore.getListSupport()
      // }
      const dataAuth = await localStorage.getItem(LOCAL_STORAGE.DATA_AUTH)

      if (!isEmpty(dataAuth) && dataAuth !== '{}') {
         AuthStore.isLogin = STATUS_LOGIN.LOGIN_SUCCESS
      } else {
         AuthStore.isLogin = STATUS_LOGIN.NOT_LOGIN
      }
   }

   const isAdminRoute = () => {
      if (role === ROLES.ADMIN) return true
      return false
   }
   const isSellerRoute = () => {
      if (role === ROLES.SELLER) return true
      return false
   }
   const isFulfillmentRoute = () => {
      if (role === ROLES.FULFILLMENT) return true
      return false
   }

   const isAccountantRoute = () => {
      if (role === ROLES.ACCOUNTANT) return true
      return false
   }

   const isSupporterRoute = () => {
      if (role === ROLE.SUPPORTER) return true
      return false
   }
   const isNccRoute = () => {
      if (role === ROLES.NCC) return true
      return false
   }
   const isManagerStock = () => {
      if (role === ROLES.MANAGER_STOCK) return true
      return false
   }
   const isManagerOrder = () => {
      if (role === ROLES.MANAGER_ORDER) return true
      return false
   }

   return (
      <>
         <Switch>
            <Route
               exact
               path="/signin"
               component={LoginScreen}
               render={() => {
                  return AuthStore.isLogin === STATUS_LOGIN.LOGIN_SUCCESS ? (
                     <Redirect to="/" />
                  ) : (
                     <Redirect to="/signin" />
                  )
               }}
            />
            <Route
               path="/huongdan"
               component={() => {
                  window.location.href =
                     'https://docs.google.com/document/d/1qLhZOh4WEAlWNs_lgayc-tTGk--yRm1aFBoZiBWgDAQ/edit'
                  return null
               }}
            />
            <Route exact path="/signup-creator" component={SignupCreatorScreen} />
            <Route exact path="/signup-designer" component={SignupDesignerScreen} />
            <Route exact path="/forgot-password" component={ForgotPasswordScreen} />
            <Route exact path="/reset-password" component={ResetPasswordScreen} />
            <Route exact path="/verify-email" component={VerifyEmailScreen} />
            <Route exact path="/resend-verify-email" component={ResendEmailScreen} />

            <Switch>
               {isAdminRoute() ? (
                  <ProtectedRoute
                     isSignedIn={AuthStore.isLogin}
                     path="/"
                     component={LayoutAdmin}
                     role={ROLES.ADMIN}
                     currentRole={role}
                  />
               ) : isSellerRoute() ? (
                  <ProtectedRoute
                     isSignedIn={AuthStore.isLogin}
                     path="/"
                     component={LayoutSeller}
                     role={ROLES.SELLER}
                     currentRole={role}
                  />
               ) : isFulfillmentRoute() ? (
                  <ProtectedRoute
                     isSignedIn={AuthStore.isLogin}
                     path="/"
                     component={LayoutFulfillment}
                     role={ROLES.FULFILLMENT}
                     currentRole={role}
                  />
               ) : isAccountantRoute() ? (
                  <ProtectedRoute
                     isSignedIn={AuthStore.isLogin}
                     path="/"
                     component={LayoutAccountant}
                     role={ROLES.ACCOUNTANT}
                     currentRole={role}
                  />
               ) : isManagerOrder() ? (
                  <ProtectedRoute
                     isSignedIn={AuthStore.isLogin}
                     path="/"
                     component={LayoutManagerOrder}
                     role={ROLES.MANAGER_ORDER}
                     currentRole={role}
                  />
               ) : isManagerStock() ? (
                  <ProtectedRoute
                     isSignedIn={AuthStore.isLogin}
                     path="/"
                     component={LayoutManagerStock}
                     role={ROLES.MANAGER_STOCK}
                     currentRole={role}
                  />
               ) : isNccRoute() ? (
                  <ProtectedRoute
                     isSignedIn={AuthStore.isLogin}
                     path="/"
                     component={LayoutNcc}
                     role={ROLES.NCC}
                     currentRole={role}
                  />
               ) : (
                  <ProtectedRoute
                     isSignedIn={AuthStore.isLogin}
                     path="/"
                     component={LayoutNcc}
                     role={ROLES.NCC}
                     currentRole={role}
                  />
               )}
            </Switch>
            <Route path="*" component={LoginScreen} />
         </Switch>
      </>
   )
})

const ProtectedRoute = (props: ProtectedRouteProps) => {
   const { component: Component, isSignedIn, role, currentRole, ...rest } = props
   const isAuthenticate = () => {
      if (currentRole !== role) return false
      return true
   }
   return (
      <Route
         {...rest}
         render={(routeProps) => {
            if (isSignedIn === STATUS_LOGIN.WAIT_LOGIN) {
               return <Loading isLoading={true} />
            } else if (isSignedIn === STATUS_LOGIN.LOGIN_SUCCESS) {
               if (isAuthenticate()) return <Component {...routeProps} />
               else return <></>
            } else {
               return (
                  <Redirect
                     to={{
                        pathname: '/signin',
                        state: { from: routeProps.location }
                     }}
                  />
               )
            }
         }}
      />
   )
}

export default withRouter(App)
