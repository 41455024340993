import { isEmpty } from 'lodash'
import { observable, action } from 'mobx'
import { PAGE_SIZE } from '../helpers/constants'
import api from '../common/api'
import { Request2 } from 'src/common/Request2'
import { responseDefault } from './store.constants'
import { showMessageError } from 'src/helpers/functions'

export class OrdersStore {
   @observable orders: any = { ...responseDefault }
   @observable ordersRecruiting: any = { ...responseDefault } //NEW: 0,
   @observable ordersProcessing: any = { ...responseDefault } //    DOING: 1,
   @observable ordersReview: any = { ...responseDefault } //    REVIEW: 2,
   @observable ordersEditing: any = { ...responseDefault } //    REJECT: 3,
   @observable ordersDone: any = { ...responseDefault } //    FINISH: 4,
   @observable ordersCount: any = {}
   @observable textSearch: string = ''
   @observable fieldFilter: any = {}
   @observable ordersTotal: any = {}
   @observable products: any = { ...responseDefault }
   @observable listStores: any = []
   @observable productsSelected: any = []
   @observable orderDetail: any = {}
   @observable sellerId: any = null
   @observable dataExport: any = { ...responseDefault }
   @observable shippingFee: number = 0

   @action
   async getOrderList(params: any = {}) {
      const result = await Request2.getWithToken(api.order.getList, { ...params, ...{ limit: PAGE_SIZE } })
      if (!isEmpty(result)) {
         this.orders = result
      }
   }

   @action
   async getProductsList(params: any = {}) {
      const result = await Request2.getWithToken(api.products.getList, { ...params })
      if (!isEmpty(result)) {
         this.products = result
      }
   }
   @action
   async getListStores(id: string) {
      const result = await Request2.getWithToken(api.order.listStore + `/${id}`)
      if (!isEmpty(result)) {
         const dt = result.filter((it: any) => !it?.disable)
         this.listStores = dt
      }
   }

   async getListStoreMultiOrder(orderIds: any = {}) {
      const result = await Request2.getWithToken(api.order.listStoreMultiOrder, orderIds)
      if (!isEmpty(result)) {
         const dt = result.filter((it: any) => !it?.disable)
         this.listStores = dt
      }
   }

   @action
   async getProducts(params: any = {}) {
      let param = { ...params }
      // if (param?.isPriority) {
      //    param.isPriority = true
      // } else {
      //    delete param['isPriority']
      // }
      const result = await Request2.getWithToken(api.products.getList, { ...param, ...{ limit: PAGE_SIZE } })

      if (!isEmpty(result)) {
         this.products = result
      } else {
         this.products = { ...responseDefault }
      }
   }

   @action
   setProductsSelected(list: any) {
      if (list.length == 0) {
         this.productsSelected.length = 0
         return
      }
      this.productsSelected = [...list]
   }

   @action
   removeProduct(index: number) {
      this.productsSelected.splice(index, 1)
   }

   @action
   async createOrder(params: any = {}) {
      const result = await Request2.postWithToken(params, api.order.create)
      return result
   }
   @action
   async updateTrackingId(id: string, trackingId: string) {
      const result = await Request2.postWithToken({ trackingId }, api.order.update + `/${id}`)
      return result
   }
   @action
   async cancelOrder(id: string) {
      const result = await Request2.postWithToken({}, api.order.cancel + `/${id}`)
      return result
   }
   @action
   async confirmOrder(params: any) {
      const result = await Request2.postWithToken(params, api.order.confirm)
      return result
   }
   @action
   async assigneeOrder(params: any) {
      const result = await Request2.postWithToken(params, api.order.assignee)
      return result
   }

   @action
   async getDetail(id: any) {
      const result = await Request2.getWithToken(api.order.detail + `/${id}`)
      return result
   }

   @action
   async finishOrder(id: string) {
      const result = await Request2.postWithToken({}, api.order.finishOrder + `/${id}`)
      return result
   }

   @action
   async setSellerId(id: any) {
      if (this.sellerId == id) return (this.sellerId = null)

      this.sellerId = id
   }
   @action
   async setOrderList(list: any) {
      if (!list || list.length == 0) return
      this.orders = list
   }

   @action
   async exportExcel(param: any = {}) {
      const result = await Request2.getWithToken(api.order.exportExcel, { ...param })

      if (!isEmpty(result)) {
         this.dataExport = result
      }
   }
   async removeOrder(id: string) {
      const result = await Request2.postWithToken({}, api.order.removeOrder + `/${id}`)
      return result
   }

   @action
   async getShippingFee(params: any) {
      const result = await Request2.postWithToken(params, api.order.getShipingFee)

      if (!isEmpty(result)) {
         this.setShippingFee(result.data)
      }
   }

   @action
   setShippingFee(fee: number) {
      this.shippingFee = fee
   }

   @action
   async refundOrder(id: string) {
      const result = await Request2.postWithToken({}, api.order.refundOrder + `/${id}`)
      return result
   }
}
