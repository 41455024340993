import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks'
import { DATE_FORMAT_CLIENT_PARAMS, DATE_TIME_FORMAT_CLIENT, ROLES } from '../../helpers/constants'
import { useTranslation } from 'react-i18next'
import { Button, Space } from 'antd'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
// @ts-ignore
import ReactExport from 'react-data-export'
import moment from 'moment'
import { useRef, useState } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import * as XLSX from 'xlsx'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const ExportToCSV = observer((props: any) => {
   const { from: fromDate, to: toDate } = props
   const { t } = useTranslation()
   const AuthStore = useStore('AuthStore')
   const { data_getUserInfo } = AuthStore
   const OrdersStore = useStore('OrdersStore')
   const { rows } = OrdersStore.dataExport
   const exportRef = useRef(null)
   const inputFileRef = useRef(null)

   const handleExportData = async () => {
      try {
         const from = moment(fromDate).format(DATE_FORMAT_CLIENT_PARAMS)
         const to = moment(toDate).format(DATE_FORMAT_CLIENT_PARAMS)

         await OrdersStore.exportExcel({ from, to })
         if (exportRef.current !== null) {
            // @ts-ignore
            exportRef.current!.click()
         }
      } catch (error) {}
   }

   const handleInputFile = () => {
      if (inputFileRef.current !== null) {
         // @ts-ignore
         inputFileRef.current!.click()
      }
   }

   const handleImportCSV = (event: any) => {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = (e: any) => {
         const data = new Uint8Array(e.target.result)
         const workbook = XLSX.read(data, { type: 'array' })
         const sheetName = workbook.SheetNames[0]
         const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName])
         console.log(worksheet) // Kiểm tra dữ liệu đã đọc từ file Excel

         // OrdersStore.setOrderList(worksheet)
      }
      reader.readAsArrayBuffer(file)
   }

   const exportToCSV = () => {
      return (
         <ExcelFile
            element={
               <Button ref={exportRef}>
                  {t(GLOBAL_CLIENT.exportCSV)}
                  <DownloadOutlined />
               </Button>
            }
            filename={`Đơn đặt hàng ${moment().format('HH:mm:ss DD-MM-YYYY')}`}
         >
            <ExcelSheet com data={rows} name="PurchaseOrderManagement">
               <ExcelColumn label={'OrderID'} value="id" />
               <ExcelColumn
                  label={'Ngày tạo'}
                  value={(col: any) => moment(col?.createdAt).format(DATE_TIME_FORMAT_CLIENT)}
               />
               <ExcelColumn label={'Seller OrderID'} value="sellerOrderId" />
               <ExcelColumn label={'Product SKU'} value="shipLabel" />
               <ExcelColumn label={'QTY'} value="qty" />
               <ExcelColumn label={'Ship to'} value="shipLabel" />
               <ExcelColumn label={'Seller note'} value="shipLabel" />
               <ExcelColumn label={'Base cost'} value="packingFee" />
               <ExcelColumn label={'Shipping cost'} value="shippingFee" />
               <ExcelColumn label={'Total'} value="totalPrice" />
               <ExcelColumn label={'Tracking ID'} value="trackingId" />
               <ExcelColumn label={'Status tracking'} value="trackingStatus" />
               <ExcelColumn label={'Status order'} value="status" />
            </ExcelSheet>
         </ExcelFile>
      )
   }
   return (
      <Space>
         <Button className="btn-add" onClick={handleInputFile}>
            {'Nhập Excel'}
         </Button>
         {data_getUserInfo?.role == ROLES.ADMIN && (
            <Button onClick={handleExportData}>
               {t(GLOBAL_CLIENT.exportExcel)}
               <DownloadOutlined />
            </Button>
         )}
         <div style={{ display: 'none' }}>
            <input ref={inputFileRef} type="file" accept=".xlsx, .xls" onChange={handleImportCSV} />
            {exportToCSV()}
         </div>
      </Space>
   )
})

export default ExportToCSV
