import { Avatar, Button, Card, Col, Input, Pagination, Row, Space, Typography, Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import { CONFIG_URL, PAGE_SIZE, getPage } from '../../helpers/constants'
import { capitalize, isEmpty, size, trim } from 'lodash'
import { useEffect, useState } from 'react'
import { useStore } from 'src/hooks'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import addProduct from 'src/assets/images/add-product.svg'
import DetailProduct from './DetailProduct'
import CreateOrder from '../order/Create'
import SelectProductModal from '../order/SelectProductModal'
import { formatCurrency } from 'src/helpers/functions'
const ListProducts = observer(() => {
   const CommonConfigStore = useStore('CommonConfigStore')
   const OrdersStore = useStore('OrdersStore')
   const AuthStore = useStore('AuthStore')
   const { data_getUserInfo } = AuthStore
   const ProductStore = useStore('ProductStore')
   const { rows, offset, count } = ProductStore.products
   const { rows: rowTag } = CommonConfigStore.tagList
   const { t } = useTranslation()
   const { Text } = Typography
   const [fieldFilter, setFieldFilter] = useState<any>([])
   const [textSearch, setTextSearch] = useState('')
   const [visibleDetail, setVisibleDetail] = useState(false)
   const [visibleCreate, setVisibleCreate] = useState(false)
   const [visibleProductModal, setVisibleProductModal] = useState(false)
   const [item, setItem] = useState(null)

   const ListProductsWrapper = styled.div`
      .ant-card-cover {
         aspect-ratio: 0.8;
      }

      .ant-card-body {
         padding: 10px;
         .ant-space-item {
            width: 100%;
         }
      }
      .btn-create {
         height: 30px;
         width: 30px;
         cursor: pointer;
      }
   `

   if (!rowTag || rowTag.length < 1) {
      const offset = getPage(0)
      const params = { offset, limit: 1000 }
      //  console.log('params', params)
      CommonConfigStore.getTagList(params)
   }

   useEffect(() => {
      console.log('---fieldFilter', fieldFilter)
      let tags = ''
      if (fieldFilter && fieldFilter.length > 0) {
         tags = fieldFilter.toString()
      }
      getListData(0, {
         ...{ tags },
         ...{ name: trim(textSearch) }
         // ...{ status: statusResult }
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [textSearch, fieldFilter])

   const renderPagination = () => {
      if (isEmpty(rows)) {
         return []
      }

      return (
         <div className="containerPagination">
            <Pagination
               onChange={onChange}
               pageSize={PAGE_SIZE}
               current={offset / PAGE_SIZE + 1}
               showSizeChanger={false}
               total={count}
            />
         </div>
      )
   }
   const onChange = (currentPage: number) => {
      let tags = ''
      if (fieldFilter && fieldFilter.length > 0) {
         tags = fieldFilter.toString()
      }
      getListData(currentPage - 1, { ...{ tags }, ...{ name: trim(textSearch) } })
   }

   const getListData = async (currentPage: number, param?: any) => {
      try {
         // setIsLoading(true)
         const offset = getPage(currentPage, PAGE_SIZE)
         const params = { offset, ...param, status: 1 }

         await ProductStore.getProducts(params)
      } catch (error) {
         // console.log(error)
      } finally {
         // setIsLoading(false)
      }
   }

   const handleChangeTextSearch = (value: any) => {
      console.log('value', value)

      setTextSearch(value)
   }
   const handleSelectTag = (tagId?: any) => {
      if (!tagId) return setFieldFilter([])
      const x = [...fieldFilter]
      const index = x.findIndex((item: any) => item === tagId)
      if (index >= 0) {
         x.splice(index, 1)
      } else {
         x.push(tagId)
      }
      setFieldFilter(x)
   }
   const handleEdit = () => {
      let tags = ''
      if (fieldFilter && fieldFilter.length > 0) {
         tags = fieldFilter.toString()
      }
      if (size(trim(textSearch)) > 0) {
         getListData(0, { ...{ tags }, ...{ name: trim(textSearch) } })
      } else {
         getListData(0, { ...{ tags } })
      }
      setVisibleDetail(false)
      setItem(null)
   }

   const hideDetail = () => {
      setVisibleDetail(false)
      setItem(null)
   }
   const showDetail = (item: any) => {
      setVisibleDetail(true)
      setItem(item)
   }
   const handleOrderCreateOK = (isEdit: boolean = false) => {
      //gọi lại API list nếu cần sửa gì đó
      setVisibleCreate(false)
   }
   const handleCreate = (event: any, product: any) => {
      //gọi lại API list nếu cần sửa gì đó
      if (event.target == event.currentTarget) {
         event.stopPropagation()
      }
      setItem(product)
      OrdersStore.setProductsSelected([product])
      setVisibleCreate(true)
   }

   return (
      <ListProductsWrapper>
         <Row>
            <Col span={24}>
               <Row className="row-container-head">
                  <Col xs={24} md={8}>
                     <Input.Search
                        onSearch={handleChangeTextSearch}
                        placeholder={t(GLOBAL_CLIENT.search)}
                        allowClear
                        enterButton
                     />
                  </Col>
               </Row>
               <Row style={{ marginBottom: '25px' }}>
                  <Col xs={24} md={24}>
                     <Button
                        style={{ padding: '0 20px' }}
                        type={!fieldFilter.length ? 'primary' : 'default'}
                        onClick={() => handleSelectTag()}
                     >
                        Tất cả
                     </Button>
                     {rowTag.map((tag: any, index: number) => (
                        <Button
                           key={index}
                           style={{ padding: '0 20px' }}
                           type={fieldFilter.includes(tag?.id) ? 'primary' : 'default'}
                           onClick={() => handleSelectTag(tag?.id)}
                        >
                           {capitalize(tag?.name)}
                        </Button>
                     ))}
                  </Col>
               </Row>
               <Row>
                  {rows &&
                     size(rows) > 0 &&
                     rows?.map((item: any, index: any) => (
                        <Col key={index} style={{ padding: '0 7px' }} xs={12} sm={8} md={6} xl={4} xxl={3}>
                           <Card
                              onClick={() => showDetail(item)}
                              className="gx-product-item"
                              cover={
                                 <img
                                    style={{
                                       width: '100%',
                                       height: '100%',
                                       objectFit: 'cover',
                                       verticalAlign: 'middle'
                                    }}
                                    alt="images"
                                    src={item?.images?.length ? CONFIG_URL.IMAGE_URL + '/' + item?.images[0] : ''}
                                 />
                              }
                           >
                              <div>
                                 <Space
                                    direction="vertical"
                                    size="small"
                                    className="gx-flex-column gx-align-items-center gx-justify-content-center "
                                 >
                                    <span className="text-oneline" style={{ marginBottom: '0px', fontSize: '14px' }}>
                                       <Tooltip placement="top" title={item?.name}>
                                          {item?.name}
                                       </Tooltip>
                                    </span>
                                    <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
                                       <Text>${formatCurrency(item?.base_cost)}</Text>
                                       <Tooltip placement="top" title={`Tạo đơn`}>
                                          <img
                                             className="btn-create"
                                             src={addProduct}
                                             alt="add product"
                                             onClick={(e) => handleCreate(e, item)}
                                          />
                                       </Tooltip>
                                    </div>
                                 </Space>
                              </div>
                           </Card>
                        </Col>
                     ))}
               </Row>
               {renderPagination()}
            </Col>
         </Row>

         {visibleCreate && (
            <CreateOrder
               visible={visibleCreate}
               onOk={() => handleOrderCreateOK()}
               onCancel={() => setVisibleCreate(false)}
               onShowProducts={() => setVisibleProductModal(true)}
            />
         )}
         {visibleProductModal && (
            <SelectProductModal
               visible={visibleProductModal}
               onOk={() => setVisibleProductModal(false)}
               onCancel={() => setVisibleProductModal(false)}
            />
         )}
         {visibleDetail && item && (
            <DetailProduct visible={visibleDetail} item={item} onOk={handleEdit} onCancel={hideDetail} />
         )}
      </ListProductsWrapper>
   )
})

export default ListProducts
