import { Route, Switch } from 'react-router-dom'
import NotFoundPage from '../404/NotFoundPage'
import DefaultFulfillmentScreen from '../default/DefaultFulfillmentScreen'
import { ROUTER_PATH } from './metaData'
import ProfileScreen from '../profile'
import ProductsManagement from '../products'
import OrderManagement from '../order'
import ReportManagement from '../report'
import WarehouseManagement from '../warehouse-management'
import SellerManagement from '../seller-management'
import FulfillmentManagement from '../fulfillment-management'
import TagManagement from '../tag-management'
import ListProductsManagement from '../products/ListProducts'
import FundManagement from '../fund-management'
import SupplierManagement from '../supplier-management'
import PriceManagement from '../price-management'
import IntroPage from '../Intro/Intro'
const FulfillmentRouters = (props: any) => {
   return (
      <Switch>
         <Route exact path={`/`} component={DefaultFulfillmentScreen} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.IntroPage}`} component={IntroPage} />

         <Route exact path={`${props.match.url}${ROUTER_PATH.SellerManagementScreen}`} component={SellerManagement} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.SupplierManagement}`} component={SupplierManagement} />
         <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.FulfillmentManagementScreen}`}
            component={FulfillmentManagement}
         />
         <Route exact path={`${props.match.url}${ROUTER_PATH.TagManagementScreen}`} component={TagManagement} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.PriceManagement}`} component={PriceManagement} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.ProfileScreen}`} component={ProfileScreen} />

         <Route exact path={`${props.match.url}${ROUTER_PATH.ProductsManagement}`} component={ProductsManagement} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.OrderManagement}`} component={OrderManagement} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.ReportManagement}`} component={ReportManagement} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.WarehouseManagement}`} component={WarehouseManagement} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.FundManagement}`} component={FundManagement} />
         <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.ListProductsManagement}`}
            component={ListProductsManagement}
         />

         <Route path="*" component={NotFoundPage} />
      </Switch>
   )
}

export default FulfillmentRouters
