import { useEffect, useRef, useState } from 'react'
import { Input, Modal, Form, Button, Col, Row } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../hooks'
import { getPage } from '../../helpers/constants'
import { cloneDeep, filter, trim } from 'lodash'
import styled from 'styled-components'
import CardItems from 'src/common/components/CardItems'
import { Empty } from 'antd'

const formItemLayout = {
   labelCol: {
      xs: { span: 24 },
      sm: { span: 2 }
   },
   wrapperCol: {
      xs: { span: 24 },
      sm: { span: 22 }
   }
}

export const ModalSelectorWapper = styled.div`
   .cursor-pointer {
      cursor: pointer;
   }
   @media screen and (max-width: 820px) {
   }
`
const SelectProductModal = observer((props: any) => {
   const { t } = useTranslation()

   const { item, visible, onOk, onCancel, exportProduct } = props
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const WarehouseStore = useStore('WarehouseStore')
   const { listProducts, listExportProducts } = WarehouseStore
   const [form] = Form.useForm()
   const [productsSelected, setProductsSelected] = useState<any[]>([])
   const [textSearch, setTextSearch] = useState('')
   const [listProductExportFilter, setListProductExportFilter] = useState(cloneDeep(listExportProducts))
   
   useEffect(() => {
      if (WarehouseStore.productsSelected.length) setProductsSelected(cloneDeep(WarehouseStore.productsSelected))
   }, [])
   const handleChangeTextSearch = (value: any = '') => {
      setTextSearch(trim(value))

      if (exportProduct && value)
         return setListProductExportFilter(
            listExportProducts.filter((i: any) => i.name.toUpperCase().includes(value.toUpperCase()))
         )

      if (exportProduct && !value) return setListProductExportFilter(listExportProducts)
         
      const offset = getPage(0)
      const params = { offset, name: trim(value) }
      WarehouseStore.getListProducts(params)
   }

   const handSelectProduct = (item: any) => {
      handleSelectProducts(item)
   }

   const handleSelectProducts = (item: any) => {
      item.qty = item.qty ? item.qty : 1
      if (exportProduct) {
         item.price = item.price ? item.price : ''
      }
      const isProductExist = productsSelected.findIndex((p: any) => p.id === item.id)

      if (isProductExist > -1) return setProductsSelected(productsSelected.filter((p: any) => p.id !== item.id))

      setProductsSelected((prev: any) => [...prev, item])
   }

   const onSave = () => {
      const newData = productsSelected.map((p: any, index: number) => {
         return {
            ...p,
            key: index
         }
      })
      WarehouseStore.setProductsSelected(newData)
      onOk()
   }

   const renderContent = () => {
      return (
         <ModalSelectorWapper>
            <Form {...formItemLayout} form={form} name="CreatorDetail" scrollToFirstError>
               <Row>
                  <Col span={24}>
                     <Input.Search placeholder="Tên sản phẩm" allowClear onSearch={handleChangeTextSearch} />
                  </Col>
               </Row>
            </Form>
            {(exportProduct && listProductExportFilter?.length) || (!exportProduct && listProducts?.length) ? (
               <CardItems
                  type={exportProduct ? 'view' : ''}
                  listCard={exportProduct ? listProductExportFilter : listProducts}
                  onSelect={handSelectProduct}
                  style={{ height: '60vh', maxHeight: '500px', overflowY: 'scroll' }}
                  itemsSelected={productsSelected}
                  classNameItem="gx-mx-0 cursor-pointer"
               />
            ) : (
               <div
                  className="gx-text-center gx-font-weight-bold"
                  style={{ height: '60vh', maxHeight: '500px', overflowY: 'scroll' }}
               >
                  <Empty
                     image={Empty.PRESENTED_IMAGE_SIMPLE}
                     description={textSearch ? 'Không tìm thấy sản phẩm nào!' : 'Không tồn tại sản phẩm nào!'}
                  />
               </div>
            )}
         </ModalSelectorWapper>
      )
   }

   return (
      <Modal
         width={'90%'}
         style={{ maxWidth: '700px' }}
         visible={visible}
         centered
         title={'Chọn sản phẩm'}
         okText={t(GLOBAL_CLIENT.save)}
         cancelText={t(GLOBAL_CLIENT.close)}
         onCancel={onCancel}
         onOk={onSave}
         footer={[
            <Button key="back" onClick={onCancel}>
               {t(GLOBAL_CLIENT.close)}
            </Button>,
            <Button
               key="submit"
               type="primary"
               onClick={onSave}
               disabled={exportProduct ? listExportProducts.length === 0 : listProducts.length === 0}
            >
               {t(GLOBAL_CLIENT.create)}
            </Button>
         ]}
      >
         {renderContent()}
      </Modal>
   )
})

export default SelectProductModal
