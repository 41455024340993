import { useEffect, useState } from 'react'
import { Input, Select, Form, Space, Button, InputNumber } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { showMessageSuccess } from '../../helpers/functions'

import { useStore } from '../../hooks'
import { MinMaxConfigDesigner } from 'src/helpers/constants'
const { Option } = Select

const formItemLayout = {
   labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
   },
   wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
   }
}

const ConfigManagement = observer((props: any) => {
   const { t } = useTranslation()

   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [isLoading, setIsLoading] = useState(false)
   const CommonConfigStore = useStore('CommonConfigStore')
   const { designerConfigList } = CommonConfigStore
   const [form] = Form.useForm()

   const [fields, setFields] = useState<any>([])
   const [timeJob, settimeJob] = useState('')
   const [amountHold, setamountHold] = useState('')
   const [minWithdraw, setminWithdraw] = useState('')
   const [rangeDate, setrangeDate] = useState('')
   // const [linkSupport, setlinkSupport] = useState('')
   const [maxReceive, setmaxReceive] = useState('')
   const [minRecharge, setMinRecharge] = useState('')
   const [rangeJobFinish, setRangeJobFinish] = useState('')
   const [jobCancelInDay, setJobCancelInDay] = useState('')
   const [jobPriorityInDay, setJobPriorityInDay] = useState('')
   const [jobReviveFree, setJobReviveFree] = useState('')
   const [exchangeRate, setExchangeRate] = useState('')
   const [shipingFee, setShipingFee] = useState('')

   useEffect(() => {
      getConfigDesigner()

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   useEffect(() => {
      setFields([
         // { name: 'timeJob', value: designerConfigList?.timeJob },
         { name: 'amountHold', value: designerConfigList?.amountHold },
         { name: 'minWithdraw', value: designerConfigList?.minWithdraw },
         { name: 'shipingFee', value: designerConfigList?.shipingFee },
         // { name: 'rangeDate', value: designerConfigList?.rangeDate },
         // { name: 'linkSupport', value: designerConfigList?.linkSupport },
         // { name: 'maxReceive', value: designerConfigList?.maxReceive },
         // { name: 'rangeJobFinish', value: designerConfigList?.rangeJobFinish },
         { name: 'minRecharge', value: designerConfigList?.minRecharge },
         // { name: 'jobCancelInDay', value: designerConfigList?.jobCancelInDay },
         // { name: 'jobPriorityInDay', value: designerConfigList?.jobPriorityInDay },
         // { name: 'jobReviveFree', value: designerConfigList?.jobReviveFree },
         { name: 'exchangeRate', value: designerConfigList?.exchangeRate }
      ])
      // settimeJob(designerConfigList?.timeJob)
      setamountHold(designerConfigList?.amountHold)
      setminWithdraw(designerConfigList?.minWithdraw)
      // setrangeDate(designerConfigList?.rangeDate)
      // setmaxReceive(designerConfigList?.maxReceive)
      setMinRecharge(designerConfigList?.minRecharge)
      // setRangeJobFinish(designerConfigList?.rangeJobFinish)
      // setJobCancelInDay(designerConfigList?.jobCancelInDay)
      // setJobPriorityInDay(designerConfigList?.jobPriorityInDay)
      // setJobReviveFree(designerConfigList?.jobReviveFree)
      setExchangeRate(designerConfigList?.exchangeRate)
      setShipingFee(designerConfigList?.shipingFee)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [designerConfigList])

   const getConfigDesigner = async () => {
      await CommonConfigStore.getConfigDesigner()
   }

   const handleApply = async (values: any) => {
      //  console.log('Received values of form: ', values)
      const result = await CommonConfigStore.updateConfigDesigner({
         // timeJob: timeJob + '' || MinMaxConfigDesigner.timeJob.min + '',
         amountHold: amountHold + '' || MinMaxConfigDesigner.amountHold.min + '',
         minWithdraw: minWithdraw + '' || MinMaxConfigDesigner.minWithdraw.min + '',
         shipingFee: shipingFee + '' || MinMaxConfigDesigner.amountHold.min + '',
         // rangeDate: rangeDate + '' || MinMaxConfigDesigner.rangeDate.min + '',
         // linkSupport: form.getFieldValue('linkSupport') + '' || '',
         // maxReceive: maxReceive + '' || MinMaxConfigDesigner.maxReceive.min + '',
         // rangeJobFinish: rangeJobFinish + '',
         minRecharge: minRecharge + '',
         // jobCancelInDay: jobCancelInDay + '',
         // jobPriorityInDay: jobPriorityInDay + '',
         // jobReviveFree: jobReviveFree + ''
         exchangeRate: exchangeRate + ''
      })
      if (result) {
         showMessageSuccess('Cập nhật thành công')
      }
   }

   const onChangeValue = (type: string, value: number | string | undefined | null) => {
      // setPrice(value)
      switch (type) {
         case 'exchangeRate':
            setExchangeRate(value + '')
            break
         case 'timeJob':
            settimeJob(value + '')
            break
         case 'minWithdraw':
            setminWithdraw(value + '')
            break
         case 'amountHold':
            setamountHold(value + '')
            break
         case 'rangeDate':
            setrangeDate(value + '')
            break
         case 'maxReceive':
            setmaxReceive(value + '')
            break
         case 'rangeJobFinish':
            setRangeJobFinish(value + '')
            break
         case 'minRecharge':
            setMinRecharge(value + '')
            break
         case 'jobPriorityInDay':
            setJobPriorityInDay(value + '')
            break
         case 'jobReviveFree':
            setJobReviveFree(value + '')
            break
            case 'shipingFee':
               setShipingFee(value + '')
               break
         default:
            setJobCancelInDay(value + '')
            //maxReceive

            break
      }
   }
   // console.log('data?.timeJob', data?.timeJob, parseInt(data?.timeJob))

   return (
      <div>
         <Form
            {...formItemLayout}
            form={form}
            fields={fields}
            name="DesignerConfig"
            // onFinish={debounce(onFinish, 400)}
            scrollToFirstError
         >
            <Form.Item
               name="exchangeRate"
               label={'Tỉ giá' + '(1$=VNĐ)'}
               initialValue={designerConfigList?.exchangeRate}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.vuilongnhapdungdinhdang)
                  }
               ]}
            >
               <InputNumber
                  min={MinMaxConfigDesigner.amountHold.min}
                  max={MinMaxConfigDesigner.amountHold.max}
                  onChange={(value: number | string | undefined | null) => onChangeValue('exchangeRate', value)}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  step={1}
                  // onChange={() => onChangePrice}
               />
            </Form.Item>
            <Form.Item
               name="minWithdraw"
               label={t(GLOBAL_CLIENT.sotientoithieuduocrut) + '($)'}
               initialValue={designerConfigList?.minWithdraw}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.vuilongnhapdungdinhdang)
                  }
               ]}
            >
               <InputNumber
                  min={MinMaxConfigDesigner.minWithdraw.min}
                  max={MinMaxConfigDesigner.minWithdraw.max}
                  onChange={(value: number | string | undefined | null) => onChangeValue('minWithdraw', value)}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  step={0.01}
                  // onChange={() => onChangePrice}
               />
            </Form.Item>
            <Form.Item
               name="amountHold"
               label={t(GLOBAL_CLIENT.sotiengiulaitoithieu) + '($)'}
               initialValue={designerConfigList?.amountHold}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.vuilongnhapdungdinhdang)
                  }
               ]}
            >
               <InputNumber
                  min={MinMaxConfigDesigner.amountHold.min}
                  max={MinMaxConfigDesigner.amountHold.max}
                  onChange={(value: number | string | undefined | null) => onChangeValue('amountHold', value)}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  step={0.01}
                  // onChange={() => onChangePrice}
               />
            </Form.Item>
            <Form.Item
               name="minRecharge"
               label={t(GLOBAL_CLIENT.sotientoithieuduocnap) + '(VNĐ)'}
               initialValue={designerConfigList?.minRecharge}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.vuilongnhapdungdinhdang)
                  }
               ]}
            >
               <InputNumber
                  onChange={(value: number | string | undefined | null) => onChangeValue('minRecharge', value)}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  step={1000}
                  // onChange={() => onChangePrice}
               />
            </Form.Item>
            <Form.Item
               name="shipingFee"
               label={'Giá ship' + '($)'}
               initialValue={designerConfigList?.shipingFee}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.vuilongnhapdungdinhdang)
                  }
               ]}
            >
               <InputNumber
                  min={MinMaxConfigDesigner.amountHold.min}
                  max={MinMaxConfigDesigner.amountHold.max}
                  onChange={(value: number | string | undefined | null) => onChangeValue('shipingFee', value)}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  step={0.01}
                  // onChange={() => onChangePrice}
               />
            </Form.Item>
            {/* <Form.Item
               name="timeJob"
               label={t(GLOBAL_CLIENT.thoigianlam1mau) + '(phút)'}
               initialValue={designerConfigList?.timeJob}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.vuilongnhapdungdinhdang)
                  }
               ]}
            >
               <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  min={MinMaxConfigDesigner.timeJob.min}
                  max={MinMaxConfigDesigner.timeJob.max}
                  onChange={(value: number | string | undefined | null) => onChangeValue('timeJob', value)}
               />
            </Form.Item>

            <Form.Item
               name="amountHold"
               label={t(GLOBAL_CLIENT.sotiengiulaitoithieu) + '(VNĐ)'}
               initialValue={designerConfigList?.amountHold}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.vuilongnhapdungdinhdang)
                  }
               ]}
            >
               <InputNumber
                  min={MinMaxConfigDesigner.amountHold.min}
                  max={MinMaxConfigDesigner.amountHold.max}
                  onChange={(value: number | string | undefined | null) => onChangeValue('amountHold', value)}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  step={1000}
                  // onChange={() => onChangePrice}
               />
            </Form.Item>
            <Form.Item
               name="minWithdraw"
               label={t(GLOBAL_CLIENT.sotientoithieuduocrut) + '(VNĐ)'}
               initialValue={designerConfigList?.minWithdraw}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.vuilongnhapdungdinhdang)
                  }
               ]}
            >
               <InputNumber
                  min={MinMaxConfigDesigner.minWithdraw.min}
                  max={MinMaxConfigDesigner.minWithdraw.max}
                  onChange={(value: number | string | undefined | null) => onChangeValue('minWithdraw', value)}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  step={1000}
                  // onChange={() => onChangePrice}
               />
            </Form.Item>
            
            <Form.Item
               name="rangeDate"
               label={t(GLOBAL_CLIENT.songaygiua2lanrut)}
               initialValue={designerConfigList?.rangeDate}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.vuilongnhapdungdinhdang)
                  }
               ]}
            >
               <InputNumber
                  min={MinMaxConfigDesigner.rangeDate0.min}
                  max={MinMaxConfigDesigner.rangeDate0.max}
                  onChange={(value: number | string | undefined | null) => onChangeValue('rangeDate', value)}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  // onChange={() => onChangePrice}
               />
            </Form.Item>
            <Form.Item
               name="rangeJobFinish"
               label={'Ngày tự động chuyển trạng thái'}
               initialValue={designerConfigList?.rangeJobFinish}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.vuilongnhapdungdinhdang)
                  }
               ]}
            >
               <InputNumber
                  min={0}
                  max={MinMaxConfigDesigner.rangeDate0.max}
                  onChange={(value: number | string | undefined | null) => onChangeValue('rangeJobFinish', value)}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  // onChange={() => onChangePrice}
               />
            </Form.Item>
            <Form.Item
               name="linkSupport"
               initialValue={designerConfigList?.linkSupport}
               label={t(GLOBAL_CLIENT.linkSupporter)}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.vuilongnhapdungdinhdang)
                  }
               ]}
            >
               <Input style={{ backgroundColor: '#fff' }} maxLength={100} />
            </Form.Item>
            <Form.Item
               name="maxReceive"
               label={t(GLOBAL_CLIENT.somautoidaduocnhan)}
               initialValue={designerConfigList?.maxReceive}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.vuilongnhapdungdinhdang)
                  }
               ]}
            >
               <InputNumber
                  min={MinMaxConfigDesigner.maxReceive.min}
                  max={MinMaxConfigDesigner.maxReceive.max}
                  onChange={(value: number | string | undefined | null) => onChangeValue('maxReceive', value)}
                  // formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  // parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  // onChange={() => onChangePrice}
               />
            </Form.Item>
            <Form.Item
               name="jobCancelInDay"
               label={t(GLOBAL_CLIENT.soluongmauhuytrenngay)}
               initialValue={designerConfigList?.jobCancelInDay}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.vuilongnhapdungdinhdang)
                  }
               ]}
            >
               <InputNumber
                  // min={MinMaxConfigDesigner.rangeDate0.min}
                  // max={MinMaxConfigDesigner.rangeDate0.max}
                  onChange={(value: number | string | undefined | null) => onChangeValue('jobCancelInDay', value)}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  // onChange={() => onChangePrice}
               />
            </Form.Item>
            <Form.Item
               name="jobPriorityInDay"
               label={t(GLOBAL_CLIENT.soluongmauuutiennhantrenngay)}
               initialValue={designerConfigList?.jobCancelInDay}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.vuilongnhapdungdinhdang)
                  }
               ]}
            >
               <InputNumber
                  // min={MinMaxConfigDesigner.rangeDate0.min}
                  // max={MinMaxConfigDesigner.rangeDate0.max}
                  onChange={(value: number | string | undefined | null) => onChangeValue('jobPriorityInDay', value)}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  // onChange={() => onChangePrice}
               />
            </Form.Item>
            <Form.Item
               name="jobReviveFree"
               label={t(GLOBAL_CLIENT.soluongjobcothenhantruockhinhanjobuutien)}
               initialValue={designerConfigList?.jobReviveFree}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.vuilongnhapdungdinhdang)
                  }
               ]}
            >
               <InputNumber
                  // min={MinMaxConfigDesigner.rangeDate0.min}
                  // max={MinMaxConfigDesigner.rangeDate0.max}
                  onChange={(value: number | string | undefined | null) => onChangeValue('jobReviveFree', value)}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  // onChange={() => onChangePrice}
               />
            </Form.Item> */}
         </Form>
         <div className="text-right">
            <Space>
               <Button onClick={handleApply} type="primary" className="btn-add">
                  {/* <i className="icon icon-add icon-in-button" /> */}

                  {t(GLOBAL_CLIENT.apply)}
               </Button>
            </Space>
         </div>
      </div>
   )
})

export default ConfigManagement
