import React from 'react'
import { useEffect, useState } from 'react'
import { Input, Select, Modal, Form, InputNumber } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { validateEmail, validatePassword, validatePhone } from '../../helpers/functions'
import { debounce, trim } from 'lodash'
import { BANKS, ROLE, ROLES } from '../../helpers/constants'

import { useStore } from '../../hooks'
import { rule } from 'src/helpers/validateRule'
const { Option } = Select

const formItemLayout = {
   labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
   },
   wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
   }
}

const CreateEmployeeScreen = observer((props: any) => {
   const { t } = useTranslation()

   const { visible, onOk, onCancel } = props
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [isLoading, setIsLoading] = useState(false)
   const UserStore = useStore('UserStore')
   const [form] = Form.useForm()
   useEffect(() => {
      // getDataEmployee()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const onFinish = async (values: any) => {
      // console.log('Received values of form: ', values)
      const result = await UserStore.createEmployees({
         role: values.role || '',
         email: values?.email || '',
         password: values.password || '',
         name: values?.name || '',
         phoneNumber: values?.phoneNumber || '',
         bankName: values?.bankName || '',
         bankNumber: values?.bankNumber || '',
         linkTelegram: values?.linkTelegram || '',
         note: values?.note || '',
         address: values?.address || '',
         linkFb: '',
         idCard: '',
         salary: values?.salary || 0
      })
      if (result) {
         form.resetFields()
         onOk()
      }
   }

   const prefixSelector = (
      <Form.Item name="prefix" noStyle>
         <Select disabled>
            <Option value="84">+84</Option>
         </Select>
      </Form.Item>
   )
   return (
      <Modal
         width={'68%'}
         visible={visible}
         centered
         title={t(GLOBAL_CLIENT.create)}
         okText={t(GLOBAL_CLIENT.txtCreate)}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={onCancel}
         onOk={form.submit}
      >
         <Form
            {...formItemLayout}
            form={form}
            name="Creator"
            onFinish={debounce(onFinish, 400)}
            initialValues={{
               // residence: ['zhejiang', 'hangzhou', 'xihu'],
               prefix: '+84'
            }}
            scrollToFirstError
         >
            <Form.Item
               name="role"
               label={t(GLOBAL_CLIENT.role)}
               initialValue={ROLES.SELLER}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.noRole)
                  }
               ]}
            >
               <Select placeholder="Chọn Loại tài khoản">
                  <Option value={ROLES.ACCOUNTANT}>{'Accountant'}</Option>
                  <Option value={ROLES.FULFILLMENT}>{'Fulfillment'}</Option>
                  <Option value={ROLES.MANAGER_ORDER}>{'Manager Order'}</Option>
                  <Option value={ROLES.MANAGER_STOCK}>{'Manager Stock'}</Option>
                  <Option value={ROLES.SELLER}>{'Seller'}</Option>
                  <Option value={ROLES.NCC}>{'NCC'}</Option>
               </Select>
            </Form.Item>
            <Form.Item
               name="name"
               label={t(GLOBAL_CLIENT.fullname)}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.noName)
                  },
                  ({ getFieldValue }) => ({
                     validator(_, value) {
                        if (trim(value) !== '') {
                           return Promise.resolve()
                        }
                        return Promise.reject(t(GLOBAL_CLIENT.noTrim))
                     }
                  })
               ]}
            >
               <Input maxLength={30} />
            </Form.Item>
            <Form.Item
               name="phoneNumber"
               label={t(GLOBAL_CLIENT.phone)}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.noPhone)
                  },
                  () => ({
                     validator(_, value) {
                        if (validatePhone(value)) {
                           return Promise.resolve()
                        }
                        return Promise.reject(t(GLOBAL_CLIENT.phoneValidate))
                     }
                  })
               ]}
            >
               <Input
                  addonBefore={prefixSelector}
                  placeholder={t(GLOBAL_CLIENT.phoneEx)}
                  style={{ width: '100%' }}
                  maxLength={20}
               />
            </Form.Item>
            <Form.Item
               name="email"
               label={t(GLOBAL_CLIENT.txtEmail)}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.noEmail)
                  },
                  () => ({
                     validator(_, value) {
                        if (validateEmail(value)) {
                           return Promise.resolve()
                        }
                        return Promise.reject(t(GLOBAL_CLIENT.emailValidate))
                     }
                  })
               ]}
            >
               <Input
                  // addonBefore={prefixSelector}
                  // placeholder={t(GLOBAL_CLIENT.)}
                  style={{ width: '100%' }}
                  maxLength={254}
               />
            </Form.Item>
            <Form.Item
               name="password"
               label={t(GLOBAL_CLIENT.password)}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.noPassword)
                  },
                  () => ({
                     validator(_, value) {
                        if (validatePassword(value)) {
                           return Promise.resolve()
                        }
                        return Promise.reject(t(GLOBAL_CLIENT.dodaimatkhau))
                     }
                  })
               ]}
               hasFeedback
            >
               <Input.Password placeholder={t(GLOBAL_CLIENT.enterPassword)} maxLength={30} />
            </Form.Item>

            <Form.Item
               name="confirm"
               label={t(GLOBAL_CLIENT.xacnhanmatkhau)}
               dependencies={['password']}
               hasFeedback
               rules={[
                  rule.requiredConfirmPassword,
                  ({ getFieldValue }) => ({
                     validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                           return Promise.resolve()
                        }
                        return Promise.reject(new Error(t(GLOBAL_CLIENT.matkhaukhongtrungkhop)))
                     }
                  })
               ]}
            >
               <Input.Password placeholder={t(GLOBAL_CLIENT.reEnterPassword)} maxLength={30} />
            </Form.Item>
            <Form.Item name="bankName" label={t(GLOBAL_CLIENT.bankName)} initialValue={'TCB'}>
               <Select placeholder="Chọn ngân hàng">
                  {BANKS.map((item) => {
                     return (
                        <Option key={item?.id} value={item?.code}>
                           {item?.short_name}
                        </Option>
                     )
                  })}
               </Select>
            </Form.Item>
            <Form.Item
               name="accountBankName"
               label={t(GLOBAL_CLIENT.accountBankName)}
               // rules={[{ required: true, message: 'Vui lòng nhập tên chủ thẻ!' }]}
            >
               <Input placeholder={t(GLOBAL_CLIENT.noBankName)} maxLength={30} />
            </Form.Item>
            <Form.Item
               name="bankNumber"
               label={t(GLOBAL_CLIENT.bankAccount)}
               // rules={[{ required: true, message: 'Vui lòng nhập số tài khoản ngân hàng!' }]}
            >
               <Input placeholder={t(GLOBAL_CLIENT.noBankAccount)} maxLength={30} />
            </Form.Item>
            {/* <Form.Item name="cmtnd" label={t(GLOBAL_CLIENT.cmtnd)}>
          <Input maxLength={254}/>
        </Form.Item> */}
            <Form.Item
               name="linkTelegram"
               label={'Telegram'}
               // rules={[{ required: true, message: 'Vui lòng nhập ngân hàng!' }]}
            >
               <Input placeholder="" maxLength={254} />
            </Form.Item>

            <Form.Item
               name="salary"
               label={t(GLOBAL_CLIENT.basicSalary)}
               rules={[
                  {
                     required: false,
                     message: t(GLOBAL_CLIENT.vuilongnhapdungdinhdang)
                  }
               ]}
            >
               <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  max={100000000}
                  step={1000}
                  // onChange={() => onChangePrice}
               />
            </Form.Item>
            <Form.Item name="address" label={t(GLOBAL_CLIENT.address)}>
               <Input maxLength={254} />
            </Form.Item>
            <Form.Item name="note" label={t(GLOBAL_CLIENT.note)}>
               <Input maxLength={254} />
            </Form.Item>
         </Form>
      </Modal>
   )
})

export default CreateEmployeeScreen
