import { useEffect } from 'react'
import { Input, Modal, Form } from 'antd'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { debounce, trim } from 'lodash'

const formItemLayout = {
   labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
   },
   wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
   }
}

const CreateProductTypeScreen = observer((props: any) => {
   const { t } = useTranslation()

   const CommonConfigStore = useStore('CommonConfigStore')
   const { visible, onOk, onCancel } = props

   const [form] = Form.useForm()
   useEffect(() => {}, [])

   const onFinish = async (values: any) => {
      // console.log('Received values of form: ', values)

      const result = await CommonConfigStore.createTag(trim(values?.name) || '')
      console.log('result',result);
      
      if (result) {
         form.setFieldsValue({
            name: ''
         })
         onOk()
      }
   }

   return (
      <Modal
         width={'68%'}
         visible={visible}
         centered
         title={t(GLOBAL_CLIENT.create)}
         okText={t(GLOBAL_CLIENT.txtCreate)}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={onCancel}
         onOk={form.submit}
      >
         <Form {...formItemLayout} form={form} name="create" onFinish={debounce(onFinish, 400)} scrollToFirstError>
            <Form.Item
               name="name"
               label={t(GLOBAL_CLIENT.newTag)}
               rules={[
                  {
                     required: true,
                     message: 'Vui lòng nhập tag'
                  },
                  ({ getFieldValue }) => ({
                     validator(_, value) {
                        if (trim(value) !== '') {
                           return Promise.resolve()
                        }
                        return Promise.reject(t(GLOBAL_CLIENT.noTrim))
                     }
                  })
               ]}
            >
               <Input maxLength={20} />
            </Form.Item>
         </Form>
      </Modal>
   )
})

export default CreateProductTypeScreen
