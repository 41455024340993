import { createContext } from 'react'
import { AuthStore } from './AuthStore'
import { JobsStore } from './JobsStore'
import { ProductStore } from './ProductStore'
import { UserStore } from './UserStore'
import { FundStore } from './FundStore'
import { CommonConfigStore } from './CommonConfigStore'
import { DebtsStore } from './DebtsStore'
import { JobDetailStore } from './JobDetailStore'
import { DashboardStore } from './DashboardStore'
import { WarehouseStore } from './WarehouseStore'
import { OrdersStore } from './OrdersStore'

export const stores = Object.freeze({
   AuthStore: new AuthStore(),
   JobsStore: new JobsStore(),
   UserStore: new UserStore(),
   FundStore: new FundStore(),
   ProductStore: new ProductStore(),
   CommonConfigStore: new CommonConfigStore(),
   DebtsStore: new DebtsStore(),
   JobDetailStore: new JobDetailStore(),
   DashboardStore: new DashboardStore(),
   WarehouseStore: new WarehouseStore(),
   OrdersStore: new OrdersStore()
})

export const storesContext = createContext(stores)
export const StoresProvider = storesContext.Provider
