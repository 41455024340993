import React, { useContext, useRef } from 'react'
import { useEffect, useState } from 'react'
import { Input, Select, Modal, Form, Button, Typography, Table, Popconfirm } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../hooks'
import styled from 'styled-components'
import { PlusOutlined } from '@ant-design/icons'
import type { FormInstance } from 'antd/es/form'

const formItemLayout = {
   labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
   },
   wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
   }
}
const EditableContext = React.createContext<FormInstance<any> | null>(null)

interface Item {
   key: string
   sku: any
   name: string
   attribute: string
   quantity: any
}

interface EditableRowProps {
   index: number
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
   const [form] = Form.useForm()
   return (
      <Form form={form} component={false}>
         <EditableContext.Provider value={form}>
            <tr {...props} />
         </EditableContext.Provider>
      </Form>
   )
}

interface EditableCellProps {
   title: React.ReactNode
   editable: boolean
   children: React.ReactNode
   dataIndex: keyof Item
   record: Item
   handleSave: (record: Item) => void
}

const EditableCell: React.FC<EditableCellProps> = ({
   title,
   editable,
   children,
   dataIndex,
   record,
   handleSave,
   ...restProps
}) => {
   const [editing, setEditing] = useState(false)
   const inputRef = useRef<any>(null)
   const form = useContext(EditableContext)!

   useEffect(() => {
      if (editing) {
         inputRef.current!.focus()
      }
   }, [editing])

   const toggleEdit = () => {
      setEditing(!editing)
      form.setFieldsValue({ [dataIndex]: record[dataIndex] })
   }

   const save = async () => {
      try {
         const values = await form.validateFields()

         toggleEdit()
         handleSave({ ...record, ...values })
      } catch (errInfo) {
         console.log('Save failed:', errInfo)
      }
   }

   let childNode = children

   if (editable) {
      childNode = editing ? (
         <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            rules={[
               {
                  required: true,
                  message: `${title} is required.`
               }
            ]}
         >
            <Input ref={inputRef} onPressEnter={save} onBlur={save} />
         </Form.Item>
      ) : (
         <div className="editable-cell-value-wrap" style={{ paddingRight: 24, height: '26px' }} onClick={toggleEdit}>
            {children}
         </div>
      )
   }

   return <td {...restProps}>{childNode}</td>
}

type EditableTableProps = Parameters<typeof Table>[0]

interface DataType {
   key: React.Key
   sku: any
   name: string
   attribute: string
   quantity: any
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>

export const ModalDeliveryWrapper = styled.div`
   .ant-table::-webkit-scrollbar {
      width: 8px;
      /* width of the entire scrollbar */
   }

   // .ant-table-content::-webkit-scrollbar-track {
   //    background: orange;
   //    /* color of the tracking area */
   // }

   // .ant-table-content::-webkit-scrollbar-thumb {
   //    background-color: blue;
   //    /* color of the scroll thumb */
   //    border-radius: 20px;
   //    /* roundness of the scroll thumb */
   //    border: 3px solid orange;
   //    /* creates padding around scroll thumb */
   // }
   .editable-cell {
      position: relative;
   }

   .editable-cell-value-wrap {
      padding: 5px 12px;
      cursor: pointer;
   }

   .editable-row:hover .editable-cell-value-wrap {
      padding: 4px 11px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
   }

   [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
      border: 1px solid #434343;
   }
`
const ProductDeliveryScreen = observer((props: any) => {
   const { t } = useTranslation()

   const { item, visible, onOk, onCancel, onlyView } = props
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [isChangePassword, setIsChangePassword] = useState(false)

   const [password, setPassword] = useState('')
   const [status, setStatus] = useState(parseInt(item?.status))
   const UserStore = useStore('UserStore')
   const AuthStore = useStore('AuthStore')
   const role: any = AuthStore.data_role
   const [form] = Form.useForm()
   const { Title, Text } = Typography
   const [attribute, setAttribute] = useState([
      {
         value: '1',
         label: 'Not Identified'
      },
      {
         value: '2',
         label: 'Closed'
      },
      {
         value: '3',
         label: 'Communicated'
      },
      {
         value: '4',
         label: 'Identified'
      },
      {
         value: '5',
         label: 'Resolved'
      },
      {
         value: '6',
         label: 'Cancelled'
      }
   ])

   const [dataSource, setDataSource] = useState<DataType[]>([
      {
         key: 1,
         name: 'Quần tất',
         attribute: 'Xanh/M',
         sku: 'UHKFS1232',
         quantity: 10
      },
      {
         key: 2,
         name: 'Áo ba lỗ',
         sku: 'UHKCSS3112',
         attribute: 'Cam/M',
         quantity: 20
      },
      {
         key: 3,
         name: 'Áo thun',
         sku: 'UHKCSS3112',
         attribute: 'Cam/M',
         quantity: 20
      },
      {
         key: 4,
         name: 'Áo khoác',
         sku: 'UHKCSS3112',
         attribute: 'Cam/M',
         quantity: 20
      },
      {
         key: 5,
         name: 'Quần tất',
         attribute: 'Xanh/M',
         sku: 'UHKFS1232',
         quantity: 10
      },
      {
         key: 6,
         name: 'Áo ba lỗ',
         sku: 'UHKCSS3112',
         attribute: 'Cam/M',
         quantity: 20
      },
      {
         key: 7,
         name: 'Áo thun',
         sku: 'UHKCSS3112',
         attribute: 'Cam/M',
         quantity: 20
      },
      {
         key: 8,
         name: 'Áo khoác',
         sku: 'UHKCSS3112',
         attribute: 'Cam/M',
         quantity: 20
      },
      {
         key: 9,
         name: 'Áo thun',
         sku: 'UHKCSS3112',
         attribute: 'Cam/M',
         quantity: 20
      },
      {
         key: 10,
         name: 'Áo khoác',
         sku: 'UHKCSS3112',
         attribute: 'Cam/M',
         quantity: 20
      },
      {
         key: 11,
         name: 'Quần tất',
         attribute: 'Xanh/M',
         sku: 'UHKFS1232',
         quantity: 10
      },
      {
         key: 12,
         name: 'Áo ba lỗ',
         sku: 'UHKCSS3112',
         attribute: 'Cam/M',
         quantity: 20
      },
      {
         key: 13,
         name: 'Áo thun',
         sku: 'UHKCSS3112',
         attribute: 'Cam/M',
         quantity: 20
      },
      {
         key: 14,
         name: 'Áo khoác',
         sku: 'UHKCSS3112',
         attribute: 'Cam/M',
         quantity: 20
      }
   ])

   const [count, setCount] = useState(15)

   const filteredOptions = () => {
      const listSkuData = dataSource.map((data) => data.sku)
      const options = attribute.filter((item) => !listSkuData.includes(item.label))
      return options
   }

   const handleDelete = (key: React.Key) => {
      const newData = dataSource.filter((item) => item.key !== key)
      setDataSource(newData)
   }

   const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
      {
         title: 'SKU',
         dataIndex: 'sku',
         key: 'sku',
         width: 100
      },
      {
         title: 'Tên sản phẩm',
         dataIndex: 'name',
         key: 'name',
         width: 100
      },
      {
         title: 'Thuộc tính',
         dataIndex: 'attribute',
         key: 'attribute',
         width: 100
      },
      {
         title: 'Số lượng',
         dataIndex: 'quantity',
         key: 'quantity',
         editable: true,
         width: 50
      },
      {
         title: 'operation',
         dataIndex: 'operation',
         fixed: 'right',
         width: 50,
         render: (_, record: any) =>
            dataSource.length >= 1 ? (
               <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                  <a>Delete</a>
               </Popconfirm>
            ) : null
      }
   ]

   const handleAdd = () => {
      const newData: DataType = {
         key: count,
         sku: (
            <Select
               style={{ width: '100%' }}
               showSearch
               onChange={(value) => handleChange(value as string, count)}
               placeholder="Search to Select"
               optionFilterProp="children"
               filterOption={(input, option) => ((option?.label as string) ?? '').includes(input)}
               filterSort={(optionA, optionB) =>
                  ((optionA?.label as string) ?? '')
                     .toLowerCase()
                     .localeCompare(((optionB?.label as string) ?? '').toLowerCase())
               }
               options={filteredOptions()}
            />
         ),
         name: '',
         attribute: '',
         quantity: null
      }
      setDataSource((dataSource) => [...dataSource, newData])
      setCount((count) => count + 1)
   }
   const handleChange = (value: string, key: number) => {
      const obj = attribute.find((item) => item.value == value)

      setDataSource((dataSource) => {
         const newState: any = dataSource?.map((item) =>
            item.key === key
               ? { key: key, sku: obj?.label, name: obj?.label, attribute: obj?.label, quantity: 1 }
               : item
         )
         return newState
      })
   }
   const handleSave = (row: DataType) => {
      const newData = [...dataSource]
      const index = newData.findIndex((item) => row.key === item.key)
      const item = newData[index]
      //@ts-ignore
      newData.splice(index, 1, { ...item, ...row })
      setDataSource(newData)
   }

   const components = {
      body: {
         row: EditableRow,
         cell: EditableCell
      }
   }

   const columns = defaultColumns.map((col) => {
      if (!col.editable) {
         return col
      }
      return {
         ...col,
         onCell: (record: DataType) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave
         })
      }
   })

   const renderContent = () => {
      return (
         <ModalDeliveryWrapper>
            <Form {...formItemLayout} form={form} name="CreatorDetail" scrollToFirstError>
               <div style={{ marginBottom: '10px' }}>
                  <Text style={{ marginRight: '5px' }}>Danh sách sản phẩm</Text>
                  <Button type="primary" size="small" shape="circle" icon={<PlusOutlined />} onClick={handleAdd} />
               </div>
               <Table
                  style={{ marginBottom: '20px' }}
                  components={components}
                  pagination={false}
                  rowClassName={() => 'editable-row'}
                  bordered
                  dataSource={dataSource}
                  columns={columns as ColumnTypes}
                  scroll={{ x: 1000, y: 240 }}
               />
               <Form.Item labelAlign="left" name="shippingLabel" label={'Kho vận chuyển'}>
                  <Select
                     showSearch
                     placeholder="Search to Select"
                     optionFilterProp="children"
                     filterOption={(input, option) => ((option?.label as string) ?? '').includes(input)}
                     filterSort={(optionA, optionB) =>
                        ((optionA?.label as string) ?? '')
                           .toLowerCase()
                           .localeCompare(((optionB?.label as string) ?? '').toLowerCase())
                     }
                     options={attribute}
                  />
               </Form.Item>
            </Form>
         </ModalDeliveryWrapper>
      )
   }

   return !onlyView ? (
      <Modal
         width={'90%'}
         style={{ maxWidth: '1200px' }}
         visible={visible}
         centered
         title={'Xuất hàng'}
         okText={t(GLOBAL_CLIENT.save)}
         cancelText={t(GLOBAL_CLIENT.close)}
         onCancel={onCancel}
         onOk={form.submit}
      >
         {renderContent()}
      </Modal>
   ) : (
      <Modal
         width={'90%'}
         style={{ maxWidth: '1200px' }}
         visible={visible}
         centered
         title={'Xuất hàng'}
         okText={t(GLOBAL_CLIENT.edit)}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={onCancel}
         onOk={form.submit}
         footer={[
            <>
               <Button key="back" onClick={onCancel}>
                  Đóng
               </Button>
            </>
         ]}
      >
         {renderContent()}
      </Modal>
   )
})

export default ProductDeliveryScreen
