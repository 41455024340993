import { Button, Form, Input, InputNumber, Pagination, Select, Space, Table, Typography } from 'antd'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { PAGE_SIZE, PhiSanMinMax, GiaMinMax, ROLE, tabConfigPrice } from 'src/helpers/constants'
import { formatCurrency, showMessageError } from 'src/helpers/functions'
import { useStore } from 'src/hooks'

interface Item {
   createdAt?: string
   deletedAt?: string
   productId: any
   productName: string
   baseCost: any
   shippingFee: number
   shippingFeeFulfillment: number
   packingFeeFulfillment: number
   handingFeeFulfillment: number
   id?: number
   packingFee?: number
   handingFee: number
   labelFee?: number
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
   editing: boolean
   dataIndex: string
   title: any
   inputType: 'number' | 'text'
   record: Item
   index: number
   children: React.ReactNode
}

const EditableCell: React.FC<EditableCellProps> = ({
   editing,
   dataIndex,
   title,
   inputType,
   record,
   index,
   children,
   ...restProps
}) => {
   const renderInputNumber = () => {
      return title === 'Giá tối thiểu' || title === 'Giá tối đa' ? (
         <InputNumber
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            // min={GiaMinMax.min}
            // max={GiaMinMax.max}
            step={1}
         />
      ) : (
         <InputNumber
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            // min={PhiSanMinMax.min}
            // max={PhiSanMinMax.max}
            step={1}
         />
      )
   }

   const inputNode = inputType === 'number' ? renderInputNumber() : <Input maxLength={254} />

   return (
      <td {...restProps}>
         {editing ? (
            <Form.Item
               name={dataIndex}
               style={{ margin: 0 }}
               rules={[
                  {
                     required: true,
                     // message: `Không được để trống ${title}!`
                     message: `Không được để trống`
                  }
               ]}
            >
               {inputNode}
            </Form.Item>
         ) : (
            children
         )}
      </td>
   )
}
const FILTER_TIME = [
   { type: 'lastWeek', title: 'All', startTime: '', endTime: '' },
   { type: 'lastMonth', title: 'Seller 1', startTime: '', endTime: '' },
   { type: 'secondMonth', title: 'Seller 2', startTime: '', endTime: '' },
   { type: 'thirdMonth', title: 'Seller 3', startTime: '', endTime: '' },
   { type: 'custom', title: 'Seller 4', startTime: '', endTime: '' }
]
const ContentFee: React.FC = observer((props: any) => {
   const [form] = Form.useForm()
   // const [data, setData] = useState(originData)
   const [editingKey, setEditingKey] = useState('')
   const { t } = useTranslation()
   const UserStore = useStore('UserStore')
   const CommonConfigStore = useStore('CommonConfigStore')
   const { rows } = CommonConfigStore.listPrice
   const { results, totalResults, page } = UserStore.listCreator
   const [typeFilter, setTypeFilter] = useState(props.listFilter[1]?.id || '')

   const [startTime, setStartTime] = useState(FILTER_TIME[1].startTime)
   const [endTime, setEndTime] = useState(FILTER_TIME[1].endTime)
   useEffect(() => {
      // console.log('prop: ', props?.data)
      setEditingKey('')
      setTypeFilter('')
      console.log('props.listFilter[1]?.id useEffect1', props.listFilter[1]?.id)
      CommonConfigStore.getListPrice(typeFilter)
      // if (props.listFilter[0]?.id) {
      //    console.log('props.listFilter[1]?.id 111', props.listFilter[1]?.id)

      //    CommonConfigStore.getListPrice(props.listFilter[1]?.id)
      // }

      // CommonConfigStore.getExchangeFeeList(props?.data?.id)

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props?.idTab, props?.listFilter])
   useEffect(() => {
      // console.log('prop: ', props?.data)
      setEditingKey('')

      if (typeFilter == '') {
         //all
         CommonConfigStore.getListPrice('')
      }
      if (typeFilter) {
         CommonConfigStore.getListPrice(typeFilter)
      }

      console.log('props.listFilter[1]?.id useEffect2', typeFilter)
      // CommonConfigStore.getExchangeFeeList(props?.data?.id)

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [typeFilter])

   const isEditing = (record: Item) => {
      // console.log(
      //    'record',
      //    JSON.stringify(record),
      //    ' record.id.toString()',
      //    record.productId.toString(),
      //    'editingKey',
      //    editingKey,
      //    'record.id.toString() === editingKey',
      //    record.productId.toString() === editingKey
      // )

      return record.productId?.toString() === editingKey
   }

   const edit = (record: Partial<Item> & { productId: React.Key }) => {
      if (typeFilter === '' && props?.idTab === tabConfigPrice.fulfillment) {
         form.setFieldsValue({
            baseCost: '',
            shippingFeeFulfillment: '',
            packingFeeFulfillment: '',
            handingFeeFulfillment: '',
            ...record
         })
      } else {
         form.setFieldsValue({ baseCost: '', shippingFee: '', handingFee: '', ...record })
      }
      console.log(
         'edit: ',
         { baseCost: '', shippingFee: '', handingFee: '', ...record },
         ' recor ban dau',
         JSON.stringify(record)
      )

      setEditingKey(record.productId + '')
   }

   const cancel = () => {
      setEditingKey('')
   }

   const save = async (record: Item) => {
      console.log('[save] record', JSON.stringify(record))

      try {
         const row = (await form.validateFields()) as Item

         const newData = [...rows]
         const index = newData.findIndex((item) => record?.productId === item.productId)
         if (index > -1) {
            const item = newData[index]
            newData?.splice(index, 1, {
               ...item,
               ...row
            })
            // console.log('index',index);

            // newData[index]={...record,...row}
            // if (row?.min >= row?.max) {
            //    showMessageError('Giá tối thiểu phải nhỏ hơn giá tối đa!')
            // } else {
            // console.log('row save; ', row, 'record :', JSON.parse(JSON.stringify(record)))
            console.log('[save] newData ne: ', JSON.stringify(newData))
            if (typeFilter) {
               let paramUpdate = {
                  userId: typeFilter,
                  productId: record?.productId
               }
               if (props.idTab == 1) {
                  paramUpdate = {
                     ...paramUpdate,
                     ...{
                        baseCost: row?.baseCost,
                        shippingFee: row?.shippingFee,
                        handingFee: row?.handingFee,
                        labelFee: row?.labelFee
                     }
                  }
               } else {
                  paramUpdate = {
                     ...paramUpdate,
                     ...{
                        shippingFee: row?.shippingFee,
                        packingFee: row?.packingFee,
                        handingFee: row?.handingFee
                     }
                  }
               }

               CommonConfigStore.updatePrice(paramUpdate)
            } else {
               let paramUpdate = {
                  role: props?.idTab == 1 ? ROLE.SELLER : ROLE.FULFILLMENT,
                  productId: record?.productId,
                  baseCost: row?.baseCost
               }
               if (props?.idTab === tabConfigPrice.fulfillment) {
                  paramUpdate = {
                     ...paramUpdate,
                     ...{
                        shippingFee: row?.shippingFeeFulfillment,
                        packingFee: row?.packingFeeFulfillment,
                        handingFee: row?.handingFeeFulfillment
                     }
                  }
               } else {
                  paramUpdate = {
                     ...paramUpdate,
                     ...{ shippingFee: row?.shippingFee, handingFee: row?.handingFee, labelFee: row?.labelFee }
                  }
               }
               CommonConfigStore.resetPriceAll(paramUpdate)
            }

            CommonConfigStore.setListPrice(newData)
            setEditingKey('')
            // }
         } else {
            newData.push(row)
            CommonConfigStore.setListPrice(newData)
            setEditingKey('')
         }
      } catch (errInfo) {
         // console.log('Validate Failed:', errInfo)
      }
   }

   let columns = [
      {
         title: 'ID',
         dataIndex: 'productId',
         key: 'productId',
         width: '20%',
         editable: false,
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
      },
      {
         title: 'Tên sản phẩm',
         dataIndex: 'product',
         key: 'product',
         width: '20%',
         editable: false,
         render: (value: any) => {
            return <span className="txtMax2Line">{value?.name}</span>
         }
      }
   ]
   if (props.idTab === tabConfigPrice.seller) {
      columns.push({
         title: 'Base cost',
         dataIndex: 'baseCost',
         key: 'baseCost',
         width: '20%',
         editable: true,
         render: (value: any) => {
            return <span className="txtMax2Line">${formatCurrency(value)}</span>
         }
      })
   }
   let column2 = []
   if (props.idTab === tabConfigPrice.fulfillment) {
      column2 = [
         {
            title: 'Shipping fee',
            dataIndex: typeFilter ? 'shippingFee' : 'shippingFeeFulfillment',
            key: typeFilter ? 'shippingFee' : 'shippingFeeFulfillment',
            width: '20%',
            editable: true,
            render: (value: any) => {
               return <span className="txtMax2Line">${formatCurrency(value)}</span>
            }
         },
         {
            title: 'Handling fee',
            dataIndex: typeFilter ? 'handingFee' : 'handingFeeFulfillment',
            key: typeFilter ? 'handingFee' : 'handingFeeFulfillment',
            width: '20%',
            editable: true,
            render: (value: any) => {
               return <span className="txtMax2Line">${formatCurrency(value)}</span>
            }
         },
         {
            title: 'Packaging fee',
            dataIndex: typeFilter ? 'packingFee' : 'packingFeeFulfillment',
            key: typeFilter ? 'packingFee' : 'packingFeeFulfillment',
            width: '20%',
            editable: true,
            render: (value: any) => {
               return <span className="txtMax2Line">${formatCurrency(value)}</span>
            }
         }
      ]
   } else {
      column2 = [
         {
            title: 'Shipping fee',
            dataIndex: 'shippingFee',
            key: 'shippingFee',
            width: '10%',
            editable: true,
            render: (value: any) => {
               return <span className="txtMax2Line">${formatCurrency(value)}</span>
            }
         },
         {
            title: 'Handling fee',
            dataIndex: 'handingFee',
            key: 'handingFee',
            width: '10%',
            editable: true,
            render: (value: any) => {
               return <span className="txtMax2Line">${formatCurrency(value)}</span>
            }
         },
         // {
         //    title: 'Packaging fee',
         //    dataIndex: 'packingFee',
         //    key: 'packingFee',
         //    width: '10%',
         //    editable: true,
         //    render: (value: any) => {
         //       return <span className="txtMax2Line">${formatCurrency(value)}</span>
         //    }
         // },
         {
            title: 'Label fee',
            dataIndex: 'labelFee',
            key: 'labelFee',
            width: '10%',
            editable: true,
            render: (value: any) => {
               return <span className="txtMax2Line">${formatCurrency(value)}</span>
            }
         }
      ]
   }
   let column3 = [
      {
         title: 'Action',
         width: '15%',
         dataIndex: 'operation',
         render: (_: any, record: Item) => {
            const editable = isEditing(record)

            return editable ? (
               <span>
                  <Typography.Link onClick={() => save(record)} style={{ marginRight: 8 }}>
                     {t(GLOBAL_CLIENT.save)}
                  </Typography.Link>
                  <Typography.Link onClick={cancel} style={{ marginRight: 8 }}>
                     {t(GLOBAL_CLIENT.huy)}
                  </Typography.Link>
               </span>
            ) : (
               <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                  {t(GLOBAL_CLIENT.edit)}
               </Typography.Link>
            )
         }
      }
   ]

   const mergedColumns = [...columns, ...column2, ...column3]?.map((col: any) => {
      if (!col?.editable) {
         return col
      }
      return {
         ...col,
         onCell: (record: Item) => ({
            record,
            inputType: 'number',
            // inputType: col.dataIndex === 'minPrice' ? 'number' : 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record)
         })
      }
   })

   const onChange = (currentPage: number) => {
      // handle_getListData(currentPage)
   }

   const renderPagination = () => {
      if (isEmpty(results)) {
         return null
      }

      return (
         <div className="containerPagination">
            <Pagination
               onChange={onChange}
               pageSize={PAGE_SIZE}
               current={page}
               showSizeChanger={false}
               total={totalResults}
            />
         </div>
      )
   }
   const handleChangeSelect = (value: string) => {
      console.log('value', value)
      setTypeFilter(value)
   }

   const resetPrice = () => {
      CommonConfigStore.resetPriceUserAPI(typeFilter)
      setTimeout(() => {
         CommonConfigStore.getListPrice(typeFilter)
      }, 700)
   }
   return (
      <div>
         <Space
            className="total-items"
            style={{ width: '100%', alignItems: 'center', justifyContent: 'space-between' }}
         >
            {props.listFilter && props.listFilter.length > 1 && (
               <Select
                  defaultValue={props.listFilter[1]?.id}
                  value={typeFilter}
                  onChange={handleChangeSelect}
                  style={{ width: 185, alignItems: 'center', textAlign: 'left', marginBottom: 16 }}
               >
                  {props.listFilter?.map((item: any) => {
                     return (
                        <Select.Option key={item.id} value={item.id}>
                           {item?.name}
                        </Select.Option>
                     )
                  })}
               </Select>
            )}
            {typeFilter !== '' && (
               <Button onClick={resetPrice} type="primary">
                  {'Khôi phục giá mặc định'}
               </Button>
            )}
         </Space>

         <Form form={form} component={false}>
            <Table
               components={{
                  body: {
                     cell: EditableCell
                  }
               }}
               bordered
               dataSource={rows || []}
               columns={mergedColumns}
               rowClassName="editable-row"
               rowKey="productId"
               pagination={false}
            />
            {renderPagination()}
         </Form>
      </div>
   )
})

export default ContentFee
