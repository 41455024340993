import { useEffect, useState } from 'react'
import { Table, Space, Pagination, Col, Row, Input, DatePicker, Divider } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { isEmpty, trim } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks'
import { DATE_FORMAT_CLIENT, DATE_FORMAT_CLIENT_PARAMS, PAGE_SIZE, TYPE_FUND } from '../../helpers/constants'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { Loading } from '../../common'
import moment from 'moment'
import 'moment/locale/vi'
import locale from 'antd/es/date-picker/locale/vi_VN'
import useWindowDimensions from '../../common/useWindowDimensions'
import { formatCurrency } from 'src/helpers/functions'
import { getPage, typeFund } from 'src/helpers/constants'
import TopupAdminModal from 'src/common/components/topup/TopupAdminModal'
import useModal from 'src/helpers/modal/useModal'
import DetailAccount from '../account-management/Detail'
import Bill from './Bill'
import ProductWarehouse from './ProductWarehouse'

const { RangePicker } = DatePicker
const TYPE_SCREEN = { main: '1', bill: '2', product: '3' }
const WarehouseManagement = observer(() => {
   const { t } = useTranslation()
   const { confirm } = useModal()
   const WarehouseStore = useStore('WarehouseStore')
   const [textSearch, set_textSearch] = useState('')
   const { height } = useWindowDimensions()
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [isLoading, setIsLoading] = useState(false)
   const [startTime, setStartTime] = useState(moment().subtract(1, 'months'))
   const [endTime, setEndTime] = useState(moment())

   const [item, setItem] = useState(null)
   const [topup, setTopup] = useState(null)
   const [sortDateDesc, setSortDateDesc] = useState(true)
   const [isTopupModalOpen, setIsTopupModalOpen] = useState(false)

   const { rows, offset, count } = WarehouseStore.warehouseList
   const { before, import: importValue, export: exportValue, inventory } = WarehouseStore.priceWarehouse
   const [itemAccount, setItemAccount] = useState(null)
   const [visibleDetailAccount, setVisibleDetailAccount] = useState<any>(false)

   const [visibleView, setVisibleView] = useState<any>(TYPE_SCREEN.main)

   useEffect(() => {
      getListData(0, {
         text: trim(textSearch),
         from: moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
         to: moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString()
      })
      // WarehouseStore.getTotalPrice(
      //    moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
      //    moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString()
      // )
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [textSearch, startTime, endTime])

   const getListData = async (currentPage: number, param?: any) => {
      try {
         // setIsLoading(true)
         const offset = getPage(currentPage)
         const params = { offset, ...param }
         //  console.log('params', params)

         await WarehouseStore.getWarehouseList(params)
      } catch (error) {
         //  console.log(error)
      } finally {
         // setIsLoading(false)
      }
   }

   const onChange = (currentPage: number) => {
      getListData(currentPage - 1, { text: trim(textSearch) })
   }

   const handleChangeTextSearch = (value: any) => {
      set_textSearch(value)
   }

   const setDetailInfo = (value: any) => {
      setItem(value)
   }

   const showBill = (value: any) => {
      setDetailInfo(value)
      setVisibleView(TYPE_SCREEN.bill)
   }
   const showProduct = (value: any) => {
      setDetailInfo(value)
      setVisibleView(TYPE_SCREEN.product)
   }

   const showMain = () => {
      setVisibleView(TYPE_SCREEN.main)
      // setTimeout(() => {
      //    setVisibleDetail(true)
      // }, 500)
   }

   const onRefreshList = () => {
      getListData(0, {
         text: trim(textSearch),
         from: moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
         to: moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString()
      })
   }

   const handleCloseTopup = () => {
      setIsTopupModalOpen(false)
   }
   const showDetailAccount = (item: any) => () => {
      setItemAccount(item)
      setTimeout(() => {
         setVisibleDetailAccount(true)
      }, 300)
   }
   const columns = [
      {
         title: t(GLOBAL_CLIENT.id),
         dataIndex: 'id',
         key: 'id',
         width: 120,
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
      },
      {
         title: 'Tên kho',
         dataIndex: 'name',
         key: 'name',
         width: 80,
         render: (value: any, record: any) => {
            return (
               <span className="gx-link txtMax2Line" onClick={showDetailAccount(record?.account)}>
                  {value || ''}
               </span>
            )
         }
      },
      {
         title: 'Tổng tồn kho',
         dataIndex: 'inventory',
         key: 'inventory',
         width: 120,
         render: (value: any) => {
            return <span className="txtMax2Line">{formatCurrency(value, true)}</span>
         }
      },
      {
         title: 'action',
         key: 'action',
         dataIndex: 'updatedAt',
         width: 120,
         align: 'center' as 'center',
         fixed: 'right' as 'right',
         render: (text: string, record: any) => {
            return (
               <span className="txtMax2Line">
                  <span onClick={() => showBill(record)} className="gx-link">
                     {'Danh sách phiếu'}
                  </span>
                  <Divider type="vertical" />
                  <span onClick={() => showProduct(record)} className="gx-link">
                     {'DS sản phẩm'}
                  </span>
               </span>
            )
         }
      }
   ]

   const renderPagination = () => {
      if (isEmpty(rows)) {
         return null
      }

      return (
         <div className="containerPagination">
            <Pagination
               onChange={onChange}
               pageSize={PAGE_SIZE}
               current={offset / PAGE_SIZE + 1}
               showSizeChanger={false}
               total={count}
            />
         </div>
      )
   }

   function onChangeTable(pagination: any, filters: any, sorter: any, extra: any) {
      // console.log('params', pagination, filters, sorter, extra)
      if (sorter.order === 'ascend') {
         setSortDateDesc(false)
      } else {
         setSortDateDesc(true)
      }
   }

   const handleRangePickerChange = (date: any) => {
      // set_dateRangerPicker(date);
      //  console.log('datee: ', date)
      setStartTime(date[0])
      setEndTime(date[1])
   }

   // const renderHeader = () => {
   //    return (
   //       <Row
   //          className="row-container-head"
   //          style={{ background: '#fff', padding: 12, borderRadius: 6, marginLeft: 0, marginRight: 0 }}
   //       >
   //          <Col span={4}>
   //             <Col>
   //                <span style={{ fontWeight: 'bold' }}>Đầu kỳ</span>
   //             </Col>
   //             <Col>
   //                <span style={{ fontSize: 18, color: '#6998B9', fontWeight: 'bolder' }}>
   //                   {formatCurrency(before, true)}
   //                </span>
   //             </Col>
   //          </Col>
   //          <Col span={1} style={{ alignSelf: 'center' }}>
   //             {/* <span style={{ fontWeight: 'bolder' }}>+</span> */}
   //          </Col>
   //          <Col span={4}>
   //             <Col>
   //                <span style={{ fontWeight: 'bold' }}>Tổng nhập</span>
   //             </Col>
   //             <Col>
   //                <span style={{ fontSize: 18, color: '#89AD88', fontWeight: 'bolder' }}>
   //                   {formatCurrency(importValue, true)}
   //                </span>
   //             </Col>
   //          </Col>
   //          <Col span={1} style={{ alignSelf: 'center' }}>
   //             {/* <span style={{ fontWeight: 'bolder' }}>-</span> */}
   //          </Col>
   //          <Col span={4}>
   //             <Col>
   //                <span style={{ fontWeight: 'bold' }}>Tổng xuất</span>
   //             </Col>
   //             <Col>
   //                <span style={{ fontSize: 18, color: '#B87986', fontWeight: 'bolder' }}>
   //                   {formatCurrency(exportValue, true)}
   //                </span>
   //             </Col>
   //          </Col>
   //          <Col span={1} style={{ alignSelf: 'center' }}>
   //             {/* <span style={{ fontWeight: 'bolder' }}>=</span> */}
   //          </Col>
   //          <Col span={4}>
   //             <Col>
   //                <span style={{ fontWeight: 'bold' }}>Tồn kho</span>
   //             </Col>
   //             <Col>
   //                <span style={{ fontSize: 18, color: '#3F74A1', fontWeight: 'bolder' }}>
   //                   {formatCurrency(inventory, true)}
   //                </span>
   //             </Col>
   //          </Col>
   //       </Row>
   //    )
   // }

   return visibleView == TYPE_SCREEN.main ? (
      <>
         <Loading isLoading={isLoading} />
         <Row>
            <Col span={24}>
               {/* <Row className="row-container-head">
                  <Col xs={24} md={12}>
                     <Input.Search
                        onSearch={handleChangeTextSearch}
                        placeholder={t(GLOBAL_CLIENT.txtSearch)}
                        allowClear
                     />
                  </Col>
                  <Col xs={24} md={12}>
                     <div className="text-right">
                        <Space>
                           {renderFilter()}
                           <Button type="primary" className="btn-add" onClick={showCreate}>
                              <i className="icon icon-add icon-in-button" />
                              {t(GLOBAL_CLIENT.create)}
                           </Button>
                        </Space>
                     </div>
                  </Col>
               </Row> */}
               {/* <Row className="row-container-head">
                  <Col xs={24} md={12}>
                     <Input.Search
                        onSearch={handleChangeTextSearch}
                        placeholder={t(GLOBAL_CLIENT.txtSearch)}
                        allowClear
                     />
                  </Col>
                  <Col xs={24} md={12}>
                     <div className="text-right">
                        <Space>
                           <RangePicker
                              allowClear={false}
                              defaultValue={[startTime, endTime]}
                              format={DATE_FORMAT_CLIENT}
                              locale={locale}
                              onChange={handleRangePickerChange}
                           />
                        </Space>
                     </div>
                  </Col>
               </Row> */}

               {/* {renderHeader()} */}
               <Space className="total-items">
                  {t(GLOBAL_CLIENT.totalItems)}: {count}
               </Space>

               <Table
                  // scroll={{ x: 1200 }}
                  scroll={{ y: height - 280 }}
                  columns={columns}
                  dataSource={rows}
                  rowKey="id"
                  pagination={false}
                  onChange={onChangeTable}
               />

               {renderPagination()}
            </Col>
         </Row>
         {itemAccount && visibleDetailAccount && (
            <DetailAccount
               visible={visibleDetailAccount}
               item={itemAccount}
               onOk={() => setVisibleDetailAccount(false)}
               onCancel={() => setVisibleDetailAccount(false)}
               onlyView
            />
         )}
         {isTopupModalOpen && (
            <TopupAdminModal
               isTopupModalOpen={isTopupModalOpen}
               topup={topup}
               handleCancel={handleCloseTopup}
               onRefreshList={onRefreshList}
            />
         )}
      </>
   ) : visibleView == TYPE_SCREEN.bill ? (
      <Bill showWarehouse={showMain} stores={item} />
   ) : (
      <ProductWarehouse showWarehouse={showMain} stores={item} />
   )
})

export default WarehouseManagement
