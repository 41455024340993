import { useEffect, useState } from 'react'
import { Input, Select, Modal, Form, InputNumber, Button, notification } from 'antd'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { DEPOSIT_WITHDRAW_TYPE, getUniqueCode } from '../../helpers/constants'
import { debounce, get, map, size } from 'lodash'
import useModal from 'src/helpers/modal/useModal'
import { ExclamationCircleOutlined } from '@ant-design/icons'
const { Option } = Select

const formItemLayout = {
   labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
   },
   wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
   }
}

const DepositWithdrawalDetail = observer((props: any) => {
   const { t } = useTranslation()

   const FundStore = useStore('FundStore')
   const UserStore = useStore('UserStore')
   const AuthStore = useStore('AuthStore')
   const {data_getUserInfo}=AuthStore
   const { visible, onOk, onCancel, item } = props
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [isLoading, setIsLoading] = useState(false)
   const [form] = Form.useForm()
   const { confirm } = useModal()

   useEffect(() => {
      handleGetMemberFilter()
      // handleSetDataDefault();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const handleGetMemberFilter = () => {
      if (size(UserStore.listMemberFilter.rows) === 0) {
         UserStore.getMemberFilter()
      }
   }

   const onRefund = async () => {
      console.log('item,', item)

      confirm({
         title: t(GLOBAL_CLIENT.chapnhanhoantien),
         icon: <ExclamationCircleOutlined />,
         okText: t(GLOBAL_CLIENT.apply),
         cancelText: t(GLOBAL_CLIENT.huy),
         content: `${item?.code}`,
         onCancel() {},
         async onOk() {
            const params = {
               price: item?.price || '',
               priceVND: item?.priceVND ||'',
               code: getUniqueCode(),
               accountId: data_getUserInfo?.id || '',
               description: `Hoàn tiền cho mã phiếu ${item?.code}`,
               type: DEPOSIT_WITHDRAW_TYPE[5].id,
               id: item?.id
               // status:1
            }
            // console.log('params ', params)

            const result = await FundStore.createFund(params)
            if (result) {
               notification.open({
                  message: t(GLOBAL_CLIENT.thongbao),
                  description: t(GLOBAL_CLIENT.refundMessage).replace('{0}', item?.code)
               })
               onCancel()
            }
         }
      })
   }

   const onFinish = async (values: any) => {
      // console.log('Received values of form: ', values)
      //   const params = {
      //      price: values?.price || '',
      //      code: values?.code || '',
      //      accountId: values?.account || '',
      //      description: values?.description || '',
      //      type: values?.type || ''
      //   }
      //   console.log('params ', params)
      //   const result = await FundStore.createFund(params)
      //   if (result) {
      //      form.resetFields()
      //      onOk()
      //   }
   }
   console.log(
      'item?.type: ',
      item?.type,
      item?.type === DEPOSIT_WITHDRAW_TYPE[3].id,
      item?.type === DEPOSIT_WITHDRAW_TYPE[4].id
   )

   return (
      <Modal
         width={'68%'}
         visible={visible}
         centered
         title={t(GLOBAL_CLIENT.detail)}
         okText={t(GLOBAL_CLIENT.refund)}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={onCancel}
         onOk={form.submit}
         footer={[
            <>
               {(item?.type === DEPOSIT_WITHDRAW_TYPE[3].id || item?.type === DEPOSIT_WITHDRAW_TYPE[4].id) && (
                  <Button key="refund" type="primary" onClick={onRefund}>
                     {t(GLOBAL_CLIENT.refund)}
                  </Button>
               )}
               <Button key="back" onClick={onCancel}>
                  Đóng
               </Button>
            </>
         ]}
      >
         <Form
            {...formItemLayout}
            form={form}
            name="create"
            onFinish={debounce(onFinish, 400)}
            initialValues={{
               // residence: ['zhejiang', 'hangzhou', 'xihu'],
               prefix: '+84'
            }}
            scrollToFirstError
         >
            <Form.Item
               rules={[{ required: true, message: 'Vui lòng điền mã phiếu!' }]}
               name="code"
               initialValue={item?.code}
               label={t(GLOBAL_CLIENT.receiptId)}
            >
               <Input disabled />
            </Form.Item>
            <Form.Item
               rules={[{ required: true, message: 'Vui lòng chọn loại thu chi!' }]}
               name="type"
               initialValue={item?.type}
               label={t(GLOBAL_CLIENT.type)}
            >
               <Select placeholder="Chọn Loại thu chi">
                  <Option value={DEPOSIT_WITHDRAW_TYPE[1].id}>{DEPOSIT_WITHDRAW_TYPE[1].title}</Option>
                  <Option value={DEPOSIT_WITHDRAW_TYPE[2].id}>{DEPOSIT_WITHDRAW_TYPE[2].title}</Option>
                  <Option value={DEPOSIT_WITHDRAW_TYPE[3].id}>{DEPOSIT_WITHDRAW_TYPE[3].title}</Option>
                  <Option value={DEPOSIT_WITHDRAW_TYPE[4].id}>{DEPOSIT_WITHDRAW_TYPE[4].title}</Option>
               </Select>
            </Form.Item>
            <Form.Item
               name="account"
               rules={[{ required: true, message: 'Vui lòng chọn người nạp/rút tiền!' }]}
               initialValue={item?.accountId}
               label={t(GLOBAL_CLIENT.withdrawerAndDepositer)}
            >
               <Select
                  placeholder="Chọn người nạp/rút tiền"
                  showSearch
                  // onChange={(value: any) => setUserId(value)}
                  filterOption={(input: string, option: any) =>
                     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
               >
                  {map(get(UserStore, 'listMemberFilter.rows', []), (user: any, index: number) => {
                     return (
                        <Option key={index} value={user?.id}>
                           {user?.name + ` [${user?.role}]`}
                        </Option>
                     )
                  })}
                  {/* {UserStore.listMemberFilter.map((item: any) => {
                     return (
                        <Option key={item?.id} value={item?.code}>
                           {item?.name + `(${item?.role})`}
                        </Option>
                     )
                  })} */}
               </Select>
            </Form.Item>
            <Form.Item
               name="priceVND"
               label={t(GLOBAL_CLIENT.money) + '(VND)'}
               initialValue={item?.priceVND}
               rules={[{ required: true, message: 'Vui lòng nhập số tiền!' }]}
            >
               <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  step={1000}
                  // formatter={value => `${numeral(value).format(NUMBER_FORMAT_SALARY_CLIENT)} đ`}
               />
            </Form.Item>
            <Form.Item
               name="price"
               label={t(GLOBAL_CLIENT.money) + '(USD)'}
               initialValue={item?.price}
               rules={[{ required: true, message: 'Vui lòng nhập số tiền!' }]}
            >
               <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  step={1000}
                  // formatter={value => `${numeral(value).format(NUMBER_FORMAT_SALARY_CLIENT)} đ`}
               />
            </Form.Item>
            {/* <Form.Item name="groupwithdrawerAndDepositer" label={t(GLOBAL_CLIENT.groupwithdrawerAndDepositer)}>
               <Input />
            </Form.Item> */}
            {/* <Form.Item name="status" initialValue={statusFund.NEW} label={t(GLOBAL_CLIENT.status)}>
               <Select placeholder="Chọn trạng thái">
                  {statusFundTitle.map((item: any) => {
                     return (
                        <Option key={item?.id} value={item?.id}>
                           {item?.title}
                        </Option>
                     )
                  })}
               </Select>
            </Form.Item> */}

            <Form.Item
               name="description"
               initialValue={item?.description}
               label={t(GLOBAL_CLIENT.note)}
               rules={[
                  {
                     required: false,
                     message: 'Vui lòng nhập mô tả'
                  }
               ]}
            >
               <Input maxLength={254} />
            </Form.Item>
         </Form>
      </Modal>
   )
})

export default DepositWithdrawalDetail
