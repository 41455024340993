import { Avatar, Popover, Modal, Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useStore } from '../../../hooks'
import { capitalizeFirstLetter, formatCurrency } from '../../../helpers/functions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GLOBAL_CLIENT } from '../../../helpers/GlobalClient'
import { CONFIG_URL, ROLE, ROLES, TAB_SIZE } from '../../../helpers/constants'
import TopupModal from 'src/common/components/topup/TopupModal'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import Images from 'src/common/Images'
import { isEmpty } from 'lodash'
import TransactionHistory from 'src/screens/transaction-history'
import useModal from 'src/helpers/modal/useModal'
import CustomScrollbars from './CustomScrollbars'
import UserNotification from './UserNotification'

const UserMenuWrapper = styled.ul`
   p {
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 8px;
   }
   li {
      img {
         width: 20px;
         height: 20px;
      }
      display: flex;
      width: 100%;
   }
`
const UserInfoWrapper = styled.span`
   color: white;
   cursor: pointer;
   margin-top: auto;
   margin-bottom: auto;
   margin-right: 10px;
   p {
      margin: unset;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-break: anywhere;
      max-width: 130px;
      @media (min-width: 1100px) {
         max-width: 200px;
      }
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      /* identical to box height, or 157% */
      color: #ffffff;
   }
   .user-info__name {
      display: flex;
   }
   .user-info__role-tag {
      margin-left: 4px;
      height: 18px;
      padding: 0 6px;
      background: ${(props) => props.theme.color.dark_2};
      border-radius: 200px;
      color: ${(props) => props.theme.color.white};
      p {
         font-size: 10px;
         line-height: 18px;
         margin: unset;
      }
   }
   div.user-info__price {
      margin: unset;
      font-weight: 400;
      font-size: 10px;
      line-height: 22px;
      & > p {
         color: ${(props) => props.theme.color.dark_1};
      }
      color: ${(props) => props.theme.color.dark_1};
      display: flex;
      justify-content: start;
   }
`

const UserProfile = observer((props: any) => {
   const AuthStore = useStore('AuthStore')
   const JobsStore = useStore('JobsStore')
   const CommonConfigStore = useStore('CommonConfigStore')
   const [visiblePopover, setVisiblePopover] = useState(false)
   const [isTransactionHistoryModalOpen, setIsTransactionHistoryModalOpen] = useState(false)
   const { confirm } = useModal()
   const { t } = useTranslation()
   const { data_getUserInfo, data_role } = AuthStore

   const history = useHistory()
   const [isTopupModalOpen, setIsTopupModalOpen] = useState(false)
   const roleString = capitalizeFirstLetter(data_getUserInfo.role)
   const showModal = () => {
      CommonConfigStore.resetBankAccount()
      setIsTopupModalOpen(true)
   }

   const handleCancel = () => {
      setIsTopupModalOpen(false)
   }

   const handleAdminLogoutClick = () => {
      setVisiblePopover(false)
      setTimeout(() => {
         confirm({
            title: t(GLOBAL_CLIENT.banmuondangxuat),
            icon: <ExclamationCircleOutlined />,
            content: t(GLOBAL_CLIENT.logoutDescription),
            okText: t(GLOBAL_CLIENT.dongy),
            cancelText: t(GLOBAL_CLIENT.huy),
            async onOk() {
               AuthStore.action_logout(history)
               JobsStore.resetJobs()
            },
            onCancel() {}
         })
      }, 100)
   }

   const handleVisibleChange = (visible: any) => {
      setVisiblePopover(visible)
   }

   const handleOpenTopupModal = () => {
      setVisiblePopover(false)
      showModal()
   }

   const handleNavigateProfiles = () => {
      setVisiblePopover(false)
      history.push('/profiles')
   }

   const handleOpenTransactionHistory = () => {
      setVisiblePopover(false)
      setIsTransactionHistoryModalOpen(true)
   }

   const userMenuOptions = (
      <UserMenuWrapper className="gx-user-popover" style={{ padding: '12px' }}>
         <li onClick={handleNavigateProfiles}>
            <img src={Images.ic_user} alt="" />
            <p>{t(GLOBAL_CLIENT.profile)}</p>
         </li>
         {data_role === ROLES.SELLER ? (
            <li onClick={handleOpenTopupModal}>
               <img src={Images.ic_ticket} alt="" />
               <p>{t(GLOBAL_CLIENT.deposit)}</p>
            </li>
         ) : (
            <></>
            // <li onClick={handleOpenTopupModal}>
            //    <img src={Images.ic_ticket} alt="" />
            //    <p>{t(GLOBAL_CLIENT.withdawal)}</p>
            // </li>
         )}
         {data_role === ROLES.FULFILLMENT && (
            <li onClick={handleOpenTransactionHistory}>
               <img src={Images.ic_ticket} alt="" />
               <p>{t(GLOBAL_CLIENT.withdawal)}</p>
            </li>
         )}

         {data_role !== ROLES.ADMIN && (
            <li onClick={handleOpenTransactionHistory}>
               <img src={Images.ic_chart} alt="" />
               <p>{t(GLOBAL_CLIENT.transactionHistory)}</p>
            </li>
         )}

         <li onClick={handleAdminLogoutClick}>
            <img src={Images.logout} style={{ height: 16, width: 16, marginLeft: 4 }} alt="" />
            <p>{t(GLOBAL_CLIENT.txtLogout)}</p>
         </li>
      </UserMenuWrapper>
   )

   return (
      <div className="gx-flex-row gx-align-items-center gx-avatar-row">
         {isTopupModalOpen && <TopupModal isTopupModalOpen={isTopupModalOpen} handleCancel={handleCancel} />}
         {data_role !== ROLE.ADMIN && (
            <TransactionHistory
            role={data_role}
               visible={isTransactionHistoryModalOpen}
               handleCancel={() => setIsTransactionHistoryModalOpen(false)}
               handleOk={() => setIsTransactionHistoryModalOpen(false)}
            />
         )}
         <Popover
            placement="bottomRight"
            content={userMenuOptions}
            trigger="click"
            visible={visiblePopover}
            onVisibleChange={handleVisibleChange}
            overlayStyle={{ zIndex: '1032' }}
         >
            <div style={{ display: 'flex' }}>
               <UserInfoWrapper>
                  <div className="user-info__name text-oneline gx-mt-auto gx-mb-auto">
                     <Tooltip placement="top" title={data_getUserInfo?.name || data_getUserInfo?.email}>
                        <p>{data_getUserInfo?.name || data_getUserInfo?.email}</p>
                     </Tooltip>
                     {
                        <div className="user-info__role-tag">
                           <Tooltip placement="top" title={roleString}>
                              <p>{roleString}</p>
                           </Tooltip>
                        </div>
                     }
                  </div>
                  <div className="user-info__price">
                     {data_getUserInfo.role === ROLES.SELLER || data_getUserInfo.role === ROLES.FULFILLMENT ? (
                        <>
                           <p className="text-oneline">${formatCurrency(data_getUserInfo?.wallet?.balance)}</p>
                           <span>&nbsp;</span>
                        </>
                     ) : (
                        <p>{roleString}</p>
                     )}
                  </div>
               </UserInfoWrapper>
               <Avatar
                  src={
                     !isEmpty(data_getUserInfo.avatar)
                        ? CONFIG_URL.IMAGE_URL + '/' + data_getUserInfo.avatar
                        : Images.icon_avatar
                  }
                  className="gx-size-40 gx-pointer image-avatar"
                  alt="avatar"
               />
               {/* {AuthStore.width_screen >= TAB_SIZE && (
                  <span className="gx-avatar-name gx-mt-auto gx-mb-auto">
                     <i
                        className={`icon icon-chevron-down gx-fs-xxs gx-ml-2 ${props.isTopbar ? 'gx-icon-white' : ''}`}
                     />
                  </span>
               )} */}
            </div>
         </Popover>
      </div>
   )
})

export default UserProfile
