 export const exchangeFeeListFake=[
    {
        "id": 6,
        "productTypeId": 6,
        "designTypeId": 2,
        "percent": 15,
        "max": 100000,
        "min": 27000,
        "deletedAt": null,
        "createdAt": "2024-06-13T15:55:13.320Z",
        "updatedAt": "2024-02-16T14:31:30.226Z",
        "productType": {
            "id": 6,
            "name": " Van Tien Minh TamT-Shirt 2D",
            "countJob": 0,
            "index": 0,
            "deletedAt": null,
            "createdAt": "2024-06-13T15:55:13.312Z",
            "updatedAt": "2024-06-13T15:55:13.312Z"
        },
        "designType": {
            "id": 2,
            "name": " Van Tien Minh TamClone",
            "countJob": 0,
            "index": 0,
            "deletedAt": null,
            "createdAt": "2024-06-13T15:54:53.563Z",
            "updatedAt": "2024-06-13T15:54:53.563Z"
        }
    },
    {
        "id": 7,
        "productTypeId": 7,
        "designTypeId": 2,
        "percent": 12,
        "max": 100000,
        "min": 45000,
        "deletedAt": null,
        "createdAt": "2024-06-13T15:55:16.980Z",
        "updatedAt": "2024-02-16T14:27:52.565Z",
        "productType": {
            "id": 7,
            "name": " Van Tien Minh TamMug",
            "countJob": 0,
            "index": 3,
            "deletedAt": null,
            "createdAt": "2024-06-13T15:55:16.975Z",
            "updatedAt": "2024-06-13T15:56:37.355Z"
        },
        "designType": {
            "id": 2,
            "name": " Van Tien Minh TamClone",
            "countJob": 0,
            "index": 0,
            "deletedAt": null,
            "createdAt": "2024-06-13T15:54:53.563Z",
            "updatedAt": "2024-06-13T15:54:53.563Z"
        }
    },
    {
        "id": 9,
        "productTypeId": 9,
        "designTypeId": 2,
        "percent": 15,
        "max": 100000,
        "min": 27000,
        "deletedAt": null,
        "createdAt": "2024-06-13T15:55:24.928Z",
        "updatedAt": "2024-02-16T14:28:00.379Z",
        "productType": {
            "id": 9,
            "name": " Van Tien Minh TamOrnament",
            "countJob": 0,
            "index": 5,
            "deletedAt": null,
            "createdAt": "2024-06-13T15:55:24.919Z",
            "updatedAt": "2024-06-13T15:57:23.932Z"
        },
        "designType": {
            "id": 2,
            "name": " Van Tien Minh TamClone",
            "countJob": 0,
            "index": 0,
            "deletedAt": null,
            "createdAt": "2024-06-13T15:54:53.563Z",
            "updatedAt": "2024-06-13T15:54:53.563Z"
        }
    },
    {
        "id": 10,
        "productTypeId": 10,
        "designTypeId": 2,
        "percent": 33,
        "max": 100000,
        "min": 45000,
        "deletedAt": null,
        "createdAt": "2024-06-13T15:55:30.585Z",
        "updatedAt": "2024-02-16T14:28:18.190Z",
        "productType": {
            "id": 10,
            "name": " Van Tien Minh TamPoster",
            "countJob": 0,
            "index": 6,
            "deletedAt": null,
            "createdAt": "2024-06-13T15:55:30.579Z",
            "updatedAt": "2024-06-13T15:57:49.403Z"
        },
        "designType": {
            "id": 2,
            "name": " Van Tien Minh TamClone",
            "countJob": 0,
            "index": 0,
            "deletedAt": null,
            "createdAt": "2024-06-13T15:54:53.563Z",
            "updatedAt": "2024-06-13T15:54:53.563Z"
        }
    },
    {
        "id": 11,
        "productTypeId": 11,
        "designTypeId": 2,
        "percent": 25,
        "max": 100000,
        "min": 45000,
        "deletedAt": null,
        "createdAt": "2024-06-13T15:55:34.579Z",
        "updatedAt": "2024-02-16T14:28:35.727Z",
        "productType": {
            "id": 11,
            "name": " Van Tien Minh TamCanvas",
            "countJob": 0,
            "index": 7,
            "deletedAt": null,
            "createdAt": "2024-06-13T15:55:34.573Z",
            "updatedAt": "2024-06-13T15:57:54.770Z"
        },
        "designType": {
            "id": 2,
            "name": " Van Tien Minh TamClone",
            "countJob": 0,
            "index": 0,
            "deletedAt": null,
            "createdAt": "2024-06-13T15:54:53.563Z",
            "updatedAt": "2024-06-13T15:54:53.563Z"
        }
    },
    {
        "id": 12,
        "productTypeId": 12,
        "designTypeId": 2,
        "percent": 25,
        "max": 100000,
        "min": 45000,
        "deletedAt": null,
        "createdAt": "2024-06-13T15:55:39.164Z",
        "updatedAt": "2024-02-16T14:28:27.523Z",
        "productType": {
            "id": 12,
            "name": " Van Tien Minh TamTumbler",
            "countJob": 0,
            "index": 8,
            "deletedAt": null,
            "createdAt": "2024-06-13T15:55:39.158Z",
            "updatedAt": "2024-06-13T15:58:02.112Z"
        },
        "designType": {
            "id": 2,
            "name": " Van Tien Minh TamClone",
            "countJob": 0,
            "index": 0,
            "deletedAt": null,
            "createdAt": "2024-06-13T15:54:53.563Z",
            "updatedAt": "2024-06-13T15:54:53.563Z"
        }
    },
    {
        "id": 13,
        "productTypeId": 13,
        "designTypeId": 2,
        "percent": 20,
        "max": 200000,
        "min": 80000,
        "deletedAt": null,
        "createdAt": "2024-06-13T15:55:46.239Z",
        "updatedAt": "2024-02-16T14:28:51.120Z",
        "productType": {
            "id": 13,
            "name": " Van Tien Minh TamQuilt",
            "countJob": 0,
            "index": 13,
            "deletedAt": null,
            "createdAt": "2024-06-13T15:55:46.234Z",
            "updatedAt": "2024-06-13T15:56:58.379Z"
        },
        "designType": {
            "id": 2,
            "name": " Van Tien Minh TamClone",
            "countJob": 0,
            "index": 0,
            "deletedAt": null,
            "createdAt": "2024-06-13T15:54:53.563Z",
            "updatedAt": "2024-06-13T15:54:53.563Z"
        }
    },
    {
        "id": 17,
        "productTypeId": 17,
        "designTypeId": 2,
        "percent": 15,
        "max": 300000,
        "min": 80000,
        "deletedAt": null,
        "createdAt": "2024-06-17T02:21:39.663Z",
        "updatedAt": "2024-02-16T14:28:57.751Z",
        "productType": {
            "id": 17,
            "name": " Van Tien Minh TamBlanket",
            "countJob": 0,
            "index": 13,
            "deletedAt": null,
            "createdAt": "2024-06-17T02:21:39.648Z",
            "updatedAt": "2024-06-17T02:21:51.104Z"
        },
        "designType": {
            "id": 2,
            "name": " Van Tien Minh TamClone",
            "countJob": 0,
            "index": 0,
            "deletedAt": null,
            "createdAt": "2024-06-13T15:54:53.563Z",
            "updatedAt": "2024-06-13T15:54:53.563Z"
        }
    },
    {
        "id": 19,
        "productTypeId": 19,
        "designTypeId": 2,
        "percent": 12,
        "max": 100000,
        "min": 35000,
        "deletedAt": null,
        "createdAt": "2024-06-23T06:25:32.036Z",
        "updatedAt": "2024-02-16T14:29:06.789Z",
        "productType": {
            "id": 19,
            "name": " Van Tien Minh TamFlag",
            "countJob": 0,
            "index": 19,
            "deletedAt": null,
            "createdAt": "2024-06-23T06:25:32.027Z",
            "updatedAt": "2024-07-14T14:32:03.533Z"
        },
        "designType": {
            "id": 2,
            "name": " Van Tien Minh TamClone",
            "countJob": 0,
            "index": 0,
            "deletedAt": null,
            "createdAt": "2024-06-13T15:54:53.563Z",
            "updatedAt": "2024-06-13T15:54:53.563Z"
        }
    },
    {
        "id": 33,
        "productTypeId": 21,
        "designTypeId": 2,
        "percent": 19,
        "max": 300000,
        "min": 80000,
        "deletedAt": null,
        "createdAt": "2024-09-08T03:43:48.304Z",
        "updatedAt": "2024-02-16T14:27:45.068Z",
        "productType": {
            "id": 21,
            "name": " Van Tien Minh TamT-Shirt 3D",
            "countJob": 0,
            "index": 1,
            "deletedAt": null,
            "createdAt": "2024-09-08T03:43:48.287Z",
            "updatedAt": "2024-07-24T06:38:02.339Z"
        },
        "designType": {
            "id": 2,
            "name": " Van Tien Minh TamClone",
            "countJob": 0,
            "index": 0,
            "deletedAt": null,
            "createdAt": "2024-06-13T15:54:53.563Z",
            "updatedAt": "2024-06-13T15:54:53.563Z"
        }
    },
    {
        "id": 35,
        "productTypeId": 22,
        "designTypeId": 2,
        "percent": 7,
        "max": 100000,
        "min": 10000,
        "deletedAt": null,
        "createdAt": "2024-07-04T07:42:14.286Z",
        "updatedAt": "2024-02-16T14:29:16.585Z",
        "productType": {
            "id": 22,
            "name": " Van Tien Minh TamOther",
            "countJob": 0,
            "index": 22,
            "deletedAt": null,
            "createdAt": "2024-07-04T07:42:14.276Z",
            "updatedAt": "2024-07-04T07:42:23.148Z"
        },
        "designType": {
            "id": 2,
            "name": " Van Tien Minh TamClone",
            "countJob": 0,
            "index": 0,
            "deletedAt": null,
            "createdAt": "2024-06-13T15:54:53.563Z",
            "updatedAt": "2024-06-13T15:54:53.563Z"
        }
    },
    {
        "id": 37,
        "productTypeId": 23,
        "designTypeId": 2,
        "percent": 15,
        "max": 100000,
        "min": 35000,
        "deletedAt": null,
        "createdAt": "2024-07-07T02:25:59.615Z",
        "updatedAt": "2024-02-16T14:28:45.139Z",
        "productType": {
            "id": 23,
            "name": " Van Tien Minh TamVintage Bootleg",
            "countJob": 0,
            "index": 7,
            "deletedAt": null,
            "createdAt": "2024-07-07T02:25:59.603Z",
            "updatedAt": "2024-07-24T06:34:15.651Z"
        },
        "designType": {
            "id": 2,
            "name": " Van Tien Minh TamClone",
            "countJob": 0,
            "index": 0,
            "deletedAt": null,
            "createdAt": "2024-06-13T15:54:53.563Z",
            "updatedAt": "2024-06-13T15:54:53.563Z"
        }
    },
    {
        "id": 39,
        "productTypeId": 24,
        "designTypeId": 2,
        "percent": 10,
        "max": 100000,
        "min": 39000,
        "deletedAt": null,
        "createdAt": "2024-07-24T06:33:50.551Z",
        "updatedAt": "2024-02-16T14:29:27.974Z",
        "productType": {
            "id": 24,
            "name": " Van Tien Minh TamCrocs",
            "countJob": 0,
            "index": 8,
            "deletedAt": null,
            "createdAt": "2024-07-24T06:33:50.538Z",
            "updatedAt": "2024-07-24T06:34:22.952Z"
        },
        "designType": {
            "id": 2,
            "name": " Van Tien Minh TamClone",
            "countJob": 0,
            "index": 0,
            "deletedAt": null,
            "createdAt": "2024-06-13T15:54:53.563Z",
            "updatedAt": "2024-06-13T15:54:53.563Z"
        }
    },
    {
        "id": 41,
        "productTypeId": 25,
        "designTypeId": 2,
        "percent": 20,
        "max": 200000,
        "min": 80000,
        "deletedAt": null,
        "createdAt": "2024-07-24T06:37:37.610Z",
        "updatedAt": "2024-02-16T14:27:40.219Z",
        "productType": {
            "id": 25,
            "name": " Van Tien Minh Tam3D Sweater",
            "countJob": 0,
            "index": 9,
            "deletedAt": null,
            "createdAt": "2024-07-24T06:37:37.600Z",
            "updatedAt": "2024-07-24T06:37:52.094Z"
        },
        "designType": {
            "id": 2,
            "name": " Van Tien Minh TamClone",
            "countJob": 0,
            "index": 0,
            "deletedAt": null,
            "createdAt": "2024-06-13T15:54:53.563Z",
            "updatedAt": "2024-06-13T15:54:53.563Z"
        }
    }
]
//fund
export const fundListFake={
    "limit": "100",
    "offset": "0",
    "count": 334,
    "rows": [
        {
            "id": 3642,
            "accountId": 657,
            "code": "9GYOGRH",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-09T14:08:15.080Z",
            "updatedAt": "2024-07-09T14:44:40.031Z",
            "account": {
                "id": 657,
                "email": "runaway051098@gmail.com",
                "password": "$2a$08$cUdlmZcwq3t36p1IbybZ5egBTF4O29YgOlE0qh/oHMJtky.ejxrNa",
                "role": "creator",
                "name": " Van Tien Minh TamHuy Hoàng",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/huyhoang051098",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 65,
                "countJob": 64,
                "phoneNumber": "0985182690",
                "isEmailVerified": true,
                "avatar": "static/20240603/2024060320314980.jpeg",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-03T13:24:33.993Z",
                "updatedAt": "2024-07-09T14:25:29.201Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3641,
            "accountId": 268,
            "code": "H5IJWUE",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-09T12:27:33.831Z",
            "updatedAt": "2024-07-09T12:32:28.342Z",
            "account": {
                "id": 268,
                "email": "maitron175@gmail.com",
                "password": "$2a$08$5fBce2NdqBLo0PyQM7TXlerI57VSQHjcK413jxQsXy/NER9oauBWW",
                "role": "creator",
                "name": " Van Tien Minh TamKun kizo",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": "Supporter: Nguyễn Thành",
                "note": null,
                "linkFb": null,
                "linkTelegram": "Mai Lê",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 126,
                "countJob": 125,
                "phoneNumber": "0936177489",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-10-11T12:31:33.831Z",
                "updatedAt": "2024-07-09T12:26:33.923Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3640,
            "accountId": 624,
            "code": "JNXSSUU",
            "status": 1,
            "accountApprove": null,
            "type": 2,
            "price": 900000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-07-09T12:00:51.120Z",
            "updatedAt": "2024-07-09T12:00:51.120Z",
            "account": {
                "id": 624,
                "email": "lethithuhang104@gmail.com",
                "password": "$2a$08$3yrV8odQ4.NeVWrA2THp.e3oWyDwjXsmoxCx/tMr7S7h5L.Cab/Vi",
                "role": "designer",
                "name": " Van Tien Minh TamTHU HẰNG",
                "bankName": "BIDV",
                "bankNumber": "8880174148",
                "accountBankName": "Lê Thị Thu Hằng",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/My My",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 602,
                "countJob": 574,
                "phoneNumber": "0944350363",
                "isEmailVerified": true,
                "avatar": "static/20240507/2024050713386560.jpeg",
                "supporterId": null,
                "countReject": 276,
                "deletedAt": null,
                "createdAt": "2024-05-03T04:53:17.281Z",
                "updatedAt": "2024-07-09T11:32:34.442Z",
                "SupporterId": null
            },
            "accountApproveInfo": null
        },
        {
            "id": 3639,
            "accountId": 444,
            "code": "QIRPVNG",
            "status": 2,
            "accountApprove": 343,
            "type": 2,
            "price": 650200,
            "description": "...",
            "deletedAt": null,
            "createdAt": "2024-07-09T08:14:48.975Z",
            "updatedAt": "2024-07-09T08:17:45.487Z",
            "account": {
                "id": 444,
                "email": "trongk57cbls@gmail.com",
                "password": "$2a$08$B.u/zx3L7q3XyK.bULhEBeS5nU7GCKLMn21c1hsfKosYgreDkKdT6",
                "role": "creator",
                "name": " Van Tien Minh TamNguyen Van Trong",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/nguyenvantrong231/",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 410,
                "countJob": 409,
                "phoneNumber": "0969259231",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-12-21T04:13:39.893Z",
                "updatedAt": "2024-07-09T09:40:43.097Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3638,
            "accountId": 664,
            "code": "SYH3632",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-09T07:56:23.134Z",
            "updatedAt": "2024-07-09T07:56:29.717Z",
            "account": {
                "id": 664,
                "email": "hcinfi.tong@gmail.com",
                "password": "$2a$08$THhDNy0ENiBtej8bq1/usek5F0YS4EJjTEG9MVmkV6/S5lTMYBiI6",
                "role": "creator",
                "name": " Van Tien Minh TamMoney Gang",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/monkeypg",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 135,
                "countJob": 135,
                "phoneNumber": "0963202320",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-08T09:01:52.638Z",
                "updatedAt": "2024-07-08T19:00:01.220Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3637,
            "accountId": 688,
            "code": "QYI5F93",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 2000000,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-09T06:06:29.873Z",
            "updatedAt": "2024-07-09T06:26:53.014Z",
            "account": {
                "id": 688,
                "email": "thaydoitichcucso03@gmail.com",
                "password": "$2a$08$lx7pLusTFnF1eO1oXj1UPeGtOkDA8tAkOgz4qxndWHcB8..sx68Bi",
                "role": "creator",
                "name": " Van Tien Minh TamChu Tường Huy",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "0388655968",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0388655968",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-09T06:05:02.316Z",
                "updatedAt": "2024-07-09T06:05:02.316Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3636,
            "accountId": 557,
            "code": "QNPAF60",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 1000000,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-09T05:36:43.645Z",
            "updatedAt": "2024-07-09T05:38:21.104Z",
            "account": {
                "id": 557,
                "email": "nguyenhp9x9999@gmail.com",
                "password": "$2a$08$WbP67xnhg9YHS890pP8ip.oLRbmjoP4yeUch9HQn1XLBAlGw3/uni",
                "role": "creator",
                "name": " Van Tien Minh Tamnguyen van nguyen",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "0963902110",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 518,
                "countJob": 518,
                "phoneNumber": "0977034567",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-03-27T02:18:20.474Z",
                "updatedAt": "2024-07-09T08:22:10.080Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3635,
            "accountId": 382,
            "code": "7MO8KQC",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-09T04:36:56.958Z",
            "updatedAt": "2024-07-09T04:40:15.241Z",
            "account": {
                "id": 382,
                "email": "janepham3012@gmail.com",
                "password": "$2a$08$BIEzCh9V07.vWQQ29eGCAekp4zl29Ga7NaJXk1J61nxuICk1GkYC6",
                "role": "creator",
                "name": " Van Tien Minh TamJane",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "@janejanepa",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 489,
                "countJob": 489,
                "phoneNumber": "0985816167",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-29T03:26:16.777Z",
                "updatedAt": "2024-07-09T04:29:36.349Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3634,
            "accountId": 489,
            "code": "PCJ9NTW",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 2000000,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-09T04:03:10.285Z",
            "updatedAt": "2024-07-09T04:05:34.482Z",
            "account": {
                "id": 489,
                "email": "vodienshopping@gmail.com",
                "password": "$2a$08$A61PqwKoR/Ckx8t6n6pp3u7wsUPG2nx2YaJCE3x20RddC7qzqUiam",
                "role": "creator",
                "name": " Van Tien Minh TamThao",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/thao.hare.5/",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 628,
                "countJob": 625,
                "phoneNumber": "0387754597",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-01-27T09:18:52.306Z",
                "updatedAt": "2024-07-09T04:28:47.705Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3633,
            "accountId": 215,
            "code": "VYR163B",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-09T02:43:09.879Z",
            "updatedAt": "2024-07-09T02:45:35.949Z",
            "account": {
                "id": 215,
                "email": "bigprooooo@gmail.com",
                "password": "$2a$08$HBuyv2s9IMOoVKAgq4OCA.aqkfmXgVhx2SljL7i93RcBuD8eM5Mda",
                "role": "creator",
                "name": " Van Tien Minh TamPham An",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/an.pham.92167789",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 957,
                "countJob": 945,
                "phoneNumber": "0978299507",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-09-11T01:59:22.681Z",
                "updatedAt": "2024-07-06T19:00:01.832Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3632,
            "accountId": 360,
            "code": "Z3A0O87",
            "status": 2,
            "accountApprove": 20,
            "type": 2,
            "price": 1514000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-07-08T19:47:47.655Z",
            "updatedAt": "2024-07-08T23:14:00.714Z",
            "account": {
                "id": 360,
                "email": "toannd11091993@gmail.com",
                "password": "$2a$08$h3C1HAapXdpvhgS3GRizEu.n3umXpPLjQfRY8KVrwplV82s7LDK0K",
                "role": "designer",
                "name": " Van Tien Minh TamSallemBro",
                "bankName": "VPBank",
                "bankNumber": "999992668",
                "accountBankName": "NGUYEN DANH TOAN",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/toannd091993",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 1306,
                "countJob": 1271,
                "phoneNumber": "0976697593",
                "isEmailVerified": true,
                "avatar": "static/20240417/2024041700486450.png",
                "supporterId": null,
                "countReject": 284,
                "deletedAt": null,
                "createdAt": "2024-07-16T15:20:03.558Z",
                "updatedAt": "2024-07-08T19:00:01.395Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3631,
            "accountId": 639,
            "code": "8QKP70K",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 1000000,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-08T11:31:37.520Z",
            "updatedAt": "2024-07-08T11:58:55.697Z",
            "account": {
                "id": 639,
                "email": "tranphuchung9999@gmail.com",
                "password": "$2a$08$jmykR4zPzmjT75FWDX/pVOd/jYuHu/w2PE4xNsCKucGMZCzUAvvju",
                "role": "creator",
                "name": " Van Tien Minh TamTRAN PHUC HUNG",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "9AfhfN7fSX@PvrT",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 72,
                "countJob": 54,
                "phoneNumber": "0974722332",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-05-12T16:04:17.895Z",
                "updatedAt": "2024-07-08T19:00:01.325Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3630,
            "accountId": 473,
            "code": "BX62447",
            "status": 3,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-08T09:40:43.948Z",
            "updatedAt": "2024-07-08T09:44:41.088Z",
            "account": {
                "id": 473,
                "email": "tunglam77777777@gmail.com",
                "password": "$2a$08$3R1i2fjFTFGKtZT.jMePVO28mkw15Z0wm6B/RgT2lJg/J7Gi75rEu",
                "role": "creator",
                "name": " Van Tien Minh TamAnh Lâm ĐZ",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "@lam92dz",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 494,
                "countJob": 482,
                "phoneNumber": "0981018666",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-01-12T02:41:57.655Z",
                "updatedAt": "2024-07-09T09:53:45.547Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3629,
            "accountId": 473,
            "code": "CK9K1A5",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-08T09:39:53.917Z",
            "updatedAt": "2024-07-08T09:44:34.993Z",
            "account": {
                "id": 473,
                "email": "tunglam77777777@gmail.com",
                "password": "$2a$08$3R1i2fjFTFGKtZT.jMePVO28mkw15Z0wm6B/RgT2lJg/J7Gi75rEu",
                "role": "creator",
                "name": " Van Tien Minh TamAnh Lâm ĐZ",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "@lam92dz",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 494,
                "countJob": 482,
                "phoneNumber": "0981018666",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-01-12T02:41:57.655Z",
                "updatedAt": "2024-07-09T09:53:45.547Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3628,
            "accountId": 659,
            "code": "B3ZEWFM",
            "status": 2,
            "accountApprove": 20,
            "type": 2,
            "price": 907000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-07-08T08:34:05.446Z",
            "updatedAt": "2024-07-08T23:13:45.227Z",
            "account": {
                "id": 659,
                "email": "dmthanh1502@gmail.com",
                "password": "$2a$08$zZbe3VcbFuwi4T5JpuNZpOenvpXRUTi2rzPtLnvGSYXy7W3Koy.bm",
                "role": "designer",
                "name": " Van Tien Minh TamĐào Minh Thành",
                "bankName": "TCB",
                "bankNumber": "1915021997",
                "accountBankName": "Đào Minh Thành",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/dmthanh1502",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 191,
                "countJob": 180,
                "phoneNumber": "0762600541",
                "isEmailVerified": true,
                "avatar": "static/20240708/2024070815347880.jpeg",
                "supporterId": null,
                "countReject": 33,
                "deletedAt": null,
                "createdAt": "2024-06-05T04:22:33.384Z",
                "updatedAt": "2024-07-09T14:25:29.199Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3627,
            "accountId": 382,
            "code": "24JLBD3",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-08T07:56:51.520Z",
            "updatedAt": "2024-07-08T08:01:50.270Z",
            "account": {
                "id": 382,
                "email": "janepham3012@gmail.com",
                "password": "$2a$08$BIEzCh9V07.vWQQ29eGCAekp4zl29Ga7NaJXk1J61nxuICk1GkYC6",
                "role": "creator",
                "name": " Van Tien Minh TamJane",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "@janejanepa",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 489,
                "countJob": 489,
                "phoneNumber": "0985816167",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-29T03:26:16.777Z",
                "updatedAt": "2024-07-09T04:29:36.349Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3626,
            "accountId": 376,
            "code": "N32S92K",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-08T07:02:23.473Z",
            "updatedAt": "2024-07-08T07:04:48.598Z",
            "account": {
                "id": 376,
                "email": "nhatductranhuynh2311@gmail.com",
                "password": "$2a$08$sGrNwoLEBeOYPNKLr9s1pOhcvE8KmwKCn5ohLu1rhg.l48jHlDydO",
                "role": "creator",
                "name": " Van Tien Minh TamTrần Huỳnh Nhật Đức",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "123456pH@",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 646,
                "countJob": 645,
                "phoneNumber": "0378455488",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-27T09:17:21.022Z",
                "updatedAt": "2024-07-09T04:37:48.674Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3625,
            "accountId": 640,
            "code": "BZW9AQM",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-08T07:01:52.713Z",
            "updatedAt": "2024-07-08T07:03:55.435Z",
            "account": {
                "id": 640,
                "email": "keohot3@gmail.com",
                "password": "$2a$08$7fSDqXQwarfxatn6ALjp0.Mq1beBnAX.444f49Eku4de0XXefxBuC",
                "role": "creator",
                "name": " Van Tien Minh TamThiệu",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "0342280028",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 233,
                "countJob": 233,
                "phoneNumber": "0342280028",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-05-13T07:08:04.891Z",
                "updatedAt": "2024-07-09T11:05:42.569Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3624,
            "accountId": 99,
            "code": "8QIKP4I",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 2000000,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-08T03:18:40.689Z",
            "updatedAt": "2024-07-08T03:26:47.200Z",
            "account": {
                "id": 99,
                "email": "mdgroupnt@gmail.com",
                "password": "$2a$08$cPdOEcee3l5iPKuAbxpIK.p0aqoHtMA/M5OylTkgliTLAawegPccO",
                "role": "creator",
                "name": " Van Tien Minh Tammdgroupnt",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/phamminhday/",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 2163,
                "countJob": 2095,
                "phoneNumber": "0355246619",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-28T06:52:38.461Z",
                "updatedAt": "2024-07-08T03:55:41.155Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3623,
            "accountId": 335,
            "code": "XE4M2AB",
            "status": 2,
            "accountApprove": 20,
            "type": 2,
            "price": 2940000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-07-08T02:51:10.425Z",
            "updatedAt": "2024-07-08T08:04:50.980Z",
            "account": {
                "id": 335,
                "email": "tanbomedia@gmail.com",
                "password": "$2a$08$sNxGJ.Lki2sTR1438DUNDOm.2xxkViIti7ZJUaSqk39cURH3Vbkna",
                "role": "designer",
                "name": " Van Tien Minh TamVõ Tấn Bộ",
                "bankName": "VPB",
                "bankNumber": "38357777779",
                "accountBankName": "Võ Tấn Bộ",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/tanbodn",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 993,
                "countJob": 958,
                "phoneNumber": "0935349866",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 198,
                "deletedAt": null,
                "createdAt": "2024-07-09T07:55:40.298Z",
                "updatedAt": "2024-07-09T08:04:40.676Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3622,
            "accountId": 673,
            "code": "#9629180",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 135000,
            "description": "refund",
            "deletedAt": null,
            "createdAt": "2024-07-08T02:32:13.991Z",
            "updatedAt": "2024-07-08T02:32:13.991Z",
            "account": {
                "id": 673,
                "email": "khaclinh95vn@gmail.com",
                "password": "$2a$08$a0st25IsefA5oe2tA/g/VOOy5pOKf1p.6/iqNqzA/ScJ25vIVnN7C",
                "role": "creator",
                "name": " Van Tien Minh TamNguyễn Khắc Linh",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/Siroabe",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 47,
                "countJob": 35,
                "phoneNumber": "0967333663",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-18T02:09:16.606Z",
                "updatedAt": "2024-07-08T06:41:41.750Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3621,
            "accountId": 157,
            "code": "Z396Q6Y",
            "status": 2,
            "accountApprove": 20,
            "type": 2,
            "price": 2148000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-07-08T02:32:05.812Z",
            "updatedAt": "2024-07-08T08:04:30.122Z",
            "account": {
                "id": 157,
                "email": "khn.qo99@gmail.com",
                "password": "$2a$08$XIhl494dl5HmHIhbuJHVQudf1.OulLg2r1bR7DeJMJs5nT7OwKQKu",
                "role": "designer",
                "name": " Van Tien Minh TamNguyễn Tất Khuyên",
                "bankName": "BIDV",
                "bankNumber": "12510001617420",
                "accountBankName": "NGUYEN TAT KHUYEN",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/khnqo99",
                "salary": null,
                "status": 1,
                "rate": 4.865384615384615,
                "sampleNumber": 1309,
                "countJob": 1271,
                "phoneNumber": "0964142285",
                "isEmailVerified": true,
                "avatar": "static/20231112/2023111201125040.jpeg",
                "supporterId": null,
                "countReject": 412,
                "deletedAt": null,
                "createdAt": "2024-08-10T16:04:07.769Z",
                "updatedAt": "2024-07-08T04:58:13.880Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3620,
            "accountId": 673,
            "code": "#4957619",
            "status": 2,
            "accountApprove": 343,
            "type": 4,
            "price": 135000,
            "description": "hoàn refund",
            "deletedAt": null,
            "createdAt": "2024-07-08T02:31:44.094Z",
            "updatedAt": "2024-07-08T02:31:44.094Z",
            "account": {
                "id": 673,
                "email": "khaclinh95vn@gmail.com",
                "password": "$2a$08$a0st25IsefA5oe2tA/g/VOOy5pOKf1p.6/iqNqzA/ScJ25vIVnN7C",
                "role": "creator",
                "name": " Van Tien Minh TamNguyễn Khắc Linh",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/Siroabe",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 47,
                "countJob": 35,
                "phoneNumber": "0967333663",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-18T02:09:16.606Z",
                "updatedAt": "2024-07-08T06:41:41.750Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3619,
            "accountId": 673,
            "code": "#5158639",
            "status": 2,
            "accountApprove": 343,
            "type": 3,
            "price": 135000,
            "description": "refund",
            "deletedAt": null,
            "createdAt": "2024-07-08T02:27:55.935Z",
            "updatedAt": "2024-07-08T02:27:55.935Z",
            "account": {
                "id": 673,
                "email": "khaclinh95vn@gmail.com",
                "password": "$2a$08$a0st25IsefA5oe2tA/g/VOOy5pOKf1p.6/iqNqzA/ScJ25vIVnN7C",
                "role": "creator",
                "name": " Van Tien Minh TamNguyễn Khắc Linh",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/Siroabe",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 47,
                "countJob": 35,
                "phoneNumber": "0967333663",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-18T02:09:16.606Z",
                "updatedAt": "2024-07-08T06:41:41.750Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3618,
            "accountId": 331,
            "code": "QS98X48",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-08T02:18:16.871Z",
            "updatedAt": "2024-07-08T02:21:39.260Z",
            "account": {
                "id": 331,
                "email": "nguyenhoanhd846@gmail.com",
                "password": "$2a$08$J6SPpxBb14pyv1nNFenibepemCWpDGMC7zlW4Eh1wO.TR/OWSZz1m",
                "role": "creator",
                "name": " Van Tien Minh TamNguyễn Thường",
                "bankName": "MBBank",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "@nmt2411",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 947,
                "countJob": 881,
                "phoneNumber": "0984711715",
                "isEmailVerified": true,
                "avatar": "static/20231230/2023123010213540.jpeg",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-09T02:01:44.677Z",
                "updatedAt": "2024-07-09T07:59:46.717Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3617,
            "accountId": 52,
            "code": "3YD5AGU",
            "status": 2,
            "accountApprove": 20,
            "type": 2,
            "price": 5408000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-07-07T22:01:24.423Z",
            "updatedAt": "2024-07-08T08:04:15.149Z",
            "account": {
                "id": 52,
                "email": "lecanhhieu1996@gmail.com",
                "password": "$2a$08$5ovzFRNmKrAO2m8aJFVKYuTk1/KH2QCmyjSG1X4eIc7dDiW3hi/jy",
                "role": "designer",
                "name": " Van Tien Minh TamHiếu Lê",
                "bankName": "Techcombank",
                "bankNumber": "19035780374011",
                "accountBankName": "LE CANH HIEU",
                "idCard": null,
                "address": "admin@gmail.com",
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/lê hiếu",
                "salary": null,
                "status": 1,
                "rate": 4.927710843373494,
                "sampleNumber": 2077,
                "countJob": 1970,
                "phoneNumber": "0794549273",
                "isEmailVerified": true,
                "avatar": "static/20231230/2023123020078810.jpeg",
                "supporterId": null,
                "countReject": 490,
                "deletedAt": null,
                "createdAt": "2024-07-03T14:12:22.126Z",
                "updatedAt": "2024-07-09T06:35:37.695Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3616,
            "accountId": 554,
            "code": "895ID47",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-07T16:01:39.934Z",
            "updatedAt": "2024-07-07T16:15:31.337Z",
            "account": {
                "id": 554,
                "email": "nguyenhuulinh1704@gmail.com",
                "password": "$2a$08$sZK3qIy6Ynug88.0XXI2.OKJIaXs8kT1AFgkxhblccnTzVNdqQHEm",
                "role": "creator",
                "name": " Van Tien Minh TamNguyễn Hữu Linh",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "@linhnh9",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 22,
                "countJob": 17,
                "phoneNumber": "0988996985",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-03-25T14:01:45.517Z",
                "updatedAt": "2024-07-08T10:58:59.386Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3615,
            "accountId": 569,
            "code": "PITBB18",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-06T12:05:38.448Z",
            "updatedAt": "2024-07-06T12:10:16.807Z",
            "account": {
                "id": 569,
                "email": "dinhtuananh8398@gmail.com",
                "password": "$2a$08$j3PMMWS8HnwTmiBlW87L3uFkpIk1jOd8uKmjSaVmNMGEiiLrYhJXW",
                "role": "creator",
                "name": " Van Tien Minh TamĐinh Tuấn Anh",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "0977588513",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 180,
                "countJob": 168,
                "phoneNumber": "0977588513",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-04-07T17:11:37.848Z",
                "updatedAt": "2024-07-08T19:00:00.825Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3614,
            "accountId": 673,
            "code": "S5H4HP8",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-06T08:53:56.114Z",
            "updatedAt": "2024-07-06T08:59:47.351Z",
            "account": {
                "id": 673,
                "email": "khaclinh95vn@gmail.com",
                "password": "$2a$08$a0st25IsefA5oe2tA/g/VOOy5pOKf1p.6/iqNqzA/ScJ25vIVnN7C",
                "role": "creator",
                "name": " Van Tien Minh TamNguyễn Khắc Linh",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/Siroabe",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 47,
                "countJob": 35,
                "phoneNumber": "0967333663",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-18T02:09:16.606Z",
                "updatedAt": "2024-07-08T06:41:41.750Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3613,
            "accountId": 501,
            "code": "4OWL4H5",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 3000000,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-06T08:51:28.016Z",
            "updatedAt": "2024-07-06T08:59:01.955Z",
            "account": {
                "id": 501,
                "email": "support@printcold.com",
                "password": "$2a$08$ZVFcnJBisdlKp9RlaanSVursiLTKIAHNvdeJwAg0mPpAyBv2mOxkW",
                "role": "creator",
                "name": " Van Tien Minh TamPrintcold",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "+84939195145",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 415,
                "countJob": 391,
                "phoneNumber": "0387354421",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-02-16T02:03:53.513Z",
                "updatedAt": "2024-07-09T11:32:34.443Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3612,
            "accountId": 522,
            "code": "TP7NGOG",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 2000000,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-06T07:41:24.513Z",
            "updatedAt": "2024-07-06T07:43:54.993Z",
            "account": {
                "id": 522,
                "email": "tsll21081994@gmail.com",
                "password": "$2a$08$6ZeKp45y41Huoe4YBDQO.uP7.DNPqTbYfsR6Jyzt32rVA0kMPo4Ym",
                "role": "creator",
                "name": " Van Tien Minh TamNguyễn Minh Hiệp",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/anivia.218.leonguyen/",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 566,
                "countJob": 532,
                "phoneNumber": "0367152745",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-03-03T15:04:09.936Z",
                "updatedAt": "2024-07-09T08:50:22.525Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3611,
            "accountId": 687,
            "code": "9I2TZGX",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-06T04:57:05.740Z",
            "updatedAt": "2024-07-06T05:15:03.721Z",
            "account": {
                "id": 687,
                "email": "daotoanthang12345@gmail.com",
                "password": "$2a$08$ILNeBbcNqal2JuARGGgTmOy3e/90I9KKcFiTQkor1HwuGYQKu3iWi",
                "role": "creator",
                "name": " Van Tien Minh TamNguyễn Tân",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "BNT",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0967038118",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-06T04:52:20.180Z",
                "updatedAt": "2024-07-06T04:52:20.180Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3610,
            "accountId": 687,
            "code": "55NQXPV",
            "status": 3,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-06T04:52:45.718Z",
            "updatedAt": "2024-07-06T05:15:08.648Z",
            "account": {
                "id": 687,
                "email": "daotoanthang12345@gmail.com",
                "password": "$2a$08$ILNeBbcNqal2JuARGGgTmOy3e/90I9KKcFiTQkor1HwuGYQKu3iWi",
                "role": "creator",
                "name": " Van Tien Minh TamNguyễn Tân",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "BNT",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0967038118",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-06T04:52:20.180Z",
                "updatedAt": "2024-07-06T04:52:20.180Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3609,
            "accountId": 417,
            "code": "C0Z6S69",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-06T04:34:55.408Z",
            "updatedAt": "2024-07-06T04:47:28.192Z",
            "account": {
                "id": 417,
                "email": "dodung040998@gmail.com",
                "password": "$2a$08$B7hLCICLmh9IdMGjSEXB7efFZDfHuzvvbeNFXvMUzIxYkttfKM3XK",
                "role": "creator",
                "name": " Van Tien Minh TamĐỗ Đăng Quốc Dũng",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "Ry",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 13,
                "countJob": 13,
                "phoneNumber": "0925851280",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-12-09T12:46:11.416Z",
                "updatedAt": "2024-04-07T18:11:41.396Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3608,
            "accountId": 108,
            "code": "RXUJT6O",
            "status": 2,
            "accountApprove": 20,
            "type": 2,
            "price": 1797000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-07-06T04:30:26.547Z",
            "updatedAt": "2024-07-06T10:42:41.080Z",
            "account": {
                "id": 108,
                "email": "truonghonghanh871998@gmail.com",
                "password": "$2a$08$aSYoUGe4VncjxwWswc0AIuBAECKy8mXuqr9Y5LVn.A1/WiD12kXmy",
                "role": "designer",
                "name": " Van Tien Minh TamMaruko",
                "bankName": "VietinBank",
                "bankNumber": "108870112780",
                "accountBankName": "Trương Hồng Hạnh",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/honghanh",
                "salary": null,
                "status": 1,
                "rate": 4.914285714285715,
                "sampleNumber": 995,
                "countJob": 961,
                "phoneNumber": "0378930618",
                "isEmailVerified": true,
                "avatar": "static/20240708/2024070815359960.jpeg",
                "supporterId": null,
                "countReject": 167,
                "deletedAt": null,
                "createdAt": "2024-07-31T08:25:35.309Z",
                "updatedAt": "2024-07-09T09:44:54.466Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3607,
            "accountId": 635,
            "code": "M1GO10D",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 1000000,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-06T04:23:48.187Z",
            "updatedAt": "2024-07-06T04:25:54.137Z",
            "account": {
                "id": 635,
                "email": "cuongcv1190@gmail.com",
                "password": "$2a$08$9VQHSVD6FsWway4KyiFUGeQFjsa.J7YE5CvuPoP0fJeU6xeJZX8Om",
                "role": "creator",
                "name": " Van Tien Minh TamCao Văn Cường",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/cuong.caovan.10",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 149,
                "countJob": 132,
                "phoneNumber": "0359544690",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-05-07T04:53:43.849Z",
                "updatedAt": "2024-07-08T08:42:04.041Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3606,
            "accountId": 103,
            "code": "MYTB45T",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 1000000,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-06T04:05:06.532Z",
            "updatedAt": "2024-07-06T04:07:01.409Z",
            "account": {
                "id": 103,
                "email": "roxiepelkey72@gmail.com",
                "password": "$2a$08$Bq55lYJMRv5P8s3x14hRluPiT3Ksl0UE9oSzqeA3z9HJkWdfVgsfy",
                "role": "creator",
                "name": " Van Tien Minh TamNguyen Huu Hoan",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/TamasSzeles",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 257,
                "countJob": 247,
                "phoneNumber": "0395478955",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-29T07:05:32.393Z",
                "updatedAt": "2024-07-08T07:06:10.069Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3605,
            "accountId": 205,
            "code": "SB31IJT",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-06T03:07:16.354Z",
            "updatedAt": "2024-07-06T03:17:51.631Z",
            "account": {
                "id": 205,
                "email": "lomngnguyenvan911@gmail.com",
                "password": "$2a$08$PN9ipkWmJ2fZoN1J07WW1eRUAqDGTgU2sQH8KfFIyADAZqrqfoxsC",
                "role": "creator",
                "name": " Van Tien Minh TamBOSS",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "@supergreen1998",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 2363,
                "countJob": 2340,
                "phoneNumber": "0855295678",
                "isEmailVerified": true,
                "avatar": "static/20231029/2023102921316980.jpeg",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-09-04T15:11:05.772Z",
                "updatedAt": "2024-07-09T07:11:32.865Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3604,
            "accountId": 473,
            "code": "PLCCEF4",
            "status": 3,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-06T02:45:28.466Z",
            "updatedAt": "2024-07-06T02:54:56.787Z",
            "account": {
                "id": 473,
                "email": "tunglam77777777@gmail.com",
                "password": "$2a$08$3R1i2fjFTFGKtZT.jMePVO28mkw15Z0wm6B/RgT2lJg/J7Gi75rEu",
                "role": "creator",
                "name": " Van Tien Minh TamAnh Lâm ĐZ",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "@lam92dz",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 494,
                "countJob": 482,
                "phoneNumber": "0981018666",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-01-12T02:41:57.655Z",
                "updatedAt": "2024-07-09T09:53:45.547Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3603,
            "accountId": 473,
            "code": "9ME6KPJ",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-06T02:45:08.231Z",
            "updatedAt": "2024-07-06T02:54:35.946Z",
            "account": {
                "id": 473,
                "email": "tunglam77777777@gmail.com",
                "password": "$2a$08$3R1i2fjFTFGKtZT.jMePVO28mkw15Z0wm6B/RgT2lJg/J7Gi75rEu",
                "role": "creator",
                "name": " Van Tien Minh TamAnh Lâm ĐZ",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "@lam92dz",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 494,
                "countJob": 482,
                "phoneNumber": "0981018666",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-01-12T02:41:57.655Z",
                "updatedAt": "2024-07-09T09:53:45.547Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3602,
            "accountId": 560,
            "code": "K1CXN9L",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 2000000,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-06T02:26:16.147Z",
            "updatedAt": "2024-07-06T02:28:22.109Z",
            "account": {
                "id": 560,
                "email": "nguyenhp9x9876@gmail.com",
                "password": "$2a$08$QWAplK/07KkLINZNjMv.C.3GFIMiBQ5ccxwlQ.k2aE2PXgfw9W/g6",
                "role": "creator",
                "name": " Van Tien Minh TamAN HAI",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "nguyen",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 986,
                "countJob": 985,
                "phoneNumber": "0963902110",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-03-29T05:18:06.953Z",
                "updatedAt": "2024-07-08T19:00:01.358Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3601,
            "accountId": 541,
            "code": "A3LSYUS",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-06T01:58:20.678Z",
            "updatedAt": "2024-07-06T01:59:43.851Z",
            "account": {
                "id": 541,
                "email": "teampod.xyz@gmail.com",
                "password": "$2a$08$c.mmWajMF9kVQ1pemzJQteZfItsz0583uhQqt1PEQWnrcIdKMsiA.",
                "role": "creator",
                "name": " Van Tien Minh Tamtran van hung",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/cu.hung.982/",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 253,
                "countJob": 219,
                "phoneNumber": "0345740930",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-03-12T10:29:13.994Z",
                "updatedAt": "2024-07-09T07:10:20.284Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3600,
            "accountId": 267,
            "code": "T01ZY1O",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-06T00:12:44.359Z",
            "updatedAt": "2024-07-06T00:13:58.500Z",
            "account": {
                "id": 267,
                "email": "trhnngoc@gmail.com",
                "password": "$2a$08$/MVeSHNU9ruBnsNe9ZKVEusEK4fmZBw.cIIFZhB8yroWYIY6fjw9O",
                "role": "creator",
                "name": " Van Tien Minh TamTrần Ngọc",
                "bankName": "Techcombank",
                "bankNumber": "19037152739011",
                "accountBankName": "Tran Hong Ngoc",
                "idCard": null,
                "address": "Supporter: Nguyễn Thành",
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/trhnngoc.apr13/",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 173,
                "countJob": 170,
                "phoneNumber": "0766110966",
                "isEmailVerified": true,
                "avatar": "static/20231012/2023101217020080.jpeg",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-10-11T07:18:59.775Z",
                "updatedAt": "2024-07-08T19:00:01.114Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3599,
            "accountId": 370,
            "code": "44CTPXF",
            "status": 2,
            "accountApprove": 20,
            "type": 2,
            "price": 1044000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-07-06T00:10:50.261Z",
            "updatedAt": "2024-07-06T10:42:29.301Z",
            "account": {
                "id": 370,
                "email": "bhtnttngan2932002@gmail.com",
                "password": "$2a$08$ao26QTgUpP0rB4DplV8lVOqOaFKx8PHkeNDV9guQJ9iKtvPVaoTi6",
                "role": "designer",
                "name": " Van Tien Minh TamThu Ngân",
                "bankName": "TPB",
                "bankNumber": "0763186788",
                "accountBankName": "BAO HUYEN TON NU THI THU NGAN",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/louisangan",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 552,
                "countJob": 503,
                "phoneNumber": "0763186788",
                "isEmailVerified": true,
                "avatar": "static/20240708/2024070815345310.jpeg",
                "supporterId": null,
                "countReject": 208,
                "deletedAt": null,
                "createdAt": "2024-07-22T11:10:30.913Z",
                "updatedAt": "2024-07-09T08:24:40.246Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3598,
            "accountId": 521,
            "code": "2GTLRTA",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-05T23:29:48.930Z",
            "updatedAt": "2024-07-05T23:54:31.685Z",
            "account": {
                "id": 521,
                "email": "hongdiepvu97@gmail.com",
                "password": "$2a$08$o0qyzP90R41LjWGpphedjuh3F.xdv5ijthBIygFdh5SmYjuGtpbVi",
                "role": "creator",
                "name": " Van Tien Minh TamThắng",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "@alexnguyennnn",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 392,
                "countJob": 308,
                "phoneNumber": "0342525895",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-03-03T09:25:58.136Z",
                "updatedAt": "2024-07-08T22:52:36.727Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3597,
            "accountId": 643,
            "code": "H7GOT68",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-05T01:06:42.835Z",
            "updatedAt": "2024-07-05T01:23:59.076Z",
            "account": {
                "id": 643,
                "email": "testpod2001@gmail.com",
                "password": "$2a$08$D42Crl6bWuxl/R6vnXMrKOLuzPFouMQtKELJX4QCXjxW9keAvCgqi",
                "role": "creator",
                "name": " Van Tien Minh TamSinh Zien Ngheo",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/dang_luan_genz",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 70,
                "countJob": 69,
                "phoneNumber": "0366520675",
                "isEmailVerified": true,
                "avatar": "static/20240626/2024062611262670.png",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-05-15T01:38:07.068Z",
                "updatedAt": "2024-07-07T03:27:23.541Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3596,
            "accountId": 24,
            "code": "68XV3HL",
            "status": 2,
            "accountApprove": 20,
            "type": 2,
            "price": 1623000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-07-04T17:25:23.729Z",
            "updatedAt": "2024-07-06T10:42:17.442Z",
            "account": {
                "id": 24,
                "email": "giaannguyen211@gmail.com",
                "password": "$2a$08$p/EzaLOQY/w1gkI3hHRvW.OVkzFuuOpMYU.AOIfZTA0oUv1qOVPO2",
                "role": "designer",
                "name": " Van Tien Minh TamHồ Thị Hồng Nhiên",
                "bankName": "Techcombank",
                "bankNumber": "19034927381012",
                "accountBankName": "HO THI HONG NHIEN",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/hothihongnhien",
                "salary": null,
                "status": 1,
                "rate": 4.7593984962406015,
                "sampleNumber": 1483,
                "countJob": 1336,
                "phoneNumber": "0941772485",
                "isEmailVerified": true,
                "avatar": "static/20240708/2024070815331500.jpeg",
                "supporterId": null,
                "countReject": 318,
                "deletedAt": null,
                "createdAt": "2024-06-14T09:57:57.136Z",
                "updatedAt": "2024-07-09T07:00:20.673Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3595,
            "accountId": 661,
            "code": "4OAYF1M",
            "status": 2,
            "accountApprove": 20,
            "type": 2,
            "price": 1384000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-07-04T07:35:19.891Z",
            "updatedAt": "2024-07-06T10:42:10.337Z",
            "account": {
                "id": 661,
                "email": "nguyenthaolinh021101@gmail.com",
                "password": "$2a$08$eH65iYoYLsvjxkFnYyyKHuWTOl8mEt/aBH/fN1OocvBHqDWXyfgj.",
                "role": "designer",
                "name": " Van Tien Minh TamNguyễn Thị Thảo Linh",
                "bankName": "Techcombank",
                "bankNumber": "19033303060010",
                "accountBankName": "Nguyễn Thị Thảo Linh",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/linhisnotdead",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 133,
                "countJob": 125,
                "phoneNumber": "0836376212",
                "isEmailVerified": true,
                "avatar": "static/20240612/2024061209176120.jpeg",
                "supporterId": null,
                "countReject": 29,
                "deletedAt": null,
                "createdAt": "2024-06-06T01:22:44.394Z",
                "updatedAt": "2024-07-09T08:06:54.079Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3594,
            "accountId": 430,
            "code": "B0VDGOK",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 1000000,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-04T07:26:41.227Z",
            "updatedAt": "2024-07-04T07:27:32.954Z",
            "account": {
                "id": 430,
                "email": "nguyenphuochpvn222@gmail.com",
                "password": "$2a$08$O0UOif3Qo29H5xYaQ9BGR.sbMETYlunqzrRxuxfK8.NxHUOSvUita",
                "role": "creator",
                "name": " Van Tien Minh Tamphuoc nguyen",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "Felixnguyen995",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 425,
                "countJob": 424,
                "phoneNumber": "0964271333",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-12-15T01:51:33.608Z",
                "updatedAt": "2024-07-09T07:59:23.077Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3593,
            "accountId": 640,
            "code": "JCT7FJA",
            "status": 3,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-04T07:19:18.875Z",
            "updatedAt": "2024-07-04T07:20:29.586Z",
            "account": {
                "id": 640,
                "email": "keohot3@gmail.com",
                "password": "$2a$08$7fSDqXQwarfxatn6ALjp0.Mq1beBnAX.444f49Eku4de0XXefxBuC",
                "role": "creator",
                "name": " Van Tien Minh TamThiệu",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "0342280028",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 233,
                "countJob": 233,
                "phoneNumber": "0342280028",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-05-13T07:08:04.891Z",
                "updatedAt": "2024-07-09T11:05:42.569Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3592,
            "accountId": 640,
            "code": "RQGKBHM",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-04T07:18:08.677Z",
            "updatedAt": "2024-07-04T07:20:26.576Z",
            "account": {
                "id": 640,
                "email": "keohot3@gmail.com",
                "password": "$2a$08$7fSDqXQwarfxatn6ALjp0.Mq1beBnAX.444f49Eku4de0XXefxBuC",
                "role": "creator",
                "name": " Van Tien Minh TamThiệu",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "0342280028",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 233,
                "countJob": 233,
                "phoneNumber": "0342280028",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-05-13T07:08:04.891Z",
                "updatedAt": "2024-07-09T11:05:42.569Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3591,
            "accountId": 421,
            "code": "H60RTAK",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 550000,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-04T04:40:50.853Z",
            "updatedAt": "2024-07-04T04:51:43.091Z",
            "account": {
                "id": 421,
                "email": "chien04091991@gmail.com",
                "password": "$2a$08$RIvf0si6AxAdbWY1.RUab.lxJdZxnVQ7e8GxkMdvYeUWB.z.yJ.B2",
                "role": "creator",
                "name": " Van Tien Minh TamCông Chiến",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": "thuylinhhoa120698@gmail.com",
                "note": null,
                "linkFb": null,
                "linkTelegram": "@congchien91",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 110,
                "countJob": 103,
                "phoneNumber": "0983189223",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-12-13T13:26:59.497Z",
                "updatedAt": "2024-07-07T19:00:01.608Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3590,
            "accountId": 444,
            "code": "08JOSFM",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-04T04:27:09.360Z",
            "updatedAt": "2024-07-04T04:52:22.434Z",
            "account": {
                "id": 444,
                "email": "trongk57cbls@gmail.com",
                "password": "$2a$08$B.u/zx3L7q3XyK.bULhEBeS5nU7GCKLMn21c1hsfKosYgreDkKdT6",
                "role": "creator",
                "name": " Van Tien Minh TamNguyen Van Trong",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/nguyenvantrong231/",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 410,
                "countJob": 409,
                "phoneNumber": "0969259231",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-12-21T04:13:39.893Z",
                "updatedAt": "2024-07-09T09:40:43.097Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3589,
            "accountId": 421,
            "code": "DP2582A",
            "status": 3,
            "accountApprove": 343,
            "type": 1,
            "price": 5500000,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-04T04:07:46.100Z",
            "updatedAt": "2024-07-04T04:51:19.282Z",
            "account": {
                "id": 421,
                "email": "chien04091991@gmail.com",
                "password": "$2a$08$RIvf0si6AxAdbWY1.RUab.lxJdZxnVQ7e8GxkMdvYeUWB.z.yJ.B2",
                "role": "creator",
                "name": " Van Tien Minh TamCông Chiến",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": "thuylinhhoa120698@gmail.com",
                "note": null,
                "linkFb": null,
                "linkTelegram": "@congchien91",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 110,
                "countJob": 103,
                "phoneNumber": "0983189223",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-12-13T13:26:59.497Z",
                "updatedAt": "2024-07-07T19:00:01.608Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3588,
            "accountId": 353,
            "code": "AJYRGKD",
            "status": 2,
            "accountApprove": 20,
            "type": 2,
            "price": 781000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-07-04T03:12:17.826Z",
            "updatedAt": "2024-07-06T10:42:01.378Z",
            "account": {
                "id": 353,
                "email": "vothanhly95@gmail.com",
                "password": "$2a$08$0bsBsT.q6NyczhFpoMzC4u1Ir3cW.nGuw/iO4.IFfidKXEh7UckPW",
                "role": "designer",
                "name": " Van Tien Minh TamVõ Thành Lý",
                "bankName": "Vietcombank",
                "bankNumber": "0811000037302",
                "accountBankName": "VO THANH LY",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/Shaddoll",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 1897,
                "countJob": 1806,
                "phoneNumber": "0869940208",
                "isEmailVerified": true,
                "avatar": "static/20240708/2024070815523610.jpeg",
                "supporterId": null,
                "countReject": 391,
                "deletedAt": null,
                "createdAt": "2024-07-15T09:15:32.563Z",
                "updatedAt": "2024-07-09T01:13:01.022Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3587,
            "accountId": 524,
            "code": "879TGZ0",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-04T00:27:16.729Z",
            "updatedAt": "2024-07-04T00:46:19.599Z",
            "account": {
                "id": 524,
                "email": "hungchau168@gmail.com",
                "password": "$2a$08$SBtYnLC1oBBRGnVO3vu5uupsZiMn30n3fW8PLSsdkM6OZBV.Cn/o.",
                "role": "creator",
                "name": " Van Tien Minh TamChâu Hùng",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "0938555228",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 46,
                "countJob": 45,
                "phoneNumber": "0938555228",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-03-04T05:19:31.465Z",
                "updatedAt": "2024-07-06T08:37:41.915Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3586,
            "accountId": 516,
            "code": "ESJVNVW",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-03T15:28:46.986Z",
            "updatedAt": "2024-07-03T15:38:28.767Z",
            "account": {
                "id": 516,
                "email": "truongthanhthuong.work@gmail.com",
                "password": "$2a$08$y8xW5isuxQbO5rOzQVt/yO7uPRkqNhfgbK/yLogSxpoaY.GqkNI6u",
                "role": "creator",
                "name": " Van Tien Minh TamThanh Thuong",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "Thanh Thuong",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 60,
                "countJob": 60,
                "phoneNumber": "0342379925",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-02-26T06:07:40.319Z",
                "updatedAt": "2024-07-08T08:07:03.010Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3585,
            "accountId": 32,
            "code": "TYT9CBO",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-03T14:59:21.287Z",
            "updatedAt": "2024-07-03T15:06:22.749Z",
            "account": {
                "id": 32,
                "email": "tuttn@msn.com",
                "password": "$2a$08$ENlCaYiYdQ9ALcDS2TkgH.NNRrzIK6ImVs7/T6jdHjju5l1eK5tMe",
                "role": "creator",
                "name": " Van Tien Minh Tamjohn",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/tuttn3191/",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 628,
                "countJob": 624,
                "phoneNumber": "0999999999",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-19T08:20:39.927Z",
                "updatedAt": "2024-07-07T15:37:25.858Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3584,
            "accountId": 302,
            "code": "UC1DN1F",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-03T13:49:02.917Z",
            "updatedAt": "2024-07-03T13:52:53.890Z",
            "account": {
                "id": 302,
                "email": "hieucntt5a@gmail.com",
                "password": "$2a$08$NWldCtCKIFd/ehmEBTRSquSRxCxRHvtYtl.Lh9SC82FNPLenLNgxu",
                "role": "creator",
                "name": " Van Tien Minh TamTrương Minh Hiếu",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": "thuylinhhoa120698@gmail.com",
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/cucaylop",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 425,
                "countJob": 425,
                "phoneNumber": "0989457447",
                "isEmailVerified": true,
                "avatar": "static/20231031/2023103113298530.jpeg",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-10-31T03:49:17.163Z",
                "updatedAt": "2024-07-09T09:11:59.073Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3583,
            "accountId": 348,
            "code": "IDJ4AIY",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 7502000,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-03T12:57:55.061Z",
            "updatedAt": "2024-07-03T12:58:53.134Z",
            "account": {
                "id": 348,
                "email": "trieuquocdat91@gmail.com",
                "password": "$2a$08$7MucHr.JIYjbboft0nmbwOO/118igERkOelp5LmrkiZLBkTg4vPx.",
                "role": "creator",
                "name": " Van Tien Minh TamTrieu Quoc Dat",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "Triệu Quốc Đạt",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 8887,
                "countJob": 8653,
                "phoneNumber": "0369595679",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-14T10:00:27.323Z",
                "updatedAt": "2024-07-09T09:10:51.537Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3582,
            "accountId": 349,
            "code": "XPDAE3W",
            "status": 2,
            "accountApprove": 20,
            "type": 2,
            "price": 1384000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-07-03T11:21:42.352Z",
            "updatedAt": "2024-07-03T22:37:24.147Z",
            "account": {
                "id": 349,
                "email": "quynhkk209@gmail.com",
                "password": "$2a$08$fCYOkBVToo5c8nmfxXd5q.M6JQ6VNDxoyrOEnfLQWYF8H7NxNzsDS",
                "role": "designer",
                "name": " Van Tien Minh TamLê Xuân Quỳnh",
                "bankName": "ICB",
                "bankNumber": "0344555565",
                "accountBankName": "LE XUAN QUYNH",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/datduong95",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 1585,
                "countJob": 1507,
                "phoneNumber": "0986584565",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 578,
                "deletedAt": null,
                "createdAt": "2024-07-15T01:34:31.493Z",
                "updatedAt": "2024-07-09T07:03:38.879Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3581,
            "accountId": 673,
            "code": "#9485361",
            "status": 2,
            "accountApprove": 343,
            "type": 5,
            "price": -500000,
            "description": "Hoàn tiền cho mã phiếu 58QR2PZ",
            "deletedAt": null,
            "createdAt": "2024-07-03T07:08:57.034Z",
            "updatedAt": "2024-07-03T07:08:57.034Z",
            "account": {
                "id": 673,
                "email": "khaclinh95vn@gmail.com",
                "password": "$2a$08$a0st25IsefA5oe2tA/g/VOOy5pOKf1p.6/iqNqzA/ScJ25vIVnN7C",
                "role": "creator",
                "name": " Van Tien Minh TamNguyễn Khắc Linh",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/Siroabe",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 47,
                "countJob": 35,
                "phoneNumber": "0967333663",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-18T02:09:16.606Z",
                "updatedAt": "2024-07-08T06:41:41.750Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3580,
            "accountId": 41,
            "code": "IBTH8WJ",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 1000000,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-03T07:07:12.950Z",
            "updatedAt": "2024-07-03T07:09:56.648Z",
            "account": {
                "id": 41,
                "email": "tuanbinh922008@gmail.com",
                "password": "$2a$08$mAG6cZz8S0lolnAe60xsQeAqVULCS8EXr/XaoivvGukXzRVOQkvO2",
                "role": "creator",
                "name": " Van Tien Minh TamDương Tuấn Bình",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "fb.com/tuanbinh92",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 1596,
                "countJob": 1222,
                "phoneNumber": "0985405686",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-29T02:02:33.186Z",
                "updatedAt": "2024-07-09T07:00:20.675Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3579,
            "accountId": 673,
            "code": "I4MENR3",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-03T06:51:02.487Z",
            "updatedAt": "2024-07-03T06:58:20.633Z",
            "account": {
                "id": 673,
                "email": "khaclinh95vn@gmail.com",
                "password": "$2a$08$a0st25IsefA5oe2tA/g/VOOy5pOKf1p.6/iqNqzA/ScJ25vIVnN7C",
                "role": "creator",
                "name": " Van Tien Minh TamNguyễn Khắc Linh",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/Siroabe",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 47,
                "countJob": 35,
                "phoneNumber": "0967333663",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-18T02:09:16.606Z",
                "updatedAt": "2024-07-08T06:41:41.750Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3578,
            "accountId": 673,
            "code": "58QR2PZ",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-03T06:48:37.180Z",
            "updatedAt": "2024-07-03T06:58:08.022Z",
            "account": {
                "id": 673,
                "email": "khaclinh95vn@gmail.com",
                "password": "$2a$08$a0st25IsefA5oe2tA/g/VOOy5pOKf1p.6/iqNqzA/ScJ25vIVnN7C",
                "role": "creator",
                "name": " Van Tien Minh TamNguyễn Khắc Linh",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/Siroabe",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 47,
                "countJob": 35,
                "phoneNumber": "0967333663",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-18T02:09:16.606Z",
                "updatedAt": "2024-07-08T06:41:41.750Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3577,
            "accountId": 205,
            "code": "K9U42W6",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-03T06:44:40.331Z",
            "updatedAt": "2024-07-03T06:49:37.193Z",
            "account": {
                "id": 205,
                "email": "lomngnguyenvan911@gmail.com",
                "password": "$2a$08$PN9ipkWmJ2fZoN1J07WW1eRUAqDGTgU2sQH8KfFIyADAZqrqfoxsC",
                "role": "creator",
                "name": " Van Tien Minh TamBOSS",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "@supergreen1998",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 2363,
                "countJob": 2340,
                "phoneNumber": "0855295678",
                "isEmailVerified": true,
                "avatar": "static/20231029/2023102921316980.jpeg",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-09-04T15:11:05.772Z",
                "updatedAt": "2024-07-09T07:11:32.865Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3576,
            "accountId": 529,
            "code": "LTJ2GJA",
            "status": 2,
            "accountApprove": 20,
            "type": 2,
            "price": 7020000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-07-03T05:01:27.926Z",
            "updatedAt": "2024-07-03T22:37:07.898Z",
            "account": {
                "id": 529,
                "email": "danganh1991hd1389@gmail.com",
                "password": "$2a$08$wnLqGMFoMz7xo0TAF8z.XeCXlieaKOWHpGugxAqYr0ayHHt5mYrQ6",
                "role": "designer",
                "name": " Van Tien Minh TamĐặng Việt Anh",
                "bankName": "Techcombank",
                "bankNumber": "19036136614016",
                "accountBankName": "DANG VIET ANH",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/LihAnhQuan",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 4749,
                "countJob": 4426,
                "phoneNumber": "0899272668",
                "isEmailVerified": true,
                "avatar": "static/20240309/2024030915553700.jpeg",
                "supporterId": null,
                "countReject": 568,
                "deletedAt": null,
                "createdAt": "2024-03-05T08:17:16.806Z",
                "updatedAt": "2024-07-09T12:26:31.803Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3575,
            "accountId": 376,
            "code": "U63HBH3",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-03T04:04:27.103Z",
            "updatedAt": "2024-07-03T04:11:37.078Z",
            "account": {
                "id": 376,
                "email": "nhatductranhuynh2311@gmail.com",
                "password": "$2a$08$sGrNwoLEBeOYPNKLr9s1pOhcvE8KmwKCn5ohLu1rhg.l48jHlDydO",
                "role": "creator",
                "name": " Van Tien Minh TamTrần Huỳnh Nhật Đức",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "123456pH@",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 646,
                "countJob": 645,
                "phoneNumber": "0378455488",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-27T09:17:21.022Z",
                "updatedAt": "2024-07-09T04:37:48.674Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3574,
            "accountId": 247,
            "code": "FSSIOPM",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-03T03:59:08.518Z",
            "updatedAt": "2024-07-03T04:11:23.902Z",
            "account": {
                "id": 247,
                "email": "selochom0212@gmail.com",
                "password": "$2a$08$pDdPRPj/6vHtE/nvSctPHu1Nl3Ur5yTcY/UyxSX5ZrICowtWa.GZm",
                "role": "creator",
                "name": " Van Tien Minh TamTrần Nguyên",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/profile.php?id=100067483304010",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 348,
                "countJob": 308,
                "phoneNumber": "0868888064",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-09-27T14:30:15.052Z",
                "updatedAt": "2024-07-07T19:00:01.147Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3573,
            "accountId": 508,
            "code": "K5INK9S",
            "status": 2,
            "accountApprove": 20,
            "type": 2,
            "price": 1178000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-07-03T03:49:28.414Z",
            "updatedAt": "2024-07-03T22:36:46.028Z",
            "account": {
                "id": 508,
                "email": "khongthanhnam123456@gmail.com",
                "password": "$2a$08$jJ.A01rJdK3M4.bfQEFdPOD0OMmPjw0rsfHM.NQZCQSYWBfLCKkI6",
                "role": "designer",
                "name": " Van Tien Minh TamKhổng Nam",
                "bankName": "Vietcombank",
                "bankNumber": "1021421304",
                "accountBankName": "KHONG THANH NAM",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/Nam Khổng",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 655,
                "countJob": 629,
                "phoneNumber": "0988495625",
                "isEmailVerified": true,
                "avatar": "static/20240609/2024060907491710.jpeg",
                "supporterId": null,
                "countReject": 185,
                "deletedAt": null,
                "createdAt": "2024-02-22T03:41:59.083Z",
                "updatedAt": "2024-07-09T08:42:08.143Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3572,
            "accountId": 489,
            "code": "ISFJCO3",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 2000000,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-03T02:50:44.010Z",
            "updatedAt": "2024-07-03T03:00:17.684Z",
            "account": {
                "id": 489,
                "email": "vodienshopping@gmail.com",
                "password": "$2a$08$A61PqwKoR/Ckx8t6n6pp3u7wsUPG2nx2YaJCE3x20RddC7qzqUiam",
                "role": "creator",
                "name": " Van Tien Minh TamThao",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/thao.hare.5/",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 628,
                "countJob": 625,
                "phoneNumber": "0387754597",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-01-27T09:18:52.306Z",
                "updatedAt": "2024-07-09T04:28:47.705Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3571,
            "accountId": 480,
            "code": "P308TTI",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-03T02:34:31.406Z",
            "updatedAt": "2024-07-03T02:59:32.565Z",
            "account": {
                "id": 480,
                "email": "dolphin.jack.fw@gmail.com",
                "password": "$2a$08$o913hBJSW6P4kmjRD4ONT.uYle2GR/2M7KkCPsXuPHI598ubOURWW",
                "role": "creator",
                "name": " Van Tien Minh TamJack",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "@jackd17",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 288,
                "countJob": 279,
                "phoneNumber": "0985725205",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-01-17T04:13:37.041Z",
                "updatedAt": "2024-07-09T07:23:30.573Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3570,
            "accountId": 267,
            "code": "F2GTGOM",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-03T00:15:58.912Z",
            "updatedAt": "2024-07-03T01:01:49.121Z",
            "account": {
                "id": 267,
                "email": "trhnngoc@gmail.com",
                "password": "$2a$08$/MVeSHNU9ruBnsNe9ZKVEusEK4fmZBw.cIIFZhB8yroWYIY6fjw9O",
                "role": "creator",
                "name": " Van Tien Minh TamTrần Ngọc",
                "bankName": "Techcombank",
                "bankNumber": "19037152739011",
                "accountBankName": "Tran Hong Ngoc",
                "idCard": null,
                "address": "Supporter: Nguyễn Thành",
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/trhnngoc.apr13/",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 173,
                "countJob": 170,
                "phoneNumber": "0766110966",
                "isEmailVerified": true,
                "avatar": "static/20231012/2023101217020080.jpeg",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-10-11T07:18:59.775Z",
                "updatedAt": "2024-07-08T19:00:01.114Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3569,
            "accountId": 623,
            "code": "6L8VDF1",
            "status": 2,
            "accountApprove": 20,
            "type": 2,
            "price": 369000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-07-02T23:05:05.327Z",
            "updatedAt": "2024-07-02T23:06:38.927Z",
            "account": {
                "id": 623,
                "email": "linhlinhdsg@gmail.com",
                "password": "$2a$08$.rZD9gdaF/sMi04hW15nAOIUELwrsWFtFq.PfcXFm9USRcSxsOZIu",
                "role": "designer",
                "name": " Van Tien Minh TamDiệu Linh",
                "bankName": "MBBank",
                "bankNumber": "98225336868",
                "accountBankName": "Lê Thị Diệu Linh",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/linhle",
                "salary": null,
                "status": 0,
                "rate": 0,
                "sampleNumber": 72,
                "countJob": 72,
                "phoneNumber": "0386919396",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 43,
                "deletedAt": null,
                "createdAt": "2024-05-03T04:23:19.076Z",
                "updatedAt": "2024-07-02T23:05:14.053Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3568,
            "accountId": 128,
            "code": "4CM3JN6",
            "status": 2,
            "accountApprove": 20,
            "type": 2,
            "price": 369000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-07-02T23:03:52.844Z",
            "updatedAt": "2024-07-02T23:06:25.753Z",
            "account": {
                "id": 128,
                "email": "lttvinh2403@gmail.com",
                "password": "$2a$08$WOGzxpNsWz1gJnrAffFwzeNG5WYWfckCV6xrtb11cTAStpP4M3lq.",
                "role": "designer",
                "name": " Van Tien Minh Tamlttvynh",
                "bankName": "Timo",
                "bankNumber": "0708087931",
                "accountBankName": "Le Thi Thuy Vinh",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/tvinh24",
                "salary": null,
                "status": 0,
                "rate": 4.833333333333333,
                "sampleNumber": 1907,
                "countJob": 1755,
                "phoneNumber": "0708087931",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 707,
                "deletedAt": null,
                "createdAt": "2024-08-04T13:43:04.536Z",
                "updatedAt": "2024-07-02T23:03:59.692Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3567,
            "accountId": 510,
            "code": "V5KZ87Z",
            "status": 2,
            "accountApprove": 20,
            "type": 2,
            "price": 323000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-07-02T23:02:59.611Z",
            "updatedAt": "2024-07-02T23:06:09.964Z",
            "account": {
                "id": 510,
                "email": "hongbinh26072000@gmail.com",
                "password": "$2a$08$GR9liu3GNsDLJkADZOBP.Ov9VYrMlrzU7SowG4UqzJ/5W.ofy1sNS",
                "role": "designer",
                "name": " Van Tien Minh TamNguyễn Hồng Bình",
                "bankName": "VCB",
                "bankNumber": "0071001293834",
                "accountBankName": "Nguyen Hong Binh",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/https://t.me/binhnguyen2607",
                "salary": null,
                "status": 0,
                "rate": 0,
                "sampleNumber": 1010,
                "countJob": 963,
                "phoneNumber": "0901323059",
                "isEmailVerified": true,
                "avatar": "static/20240308/2024030809309180.jpeg",
                "supporterId": null,
                "countReject": 295,
                "deletedAt": null,
                "createdAt": "2024-02-22T11:22:39.028Z",
                "updatedAt": "2024-07-02T23:03:07.415Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3566,
            "accountId": 90,
            "code": "SIB93L4",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 1000000,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-02T07:25:41.671Z",
            "updatedAt": "2024-07-02T07:26:49.322Z",
            "account": {
                "id": 90,
                "email": "jennychinhf2@gmail.com",
                "password": "$2a$08$if9sAULojVNXgvzndFxV6.Dyda8awdAm4Rh770Ygc8s.OS6L6EJla",
                "role": "creator",
                "name": " Van Tien Minh TamTony Nguyen",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/tony.kenz.752/",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 1545,
                "countJob": 1359,
                "phoneNumber": "0336096650",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-27T02:17:56.376Z",
                "updatedAt": "2024-07-05T06:29:07.803Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3565,
            "accountId": 664,
            "code": "7IT7ZKM",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 1000000,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-02T04:17:56.194Z",
            "updatedAt": "2024-07-02T05:06:17.959Z",
            "account": {
                "id": 664,
                "email": "hcinfi.tong@gmail.com",
                "password": "$2a$08$THhDNy0ENiBtej8bq1/usek5F0YS4EJjTEG9MVmkV6/S5lTMYBiI6",
                "role": "creator",
                "name": " Van Tien Minh TamMoney Gang",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/monkeypg",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 135,
                "countJob": 135,
                "phoneNumber": "0963202320",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-08T09:01:52.638Z",
                "updatedAt": "2024-07-08T19:00:01.220Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3564,
            "accountId": 473,
            "code": "2JQMOVY",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-02T03:22:05.440Z",
            "updatedAt": "2024-07-02T03:23:15.205Z",
            "account": {
                "id": 473,
                "email": "tunglam77777777@gmail.com",
                "password": "$2a$08$3R1i2fjFTFGKtZT.jMePVO28mkw15Z0wm6B/RgT2lJg/J7Gi75rEu",
                "role": "creator",
                "name": " Van Tien Minh TamAnh Lâm ĐZ",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "@lam92dz",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 494,
                "countJob": 482,
                "phoneNumber": "0981018666",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-01-12T02:41:57.655Z",
                "updatedAt": "2024-07-09T09:53:45.547Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3563,
            "accountId": 644,
            "code": "PEY8WWC",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-02T00:05:21.060Z",
            "updatedAt": "2024-07-02T00:07:22.272Z",
            "account": {
                "id": 644,
                "email": "thucptit93@gmail.com",
                "password": "$2a$08$P7y4l4sa8dzNGDBv.qab8utqR18M2enf0r7lEgeYKHlEi3R3H7qKa",
                "role": "creator",
                "name": " Van Tien Minh TamNgô Quang Thức",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "Quang Thuc Ngo",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 75,
                "countJob": 66,
                "phoneNumber": "0868296269",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-05-15T01:54:13.260Z",
                "updatedAt": "2024-07-05T19:00:02.720Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3562,
            "accountId": 521,
            "code": "4B45SYE",
            "status": 2,
            "accountApprove": 20,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T22:49:43.998Z",
            "updatedAt": "2024-07-01T22:49:58.660Z",
            "account": {
                "id": 521,
                "email": "hongdiepvu97@gmail.com",
                "password": "$2a$08$o0qyzP90R41LjWGpphedjuh3F.xdv5ijthBIygFdh5SmYjuGtpbVi",
                "role": "creator",
                "name": " Van Tien Minh TamThắng",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "@alexnguyennnn",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 392,
                "countJob": 308,
                "phoneNumber": "0342525895",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-03-03T09:25:58.136Z",
                "updatedAt": "2024-07-08T22:52:36.727Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3561,
            "accountId": 556,
            "code": "D5QRHHX",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T09:12:41.263Z",
            "updatedAt": "2024-07-01T09:15:38.937Z",
            "account": {
                "id": 556,
                "email": "nguyenquocbaotkt@gmail.com",
                "password": "$2a$08$1N57FS0Bn68WiXnem/XY/ulpjUe1WgUtU.tBkgh8aL/2YVsfzpP4S",
                "role": "creator",
                "name": " Van Tien Minh Tamgg",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/bimbimsoda/",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 356,
                "countJob": 356,
                "phoneNumber": "0966075593",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-03-26T08:46:52.068Z",
                "updatedAt": "2024-07-05T01:15:56.752Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3560,
            "accountId": 624,
            "code": "V9O53JT",
            "status": 2,
            "accountApprove": 20,
            "type": 2,
            "price": 2332000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T08:59:59.497Z",
            "updatedAt": "2024-07-01T10:21:01.118Z",
            "account": {
                "id": 624,
                "email": "lethithuhang104@gmail.com",
                "password": "$2a$08$3yrV8odQ4.NeVWrA2THp.e3oWyDwjXsmoxCx/tMr7S7h5L.Cab/Vi",
                "role": "designer",
                "name": " Van Tien Minh TamTHU HẰNG",
                "bankName": "BIDV",
                "bankNumber": "8880174148",
                "accountBankName": "Lê Thị Thu Hằng",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/My My",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 602,
                "countJob": 574,
                "phoneNumber": "0944350363",
                "isEmailVerified": true,
                "avatar": "static/20240507/2024050713386560.jpeg",
                "supporterId": null,
                "countReject": 276,
                "deletedAt": null,
                "createdAt": "2024-05-03T04:53:17.281Z",
                "updatedAt": "2024-07-09T11:32:34.442Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3559,
            "accountId": 171,
            "code": "IAASDAA",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T08:08:16.658Z",
            "updatedAt": "2024-07-01T08:11:32.720Z",
            "account": {
                "id": 171,
                "email": "vizero.4003@gmail.com",
                "password": "$2a$08$DuM0zVnPOviH2OwOLFMTxOj.9XXdLcazCGMKufWy8o22NGJAp0MdO",
                "role": "creator",
                "name": " Van Tien Minh Tamvizero",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/profile.php?id=100022129513174",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 1218,
                "countJob": 910,
                "phoneNumber": "0000000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-08-18T04:09:04.292Z",
                "updatedAt": "2024-07-05T19:00:01.152Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3558,
            "accountId": 253,
            "code": "3AXE967",
            "status": 2,
            "accountApprove": 20,
            "type": 2,
            "price": 2447000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T08:01:24.119Z",
            "updatedAt": "2024-07-01T10:20:41.712Z",
            "account": {
                "id": 253,
                "email": "duongthianhtuyet21798@gmail.com",
                "password": "$2a$08$AZA06lOXZNtagvpSn47IhumBvZ.prtSZyrT2ixR9z9.r0gswH/HiS",
                "role": "designer",
                "name": " Van Tien Minh TamTuyết Dương",
                "bankName": "MBBank",
                "bankNumber": "0347273311",
                "accountBankName": "Dương Thị Ánh Tuyết",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/Tuyết Dương",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 2991,
                "countJob": 2867,
                "phoneNumber": "0347273311",
                "isEmailVerified": true,
                "avatar": "static/20231115/2023111518181640.jpeg",
                "supporterId": null,
                "countReject": 869,
                "deletedAt": null,
                "createdAt": "2024-10-02T08:02:01.410Z",
                "updatedAt": "2024-07-09T07:20:54.909Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3557,
            "accountId": 560,
            "code": "FSHL53F",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 2000000,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T08:00:53.740Z",
            "updatedAt": "2024-07-01T08:03:29.622Z",
            "account": {
                "id": 560,
                "email": "nguyenhp9x9876@gmail.com",
                "password": "$2a$08$QWAplK/07KkLINZNjMv.C.3GFIMiBQ5ccxwlQ.k2aE2PXgfw9W/g6",
                "role": "creator",
                "name": " Van Tien Minh TamAN HAI",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "nguyen",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 986,
                "countJob": 985,
                "phoneNumber": "0963902110",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-03-29T05:18:06.953Z",
                "updatedAt": "2024-07-08T19:00:01.358Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3556,
            "accountId": 90,
            "code": "CAMWYDD",
            "status": 3,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T07:54:20.774Z",
            "updatedAt": "2024-07-01T08:03:12.057Z",
            "account": {
                "id": 90,
                "email": "jennychinhf2@gmail.com",
                "password": "$2a$08$if9sAULojVNXgvzndFxV6.Dyda8awdAm4Rh770Ygc8s.OS6L6EJla",
                "role": "creator",
                "name": " Van Tien Minh TamTony Nguyen",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/tony.kenz.752/",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 1545,
                "countJob": 1359,
                "phoneNumber": "0336096650",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-27T02:17:56.376Z",
                "updatedAt": "2024-07-05T06:29:07.803Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3555,
            "accountId": 90,
            "code": "Y7A4OL6",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T07:53:20.956Z",
            "updatedAt": "2024-07-01T07:54:18.954Z",
            "account": {
                "id": 90,
                "email": "jennychinhf2@gmail.com",
                "password": "$2a$08$if9sAULojVNXgvzndFxV6.Dyda8awdAm4Rh770Ygc8s.OS6L6EJla",
                "role": "creator",
                "name": " Van Tien Minh TamTony Nguyen",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/tony.kenz.752/",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 1545,
                "countJob": 1359,
                "phoneNumber": "0336096650",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-27T02:17:56.376Z",
                "updatedAt": "2024-07-05T06:29:07.803Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3554,
            "accountId": 662,
            "code": "XJH48UZ",
            "status": 2,
            "accountApprove": 20,
            "type": 2,
            "price": 719000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T07:18:33.128Z",
            "updatedAt": "2024-07-01T10:20:20.692Z",
            "account": {
                "id": 662,
                "email": "mtngoc2407@gmail.com",
                "password": "$2a$08$Co3ZiZLDKGmiPVMx4WgOOO7WLkLI5ri6iIGdUptAW8vQ5W34KxWku",
                "role": "designer",
                "name": " Van Tien Minh TamMai Tiểu Ngọc",
                "bankName": "VietinBank",
                "bankNumber": "103871646321",
                "accountBankName": "MAI TIEU NGOC",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/tieungocmai247",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 80,
                "countJob": 79,
                "phoneNumber": "0778889101",
                "isEmailVerified": true,
                "avatar": "static/20240708/2024070816378010.jpeg",
                "supporterId": null,
                "countReject": 28,
                "deletedAt": null,
                "createdAt": "2024-06-06T15:37:10.714Z",
                "updatedAt": "2024-07-08T23:54:20.789Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3553,
            "accountId": 522,
            "code": "A04EDQF",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 2000000,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T07:14:44.070Z",
            "updatedAt": "2024-07-01T07:18:08.988Z",
            "account": {
                "id": 522,
                "email": "tsll21081994@gmail.com",
                "password": "$2a$08$6ZeKp45y41Huoe4YBDQO.uP7.DNPqTbYfsR6Jyzt32rVA0kMPo4Ym",
                "role": "creator",
                "name": " Van Tien Minh TamNguyễn Minh Hiệp",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/anivia.218.leonguyen/",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 566,
                "countJob": 532,
                "phoneNumber": "0367152745",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-03-03T15:04:09.936Z",
                "updatedAt": "2024-07-09T08:50:22.525Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3552,
            "accountId": 90,
            "code": "2T54QB0",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T06:29:33.853Z",
            "updatedAt": "2024-07-01T06:32:57.069Z",
            "account": {
                "id": 90,
                "email": "jennychinhf2@gmail.com",
                "password": "$2a$08$if9sAULojVNXgvzndFxV6.Dyda8awdAm4Rh770Ygc8s.OS6L6EJla",
                "role": "creator",
                "name": " Van Tien Minh TamTony Nguyen",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/tony.kenz.752/",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 1545,
                "countJob": 1359,
                "phoneNumber": "0336096650",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-27T02:17:56.376Z",
                "updatedAt": "2024-07-05T06:29:07.803Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3551,
            "accountId": 215,
            "code": "K5MUJRT",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T04:50:34.131Z",
            "updatedAt": "2024-07-01T05:14:08.092Z",
            "account": {
                "id": 215,
                "email": "bigprooooo@gmail.com",
                "password": "$2a$08$HBuyv2s9IMOoVKAgq4OCA.aqkfmXgVhx2SljL7i93RcBuD8eM5Mda",
                "role": "creator",
                "name": " Van Tien Minh TamPham An",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/an.pham.92167789",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 957,
                "countJob": 945,
                "phoneNumber": "0978299507",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-09-11T01:59:22.681Z",
                "updatedAt": "2024-07-06T19:00:01.832Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3550,
            "accountId": 640,
            "code": "7K6Y2J2",
            "status": 3,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T04:40:15.095Z",
            "updatedAt": "2024-07-01T05:11:25.751Z",
            "account": {
                "id": 640,
                "email": "keohot3@gmail.com",
                "password": "$2a$08$7fSDqXQwarfxatn6ALjp0.Mq1beBnAX.444f49Eku4de0XXefxBuC",
                "role": "creator",
                "name": " Van Tien Minh TamThiệu",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "0342280028",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 233,
                "countJob": 233,
                "phoneNumber": "0342280028",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-05-13T07:08:04.891Z",
                "updatedAt": "2024-07-09T11:05:42.569Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3549,
            "accountId": 640,
            "code": "14UNURC",
            "status": 3,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T04:24:47.746Z",
            "updatedAt": "2024-07-01T05:11:19.871Z",
            "account": {
                "id": 640,
                "email": "keohot3@gmail.com",
                "password": "$2a$08$7fSDqXQwarfxatn6ALjp0.Mq1beBnAX.444f49Eku4de0XXefxBuC",
                "role": "creator",
                "name": " Van Tien Minh TamThiệu",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "0342280028",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 233,
                "countJob": 233,
                "phoneNumber": "0342280028",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-05-13T07:08:04.891Z",
                "updatedAt": "2024-07-09T11:05:42.569Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3548,
            "accountId": 640,
            "code": "6H0I76G",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T04:19:42.796Z",
            "updatedAt": "2024-07-01T05:11:02.491Z",
            "account": {
                "id": 640,
                "email": "keohot3@gmail.com",
                "password": "$2a$08$7fSDqXQwarfxatn6ALjp0.Mq1beBnAX.444f49Eku4de0XXefxBuC",
                "role": "creator",
                "name": " Van Tien Minh TamThiệu",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "0342280028",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 233,
                "countJob": 233,
                "phoneNumber": "0342280028",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-05-13T07:08:04.891Z",
                "updatedAt": "2024-07-09T11:05:42.569Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3547,
            "accountId": 640,
            "code": "IAFMJR0",
            "status": 3,
            "accountApprove": 343,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T04:17:55.221Z",
            "updatedAt": "2024-07-01T05:11:07.280Z",
            "account": {
                "id": 640,
                "email": "keohot3@gmail.com",
                "password": "$2a$08$7fSDqXQwarfxatn6ALjp0.Mq1beBnAX.444f49Eku4de0XXefxBuC",
                "role": "creator",
                "name": " Van Tien Minh TamThiệu",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "0342280028",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 233,
                "countJob": 233,
                "phoneNumber": "0342280028",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-05-13T07:08:04.891Z",
                "updatedAt": "2024-07-09T11:05:42.569Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3546,
            "accountId": 205,
            "code": "J4AF35V",
            "status": 2,
            "accountApprove": 20,
            "type": 1,
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T03:32:58.819Z",
            "updatedAt": "2024-07-01T03:35:03.416Z",
            "account": {
                "id": 205,
                "email": "lomngnguyenvan911@gmail.com",
                "password": "$2a$08$PN9ipkWmJ2fZoN1J07WW1eRUAqDGTgU2sQH8KfFIyADAZqrqfoxsC",
                "role": "creator",
                "name": " Van Tien Minh TamBOSS",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "@supergreen1998",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 2363,
                "countJob": 2340,
                "phoneNumber": "0855295678",
                "isEmailVerified": true,
                "avatar": "static/20231029/2023102921316980.jpeg",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-09-04T15:11:05.772Z",
                "updatedAt": "2024-07-09T07:11:32.865Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3545,
            "accountId": 570,
            "code": "CLIA63M",
            "status": 2,
            "accountApprove": 20,
            "type": 2,
            "price": 5504000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T02:08:39.716Z",
            "updatedAt": "2024-07-01T03:06:46.159Z",
            "account": {
                "id": 570,
                "email": "vumanhhieulssl@gmail.com",
                "password": "$2a$08$3bHUlW9kT99DHzRi1LqpU.dNaoshvF6VUJblxq.LCfGyH12Oee5pa",
                "role": "designer",
                "name": " Van Tien Minh TamVũ Mạnh Hiếu",
                "bankName": "MB",
                "bankNumber": "2228811082000",
                "accountBankName": "VU MANH HIEU",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/vuhieuu",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 924,
                "countJob": 876,
                "phoneNumber": "0397721932",
                "isEmailVerified": true,
                "avatar": "static/20240409/2024040916180350.jpeg",
                "supporterId": null,
                "countReject": 182,
                "deletedAt": null,
                "createdAt": "2024-04-09T03:43:36.208Z",
                "updatedAt": "2024-07-09T08:41:31.908Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        },
        {
            "id": 3544,
            "accountId": 100,
            "code": "5JD4EW1",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "price": 1500000,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T01:12:23.833Z",
            "updatedAt": "2024-07-01T01:21:57.626Z",
            "account": {
                "id": 100,
                "email": "nguyenquyen24112@gmail.com",
                "password": "$2a$08$0e7sZb08f/oHakE44PNRc.SXIkIUiS/Arl0ChZk2JE7Dyc6opnMD2",
                "role": "creator",
                "name": " Van Tien Minh TamNguyen Nguyen",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/nguyennguyends",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 494,
                "countJob": 389,
                "phoneNumber": "0705210693",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-28T07:31:25.001Z",
                "updatedAt": "2024-07-08T19:00:00.378Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
        {
            "id": 3543,
            "accountId": 315,
            "code": "93KWYLW",
            "status": 2,
            "accountApprove": 20,
            "type": 2,
            "price": 3645000,
            "description": "Rút tiền",
            "deletedAt": null,
            "createdAt": "2024-06-30T19:11:45.603Z",
            "updatedAt": "2024-07-01T03:06:28.469Z",
            "account": {
                "id": 315,
                "email": "khanhsuper1310.kns@gmail.com",
                "password": "$2a$08$qUGuOC2xbFVkVpxG1bXvAO.3WNmm.iO2pKdDaUoF7gIKg6wjInx3S",
                "role": "designer",
                "name": " Van Tien Minh TamNguyễn Khánh",
                "bankName": "Vietcombank",
                "bankNumber": "0071001128874",
                "accountBankName": "NGUYEN THI KHANH TRANG",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/https://t.me/khanhnguyen0212",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 2843,
                "countJob": 2631,
                "phoneNumber": "0903803405",
                "isEmailVerified": true,
                "avatar": "static/20240510/2024051000107840.jpeg",
                "supporterId": null,
                "countReject": 1083,
                "deletedAt": null,
                "createdAt": "2024-07-05T09:18:21.043Z",
                "updatedAt": "2024-07-09T08:40:52.705Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 20,
                "email": "admin@gmail.com",
                "password": "$2a$08$TxygxJ5uW2eVAU4Jc242beg725BG5KaMlENiRPzwSh5S7Ba12efIS",
                "role": "admin",
                "name": " Van Tien Minh Tamadmin",
                "bankName": "bankName",
                "bankNumber": null,
                "accountBankName": "accountBankName",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "linkTelegram",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "00000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-06-13T15:48:53.076Z",
                "updatedAt": "2024-06-13T15:48:53.076Z",
                "SupporterId": null
            }
        }
    ]
}

export const priceFundFake={
    "before": 93353700,
    "collect": 89626000,
    "spend": 9030000,
    "after": 2383000
}
export const warehouseListFake={
    "limit": "100",
    "offset": "0",
    "count": 334,
    "rows": [
          
        {
            "id": 3559,
            "accountId": 171,
            "code": "IAASDAA",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "name":'Kho chính',
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T08:08:16.658Z",
            "updatedAt": "2024-07-01T08:11:32.720Z",
            "account": {
                "id": 171,
                "email": "vizero.4003@gmail.com",
                "password": "$2a$08$DuM0zVnPOviH2OwOLFMTxOj.9XXdLcazCGMKufWy8o22NGJAp0MdO",
                "role": "creator",
                "name": " Van Tien Minh Tamvizero",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/profile.php?id=100022129513174",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 1218,
                "countJob": 910,
                "phoneNumber": "0000000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-08-18T04:09:04.292Z",
                "updatedAt": "2024-07-05T19:00:01.152Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },        
        {
            "id": 3559,
            "accountId": 171,
            "code": "IAASDAA",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "name":'Giang Văn Minh',
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T08:08:16.658Z",
            "updatedAt": "2024-07-01T08:11:32.720Z",
            "account": {
                "id": 171,
                "email": "vizero.4003@gmail.com",
                "password": "$2a$08$DuM0zVnPOviH2OwOLFMTxOj.9XXdLcazCGMKufWy8o22NGJAp0MdO",
                "role": "creator",
                "name": " Van Tien Minh Tamvizero",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/profile.php?id=100022129513174",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 1218,
                "countJob": 910,
                "phoneNumber": "0000000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-08-18T04:09:04.292Z",
                "updatedAt": "2024-07-05T19:00:01.152Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
                
        {
            "id": 3559,
            "accountId": 171,
            "code": "IAASDAA",
            "status": 2,
            "accountApprove": 343,
            "type": 1,
            "name":'Hoàng Hà Tổng kho miền bắc',
            "price": 750200,
            "description": "Nạp tiền",
            "deletedAt": null,
            "createdAt": "2024-07-01T08:08:16.658Z",
            "updatedAt": "2024-07-01T08:11:32.720Z",
            "account": {
                "id": 171,
                "email": "vizero.4003@gmail.com",
                "password": "$2a$08$DuM0zVnPOviH2OwOLFMTxOj.9XXdLcazCGMKufWy8o22NGJAp0MdO",
                "role": "creator",
                "name": " Van Tien Minh Tamvizero",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/profile.php?id=100022129513174",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 1218,
                "countJob": 910,
                "phoneNumber": "0000000000",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-08-18T04:09:04.292Z",
                "updatedAt": "2024-07-05T19:00:01.152Z",
                "SupporterId": null
            },
            "accountApproveInfo": {
                "id": 343,
                "email": "vinhsongtin@gmail.com",
                "password": "$2a$08$0/j1fq22NzNDxi1w29ibk.hrUK0UCrvWjiaenfD/J2g22FzVF7EuK",
                "role": "accountant",
                "name": " Van Tien Minh TamKế toán 01",
                "bankName": "TCB",
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": null,
                "salary": 1000000,
                "status": 1,
                "rate": 0,
                "sampleNumber": 0,
                "countJob": 0,
                "phoneNumber": "0912345966",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-07-12T14:38:41.079Z",
                "updatedAt": "2024-07-12T14:38:41.079Z",
                "SupporterId": null
            }
        },
       
    ]
}
export const priceWarehouseFake={
    "before": 93353700,
    "collect": 89626000,
    "spend": 9030000,
}
export const taglistFake= [
    {
        "id": 6,
        "name": " Van Tien Minh TamT-Shirt 2D",
        "countJob": 0,
        "index": 0,
        "deletedAt": null,
        "createdAt": "2024-06-13T15:55:13.312Z",
        "updatedAt": "2024-06-13T15:55:13.312Z"
    },
    {
        "id": 21,
        "name": " Van Tien Minh TamT-Shirt 3D",
        "countJob": 0,
        "index": 1,
        "deletedAt": null,
        "createdAt": "2024-09-08T03:43:48.287Z",
        "updatedAt": "2024-07-24T06:38:02.339Z"
    },
    {
        "id": 7,
        "name": " Van Tien Minh TamMug",
        "countJob": 0,
        "index": 3,
        "deletedAt": null,
        "createdAt": "2024-06-13T15:55:16.975Z",
        "updatedAt": "2024-06-13T15:56:37.355Z"
    },
    {
        "id": 9,
        "name": " Van Tien Minh TamOrnament",
        "countJob": 0,
        "index": 5,
        "deletedAt": null,
        "createdAt": "2024-06-13T15:55:24.919Z",
        "updatedAt": "2024-06-13T15:57:23.932Z"
    },
    {
        "id": 10,
        "name": " Van Tien Minh TamPoster",
        "countJob": 0,
        "index": 6,
        "deletedAt": null,
        "createdAt": "2024-06-13T15:55:30.579Z",
        "updatedAt": "2024-06-13T15:57:49.403Z"
    },
    {
        "id": 23,
        "name": " Van Tien Minh TamVintage Bootleg",
        "countJob": 0,
        "index": 7,
        "deletedAt": null,
        "createdAt": "2024-07-07T02:25:59.603Z",
        "updatedAt": "2024-07-24T06:34:15.651Z"
    },
    {
        "id": 11,
        "name": " Van Tien Minh TamCanvas",
        "countJob": 0,
        "index": 7,
        "deletedAt": null,
        "createdAt": "2024-06-13T15:55:34.573Z",
        "updatedAt": "2024-06-13T15:57:54.770Z"
    },
    {
        "id": 24,
        "name": " Van Tien Minh TamCrocs",
        "countJob": 0,
        "index": 8,
        "deletedAt": null,
        "createdAt": "2024-07-24T06:33:50.538Z",
        "updatedAt": "2024-07-24T06:34:22.952Z"
    },
    {
        "id": 12,
        "name": " Van Tien Minh TamTumbler",
        "countJob": 0,
        "index": 8,
        "deletedAt": null,
        "createdAt": "2024-06-13T15:55:39.158Z",
        "updatedAt": "2024-06-13T15:58:02.112Z"
    },
    {
        "id": 25,
        "name": " Van Tien Minh Tam3D Sweater",
        "countJob": 0,
        "index": 9,
        "deletedAt": null,
        "createdAt": "2024-07-24T06:37:37.600Z",
        "updatedAt": "2024-07-24T06:37:52.094Z"
    },
    {
        "id": 17,
        "name": " Van Tien Minh TamBlanket",
        "countJob": 0,
        "index": 13,
        "deletedAt": null,
        "createdAt": "2024-06-17T02:21:39.648Z",
        "updatedAt": "2024-06-17T02:21:51.104Z"
    },
    {
        "id": 13,
        "name": " Van Tien Minh TamQuilt",
        "countJob": 0,
        "index": 13,
        "deletedAt": null,
        "createdAt": "2024-06-13T15:55:46.234Z",
        "updatedAt": "2024-06-13T15:56:58.379Z"
    },
    {
        "id": 19,
        "name": " Van Tien Minh TamFlag",
        "countJob": 0,
        "index": 19,
        "deletedAt": null,
        "createdAt": "2024-06-23T06:25:32.027Z",
        "updatedAt": "2024-07-14T14:32:03.533Z"
    },
    {
        "id": 22,
        "name": " Van Tien Minh TamOther",
        "countJob": 0,
        "index": 22,
        "deletedAt": null,
        "createdAt": "2024-07-04T07:42:14.276Z",
        "updatedAt": "2024-07-04T07:42:23.148Z"
    }
]

export const jobsFake= {
    "limit": "100",
    "offset": "0",
    "count": 10,
    "rows": [
        {
            "images": [
                {
                    "path": "static/20240709/2024070922413010.jpeg",
                    "description": "Ảnh 1"
                },
                {
                    "path": "static/20240709/2024070922413120.jpeg",
                    "description": "Ảnh 2"
                }
            ],
            "id": 63685,
            "count": 1,
            "creatorId": 526,
            "designerId": null,
            "productTypeId": 6,
            "designTypeId": 2,
            "price": 27000,
            "actuallyReceived": 23000,
            "totalPrice": 27000,
            "totalActuallyReceived": 23000,
            "tag": "",
            "url": "",
            "name": "#2800692",
            "note": "Lấy des 4500x5100, des in lên áo sáng và áo tối",
            "countReject": 0,
            "status": 0,
            "startedAt": null,
            "expiresAt": null,
            "deletedAt": null,
            "isPriority": false,
            "createdAt": "2024-07-09T15:41:53.622Z",
            "updatedAt": "2024-07-09T15:41:53.622Z",
            "productType": {
                "id": 6,
                "name": "T-Shirt 2D",
                "countJob": 0,
                "index": 0,
                "deletedAt": null,
                "createdAt": "2023-06-13T15:55:13.312Z",
                "updatedAt": "2023-06-13T15:55:13.312Z"
            },
            "designType": {
                "id": 2,
                "name": "Clone",
                "countJob": 0,
                "index": 0,
                "deletedAt": null,
                "createdAt": "2023-06-13T15:54:53.563Z",
                "updatedAt": "2023-06-13T15:54:53.563Z"
            },
            "creator": {
                "id": 526,
                "email": "tuananh080697@gmail.com",
                "password": "$2a$08$D8gAMaPAxT/CCXFaswg/RuNtlN1y9UJ4WsJuSA3C5RZ1RXQlKZ/1i",
                "role": "creator",
                "name": "Nguyễn Tuấn Anh",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/tuananhcl97/",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 330,
                "countJob": 284,
                "phoneNumber": "0363991672",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-03-04T09:45:43.437Z",
                "updatedAt": "2024-07-07T19:00:00.639Z",
                "SupporterId": null
            },
            "designer": null
        },
        {
            "images": [
                {
                    "path": "static/20240709/2024070922407800.png",
                    "description": "Ảnh 1"
                }
            ],
            "id": 63684,
            "count": 2,
            "creatorId": 100,
            "designerId": 67,
            "productTypeId": 6,
            "designTypeId": 2,
            "price": 27000,
            "actuallyReceived": 23000,
            "totalPrice": 54000,
            "totalActuallyReceived": 46000,
            "tag": "1730818030",
            "url": "",
            "name": "#4968804",
            "note": "design 2 bản để in lên đc cả áo trắng và áo đen",
            "countReject": 0,
            "status": 1,
            "startedAt": "2024-07-09T15:46:12.834Z",
            "expiresAt": "2024-07-09T19:46:12.834Z",
            "deletedAt": null,
            "isPriority": false,
            "createdAt": "2024-07-09T15:40:26.906Z",
            "updatedAt": "2024-07-09T15:46:12.834Z",
            "productType": {
                "id": 6,
                "name": "T-Shirt 2D",
                "countJob": 0,
                "index": 0,
                "deletedAt": null,
                "createdAt": "2023-06-13T15:55:13.312Z",
                "updatedAt": "2023-06-13T15:55:13.312Z"
            },
            "designType": {
                "id": 2,
                "name": "Clone",
                "countJob": 0,
                "index": 0,
                "deletedAt": null,
                "createdAt": "2023-06-13T15:54:53.563Z",
                "updatedAt": "2023-06-13T15:54:53.563Z"
            },
            "creator": {
                "id": 100,
                "email": "nguyenquyen24112@gmail.com",
                "password": "$2a$08$0e7sZb08f/oHakE44PNRc.SXIkIUiS/Arl0ChZk2JE7Dyc6opnMD2",
                "role": "creator",
                "name": "Nguyen Nguyen",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/nguyennguyends",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 495,
                "countJob": 390,
                "phoneNumber": "0705210693",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2023-07-28T07:31:25.001Z",
                "updatedAt": "2024-07-09T15:32:13.621Z",
                "SupporterId": null
            },
            "designer": {
                "id": 67,
                "email": "binhdi003@gmail.com",
                "password": "$2a$08$Y.wfsqlQkF0EqCVMt66ppOKztdhH5hvAVdOelSbA1pJ0va8F0BYsO",
                "role": "designer",
                "name": "Lâm Thanh Phong",
                "bankName": "Vietcombank",
                "bankNumber": "1015493334",
                "accountBankName": "Lâm Thanh Phong",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/lamlaicuonsongmoi",
                "salary": null,
                "status": 1,
                "rate": 4.854838709677419,
                "sampleNumber": 6364,
                "countJob": 5923,
                "phoneNumber": "0379307826",
                "isEmailVerified": true,
                "avatar": "static/20231029/2023102923029810.jpeg",
                "supporterId": null,
                "countReject": 1675,
                "deletedAt": null,
                "createdAt": "2023-07-15T04:19:54.725Z",
                "updatedAt": "2024-07-09T12:16:23.701Z",
                "SupporterId": null
            }
        },
        {
            "images": [
                {
                    "path": "static/20240709/2024070922213230.png",
                    "description": "Ảnh 1"
                }
            ],
            "id": 63683,
            "count": 2,
            "creatorId": 100,
            "designerId": 659,
            "productTypeId": 6,
            "designTypeId": 2,
            "price": 27000,
            "actuallyReceived": 23000,
            "totalPrice": 54000,
            "totalActuallyReceived": 46000,
            "tag": "jaj1731982488",
            "url": "",
            "name": "#8881932",
            "note": "design 2 bản để in lên đc cả áo trắng và áo đen",
            "countReject": 0,
            "status": 1,
            "startedAt": "2024-07-09T15:23:29.557Z",
            "expiresAt": "2024-07-09T19:23:29.557Z",
            "deletedAt": null,
            "isPriority": false,
            "createdAt": "2024-07-09T15:21:35.223Z",
            "updatedAt": "2024-07-09T15:23:29.557Z",
            "productType": {
                "id": 6,
                "name": "T-Shirt 2D",
                "countJob": 0,
                "index": 0,
                "deletedAt": null,
                "createdAt": "2023-06-13T15:55:13.312Z",
                "updatedAt": "2023-06-13T15:55:13.312Z"
            },
            "designType": {
                "id": 2,
                "name": "Clone",
                "countJob": 0,
                "index": 0,
                "deletedAt": null,
                "createdAt": "2023-06-13T15:54:53.563Z",
                "updatedAt": "2023-06-13T15:54:53.563Z"
            },
            "creator": {
                "id": 100,
                "email": "nguyenquyen24112@gmail.com",
                "password": "$2a$08$0e7sZb08f/oHakE44PNRc.SXIkIUiS/Arl0ChZk2JE7Dyc6opnMD2",
                "role": "creator",
                "name": "Nguyen Nguyen",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/nguyennguyends",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 495,
                "countJob": 390,
                "phoneNumber": "0705210693",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2023-07-28T07:31:25.001Z",
                "updatedAt": "2024-07-09T15:32:13.621Z",
                "SupporterId": null
            },
            "designer": {
                "id": 659,
                "email": "dmthanh1502@gmail.com",
                "password": "$2a$08$zZbe3VcbFuwi4T5JpuNZpOenvpXRUTi2rzPtLnvGSYXy7W3Koy.bm",
                "role": "designer",
                "name": "Đào Minh Thành",
                "bankName": "TCB",
                "bankNumber": "1915021997",
                "accountBankName": "Đào Minh Thành",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/dmthanh1502",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 191,
                "countJob": 180,
                "phoneNumber": "0762600541",
                "isEmailVerified": true,
                "avatar": "static/20240708/2024070815347880.jpeg",
                "supporterId": null,
                "countReject": 33,
                "deletedAt": null,
                "createdAt": "2024-06-05T04:22:33.384Z",
                "updatedAt": "2024-07-09T14:25:29.199Z",
                "SupporterId": null
            }
        },
        {
            "images": [
                {
                    "path": "static/20240709/2024070921183630.png",
                    "description": "mẫu gốc"
                },
                {
                    "path": "static/20240709/2024070921195430.png",
                    "description": "làm cái font chữ fall chấm trắng và màu sắc như này"
                }
            ],
            "id": 63682,
            "count": 1,
            "creatorId": 501,
            "designerId": null,
            "productTypeId": 6,
            "designTypeId": 4,
            "price": 37000,
            "actuallyReceived": 33000,
            "totalPrice": 37000,
            "totalActuallyReceived": 33000,
            "tag": "",
            "url": "",
            "name": "#8777905",
            "note": "- File PSD sửa chi tiết \n- File PNG 4800*5400 300DPI\n\nrds chủ đề fall. mẫu của Lý nên có font chư săn rôi á\n\ndesigner nhận :  Lý",
            "countReject": 0,
            "status": 0,
            "startedAt": null,
            "expiresAt": null,
            "deletedAt": null,
            "isPriority": false,
            "createdAt": "2024-07-09T14:19:46.790Z",
            "updatedAt": "2024-07-09T14:19:46.790Z",
            "productType": {
                "id": 6,
                "name": "T-Shirt 2D",
                "countJob": 0,
                "index": 0,
                "deletedAt": null,
                "createdAt": "2023-06-13T15:55:13.312Z",
                "updatedAt": "2023-06-13T15:55:13.312Z"
            },
            "designType": {
                "id": 4,
                "name": "Redesign",
                "countJob": 0,
                "index": 2,
                "deletedAt": null,
                "createdAt": "2023-08-17T05:20:05.925Z",
                "updatedAt": "2023-08-17T05:20:12.437Z"
            },
            "creator": {
                "id": 501,
                "email": "support@printcold.com",
                "password": "$2a$08$ZVFcnJBisdlKp9RlaanSVursiLTKIAHNvdeJwAg0mPpAyBv2mOxkW",
                "role": "creator",
                "name": "Printcold",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "+84939195145",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 415,
                "countJob": 391,
                "phoneNumber": "0387354421",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-02-16T02:03:53.513Z",
                "updatedAt": "2024-07-09T11:32:34.443Z",
                "SupporterId": null
            },
            "designer": null
        },
        {
            "images": [
                {
                    "path": "static/20240709/2024070919562610.png",
                    "description": "Ảnh 1"
                }
            ],
            "id": 63674,
            "count": 1,
            "creatorId": 268,
            "designerId": null,
            "productTypeId": 13,
            "designTypeId": 2,
            "price": 80000,
            "actuallyReceived": 64000,
            "totalPrice": 80000,
            "totalActuallyReceived": 64000,
            "tag": "",
            "url": "",
            "name": "#7374731",
            "note": "9000x10000",
            "countReject": 0,
            "status": 0,
            "startedAt": null,
            "expiresAt": null,
            "deletedAt": null,
            "isPriority": false,
            "createdAt": "2024-07-09T12:56:19.884Z",
            "updatedAt": "2024-07-09T13:27:55.822Z",
            "productType": {
                "id": 13,
                "name": "Quilt",
                "countJob": 0,
                "index": 13,
                "deletedAt": null,
                "createdAt": "2023-06-13T15:55:46.234Z",
                "updatedAt": "2023-06-13T15:56:58.379Z"
            },
            "designType": {
                "id": 2,
                "name": "Clone",
                "countJob": 0,
                "index": 0,
                "deletedAt": null,
                "createdAt": "2023-06-13T15:54:53.563Z",
                "updatedAt": "2023-06-13T15:54:53.563Z"
            },
            "creator": {
                "id": 268,
                "email": "maitron175@gmail.com",
                "password": "$2a$08$5fBce2NdqBLo0PyQM7TXlerI57VSQHjcK413jxQsXy/NER9oauBWW",
                "role": "creator",
                "name": "Kun kizo",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": "Supporter: Nguyễn Thành",
                "note": null,
                "linkFb": null,
                "linkTelegram": "Mai Lê",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 126,
                "countJob": 125,
                "phoneNumber": "0936177489",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2023-10-11T12:31:33.831Z",
                "updatedAt": "2024-07-09T12:26:33.923Z",
                "SupporterId": null
            },
            "designer": null
        },
        {
            "images": [
                {
                    "path": "static/20240709/2024070919167780.png",
                    "description": "Ảnh 1"
                }
            ],
            "id": 63672,
            "count": 1,
            "creatorId": 448,
            "designerId": 574,
            "productTypeId": 23,
            "designTypeId": 2,
            "price": 35000,
            "actuallyReceived": 30000,
            "totalPrice": 35000,
            "totalActuallyReceived": 30000,
            "tag": "",
            "url": "https://drive.google.com/file/d/1IgYokgFvsQBMpwNQtt-cDsg4sfs6B_nU/view?usp=sharing",
            "name": "#5501725",
            "note": "",
            "countReject": 1,
            "status": 3,
            "startedAt": "2024-07-09T12:22:39.104Z",
            "expiresAt": "2024-07-09T14:22:39.104Z",
            "deletedAt": null,
            "isPriority": false,
            "createdAt": "2024-07-09T12:16:38.438Z",
            "updatedAt": "2024-07-09T15:36:57.912Z",
            "productType": {
                "id": 23,
                "name": "Vintage Bootleg",
                "countJob": 0,
                "index": 7,
                "deletedAt": null,
                "createdAt": "2023-11-07T02:25:59.603Z",
                "updatedAt": "2023-11-24T06:34:15.651Z"
            },
            "designType": {
                "id": 2,
                "name": "Clone",
                "countJob": 0,
                "index": 0,
                "deletedAt": null,
                "createdAt": "2023-06-13T15:54:53.563Z",
                "updatedAt": "2023-06-13T15:54:53.563Z"
            },
            "creator": {
                "id": 448,
                "email": "namnguyenhp.280895@gmail.com",
                "password": "$2a$08$dEPog7yCSSqr8GIYmm.sa.F9OfN5Q6lwDRWCv5OcIAntfVFvE1ayK",
                "role": "creator",
                "name": "Nam Nguyễn",
                "bankName": "Vietcombank",
                "bankNumber": null,
                "accountBankName": "Nguyễn Hoàng Nam",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "Hoàng Nam",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 47,
                "countJob": 46,
                "phoneNumber": "0378090161",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2023-12-28T03:10:16.926Z",
                "updatedAt": "2024-07-09T12:16:23.702Z",
                "SupporterId": null
            },
            "designer": {
                "id": 574,
                "email": "luongtam1391989@gmail.com",
                "password": "$2a$08$7csd1ZP/2jYQUXTZmtQKje8LNL7oqt45NJnar2A8JYsaxCiO5FoVa",
                "role": "designer",
                "name": "Lương Ngọc Tâm",
                "bankName": "Techcombank",
                "bankNumber": "19039192766014",
                "accountBankName": "Lương Ngọc Tâm",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/https://t.me/luongtam13",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 932,
                "countJob": 898,
                "phoneNumber": "0904308864",
                "isEmailVerified": true,
                "avatar": "static/20240702/2024070214021760.png",
                "supporterId": null,
                "countReject": 216,
                "deletedAt": null,
                "createdAt": "2024-04-10T02:08:19.560Z",
                "updatedAt": "2024-07-09T09:40:43.096Z",
                "SupporterId": null
            }
        },
        {
            "images": [
                {
                    "path": "static/20240709/2024070913569390.png",
                    "description": "lấy vector của cái nơ này. design lại cái đuôi n thành bút chì như 2 ảnh dưới"
                },
                {
                    "path": "static/20240709/2024070913567880.png",
                    "description": "mẫu gốc làm như này."
                },
                {
                    "path": "static/20240709/2024070913576850.png",
                    "description": "Ảnh 4"
                }
            ],
            "id": 63634,
            "count": 1,
            "creatorId": 501,
            "designerId": 315,
            "productTypeId": 6,
            "designTypeId": 4,
            "price": 40000,
            "actuallyReceived": 36000,
            "totalPrice": 40000,
            "totalActuallyReceived": 36000,
            "tag": "",
            "url": "https://drive.google.com/drive/folders/1MdnWuAReFhwwDT5aaX8lF8aqee6fAWUi?usp=drive_link",
            "name": "#7452993",
            "note": "- File PSD sửa chi tiết \n- File PNG 4800*5400 300DPI (2png)\n- Làm mockup như ảnh 2\n\nrds lại cái nơ họa tiết màu sắc giữ nguyên",
            "countReject": 1,
            "status": 3,
            "startedAt": "2024-07-09T09:00:02.994Z",
            "expiresAt": "2024-07-09T11:00:02.994Z",
            "deletedAt": null,
            "isPriority": false,
            "createdAt": "2024-07-09T06:59:41.695Z",
            "updatedAt": "2024-07-09T11:35:20.591Z",
            "productType": {
                "id": 6,
                "name": "T-Shirt 2D",
                "countJob": 0,
                "index": 0,
                "deletedAt": null,
                "createdAt": "2023-06-13T15:55:13.312Z",
                "updatedAt": "2023-06-13T15:55:13.312Z"
            },
            "designType": {
                "id": 4,
                "name": "Redesign",
                "countJob": 0,
                "index": 2,
                "deletedAt": null,
                "createdAt": "2023-08-17T05:20:05.925Z",
                "updatedAt": "2023-08-17T05:20:12.437Z"
            },
            "creator": {
                "id": 501,
                "email": "support@printcold.com",
                "password": "$2a$08$ZVFcnJBisdlKp9RlaanSVursiLTKIAHNvdeJwAg0mPpAyBv2mOxkW",
                "role": "creator",
                "name": "Printcold",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "+84939195145",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 415,
                "countJob": 391,
                "phoneNumber": "0387354421",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-02-16T02:03:53.513Z",
                "updatedAt": "2024-07-09T11:32:34.443Z",
                "SupporterId": null
            },
            "designer": {
                "id": 315,
                "email": "khanhsuper1310.kns@gmail.com",
                "password": "$2a$08$qUGuOC2xbFVkVpxG1bXvAO.3WNmm.iO2pKdDaUoF7gIKg6wjInx3S",
                "role": "designer",
                "name": "Nguyễn Khánh",
                "bankName": "Vietcombank",
                "bankNumber": "0071001128874",
                "accountBankName": "NGUYEN THI KHANH TRANG",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/https://t.me/khanhnguyen0212",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 2843,
                "countJob": 2631,
                "phoneNumber": "0903803405",
                "isEmailVerified": true,
                "avatar": "static/20240510/2024051000107840.jpeg",
                "supporterId": null,
                "countReject": 1083,
                "deletedAt": null,
                "createdAt": "2023-11-05T09:18:21.043Z",
                "updatedAt": "2024-07-09T08:40:52.705Z",
                "SupporterId": null
            }
        },
        {
            "images": [
                {
                    "path": "static/20240708/2024070819552110.png",
                    "description": "Ảnh 1"
                }
            ],
            "id": 63536,
            "count": 1,
            "creatorId": 501,
            "designerId": 315,
            "productTypeId": 6,
            "designTypeId": 4,
            "price": 57000,
            "actuallyReceived": 51000,
            "totalPrice": 57000,
            "totalActuallyReceived": 51000,
            "tag": "",
            "url": "https://drive.google.com/drive/folders/1su266brY8ua42_UPAbiPBAREuIP688Z0?usp=drive_link",
            "name": "#3476214",
            "note": "- File PSD sửa chi tiết \n- File PNG 4800*5400 300DPI (3PNG)\nMOCKUP TỰA DỊ Ó\n\n- rds . PENCIL HALLOWEEN. BẠN KHÁNH LÀM CHO MÌNH CÁI NƠ NÓ ĐẸP DẠY Á NHAAA. \n\ndesigner KHÁNH nhận",
            "countReject": 1,
            "status": 3,
            "startedAt": "2024-07-09T06:38:21.268Z",
            "expiresAt": "2024-07-09T08:38:21.268Z",
            "deletedAt": null,
            "isPriority": false,
            "createdAt": "2024-07-08T12:56:47.094Z",
            "updatedAt": "2024-07-09T09:54:39.299Z",
            "productType": {
                "id": 6,
                "name": "T-Shirt 2D",
                "countJob": 0,
                "index": 0,
                "deletedAt": null,
                "createdAt": "2023-06-13T15:55:13.312Z",
                "updatedAt": "2023-06-13T15:55:13.312Z"
            },
            "designType": {
                "id": 4,
                "name": "Redesign",
                "countJob": 0,
                "index": 2,
                "deletedAt": null,
                "createdAt": "2023-08-17T05:20:05.925Z",
                "updatedAt": "2023-08-17T05:20:12.437Z"
            },
            "creator": {
                "id": 501,
                "email": "support@printcold.com",
                "password": "$2a$08$ZVFcnJBisdlKp9RlaanSVursiLTKIAHNvdeJwAg0mPpAyBv2mOxkW",
                "role": "creator",
                "name": "Printcold",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "+84939195145",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 415,
                "countJob": 391,
                "phoneNumber": "0387354421",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-02-16T02:03:53.513Z",
                "updatedAt": "2024-07-09T11:32:34.443Z",
                "SupporterId": null
            },
            "designer": {
                "id": 315,
                "email": "khanhsuper1310.kns@gmail.com",
                "password": "$2a$08$qUGuOC2xbFVkVpxG1bXvAO.3WNmm.iO2pKdDaUoF7gIKg6wjInx3S",
                "role": "designer",
                "name": "Nguyễn Khánh",
                "bankName": "Vietcombank",
                "bankNumber": "0071001128874",
                "accountBankName": "NGUYEN THI KHANH TRANG",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/https://t.me/khanhnguyen0212",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 2843,
                "countJob": 2631,
                "phoneNumber": "0903803405",
                "isEmailVerified": true,
                "avatar": "static/20240510/2024051000107840.jpeg",
                "supporterId": null,
                "countReject": 1083,
                "deletedAt": null,
                "createdAt": "2023-11-05T09:18:21.043Z",
                "updatedAt": "2024-07-09T08:40:52.705Z",
                "SupporterId": null
            }
        },
        {
            "images": [
                {
                    "path": "static/20240708/2024070819484100.png",
                    "description": "Ảnh 1"
                }
            ],
            "id": 63535,
            "count": 1,
            "creatorId": 501,
            "designerId": 315,
            "productTypeId": 12,
            "designTypeId": 4,
            "price": 70000,
            "actuallyReceived": 56000,
            "totalPrice": 70000,
            "totalActuallyReceived": 56000,
            "tag": "",
            "url": "https://drive.google.com/drive/folders/1Z0kSo35JZycPoLblnHdHAnTAexF0UUkU?usp=drive_link",
            "name": "#8486887",
            "note": "- File PSD sửa chi tiết \n- File PNG 4800*5400 300DPI \n\n- rds . \n\ndesigner KHÁNH nhận",
            "countReject": 3,
            "status": 3,
            "startedAt": "2024-07-09T06:59:53.491Z",
            "expiresAt": "2024-07-09T07:59:53.491Z",
            "deletedAt": null,
            "isPriority": false,
            "createdAt": "2024-07-08T12:49:20.951Z",
            "updatedAt": "2024-07-09T11:36:49.539Z",
            "productType": {
                "id": 12,
                "name": "Tumbler",
                "countJob": 0,
                "index": 8,
                "deletedAt": null,
                "createdAt": "2023-06-13T15:55:39.158Z",
                "updatedAt": "2023-06-13T15:58:02.112Z"
            },
            "designType": {
                "id": 4,
                "name": "Redesign",
                "countJob": 0,
                "index": 2,
                "deletedAt": null,
                "createdAt": "2023-08-17T05:20:05.925Z",
                "updatedAt": "2023-08-17T05:20:12.437Z"
            },
            "creator": {
                "id": 501,
                "email": "support@printcold.com",
                "password": "$2a$08$ZVFcnJBisdlKp9RlaanSVursiLTKIAHNvdeJwAg0mPpAyBv2mOxkW",
                "role": "creator",
                "name": "Printcold",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "+84939195145",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 415,
                "countJob": 391,
                "phoneNumber": "0387354421",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2024-02-16T02:03:53.513Z",
                "updatedAt": "2024-07-09T11:32:34.443Z",
                "SupporterId": null
            },
            "designer": {
                "id": 315,
                "email": "khanhsuper1310.kns@gmail.com",
                "password": "$2a$08$qUGuOC2xbFVkVpxG1bXvAO.3WNmm.iO2pKdDaUoF7gIKg6wjInx3S",
                "role": "designer",
                "name": "Nguyễn Khánh",
                "bankName": "Vietcombank",
                "bankNumber": "0071001128874",
                "accountBankName": "NGUYEN THI KHANH TRANG",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/https://t.me/khanhnguyen0212",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 2843,
                "countJob": 2631,
                "phoneNumber": "0903803405",
                "isEmailVerified": true,
                "avatar": "static/20240510/2024051000107840.jpeg",
                "supporterId": null,
                "countReject": 1083,
                "deletedAt": null,
                "createdAt": "2023-11-05T09:18:21.043Z",
                "updatedAt": "2024-07-09T08:40:52.705Z",
                "SupporterId": null
            }
        },
        {
            "images": [
                {
                    "path": "static/20240706/2024070621379670.png",
                    "description": "Ảnh 1"
                }
            ],
            "id": 63238,
            "count": 1,
            "creatorId": 100,
            "designerId": 529,
            "productTypeId": 6,
            "designTypeId": 2,
            "price": 27000,
            "actuallyReceived": 23000,
            "totalPrice": 27000,
            "totalActuallyReceived": 23000,
            "tag": "1737539739",
            "url": "https://drive.google.com/file/d/1PdJ37-GZQNNPvZcP1OCqrc4VUlcdiT4L/view?usp=sharing",
            "name": "#5643688",
            "note": "IN 2 MÀU SÁNG TỐI",
            "countReject": 1,
            "status": 3,
            "startedAt": "2024-07-06T14:38:19.085Z",
            "expiresAt": "2024-07-06T16:38:19.085Z",
            "deletedAt": null,
            "isPriority": false,
            "createdAt": "2024-07-06T14:38:02.223Z",
            "updatedAt": "2024-07-09T15:06:58.611Z",
            "productType": {
                "id": 6,
                "name": "T-Shirt 2D",
                "countJob": 0,
                "index": 0,
                "deletedAt": null,
                "createdAt": "2023-06-13T15:55:13.312Z",
                "updatedAt": "2023-06-13T15:55:13.312Z"
            },
            "designType": {
                "id": 2,
                "name": "Clone",
                "countJob": 0,
                "index": 0,
                "deletedAt": null,
                "createdAt": "2023-06-13T15:54:53.563Z",
                "updatedAt": "2023-06-13T15:54:53.563Z"
            },
            "creator": {
                "id": 100,
                "email": "nguyenquyen24112@gmail.com",
                "password": "$2a$08$0e7sZb08f/oHakE44PNRc.SXIkIUiS/Arl0ChZk2JE7Dyc6opnMD2",
                "role": "creator",
                "name": "Nguyen Nguyen",
                "bankName": null,
                "bankNumber": null,
                "accountBankName": null,
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://www.facebook.com/nguyennguyends",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 495,
                "countJob": 390,
                "phoneNumber": "0705210693",
                "isEmailVerified": true,
                "avatar": "",
                "supporterId": null,
                "countReject": 0,
                "deletedAt": null,
                "createdAt": "2023-07-28T07:31:25.001Z",
                "updatedAt": "2024-07-09T15:32:13.621Z",
                "SupporterId": null
            },
            "designer": {
                "id": 529,
                "email": "danganh1991hd1389@gmail.com",
                "password": "$2a$08$wnLqGMFoMz7xo0TAF8z.XeCXlieaKOWHpGugxAqYr0ayHHt5mYrQ6",
                "role": "designer",
                "name": "Đặng Việt Anh",
                "bankName": "Techcombank",
                "bankNumber": "19036136614016",
                "accountBankName": "DANG VIET ANH",
                "idCard": null,
                "address": null,
                "note": null,
                "linkFb": null,
                "linkTelegram": "https://t.me/LihAnhQuan",
                "salary": null,
                "status": 1,
                "rate": 0,
                "sampleNumber": 4750,
                "countJob": 4427,
                "phoneNumber": "0899272668",
                "isEmailVerified": true,
                "avatar": "static/20240309/2024030915553700.jpeg",
                "supporterId": null,
                "countReject": 568,
                "deletedAt": null,
                "createdAt": "2024-03-05T08:17:16.806Z",
                "updatedAt": "2024-07-09T15:32:13.620Z",
                "SupporterId": null
            }
        }
    ]
}