import React from 'react'

import { useEffect, useState } from 'react'
import { Input, Select, Modal, Form, Button, Col, Row, Typography } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { validateEmail, validatePassword, validatePhone } from '../../helpers/functions'
import { useStore } from '../../hooks'
import { BANKS, getPage, PAGE_SIZE, ROLE } from '../../helpers/constants'
import { cloneDeep, debounce, trim } from 'lodash'
import { rule } from 'src/helpers/validateRule'
import TextArea from 'antd/lib/input/TextArea'
import styled from 'styled-components'
import CardItems from 'src/common/components/CardItems'
const { Option } = Select

const formItemLayout = {
   labelCol: {
      xs: { span: 24 },
      sm: { span: 2 }
   },
   wrapperCol: {
      xs: { span: 24 },
      sm: { span: 22 }
   }
}

export const ModalSelectorWapper = styled.div`
   .cursor-pointer {
      cursor: pointer;
   }
   @media screen and (max-width: 820px) {
   }
`
const SelectProductModal = observer((props: any) => {
   const { t } = useTranslation()

   const { item, visible, onOk, onCancel, onlyView } = props
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [isChangePassword, setIsChangePassword] = useState(false)

   const [searchValue, setSearchValue] = useState('')
   const [status, setStatus] = useState(parseInt(item?.status))
   const UserStore = useStore('UserStore')
   const AuthStore = useStore('AuthStore')
   const role: any = AuthStore.data_role
   const [form] = Form.useForm()
   const { Title, Text } = Typography
   const [tags, setTags] = useState<any>([])
   const [tagSelected, setTagSelected] = useState([])
   const CommonConfigStore = useStore('CommonConfigStore')
   const { rows } = CommonConfigStore.tagList
   const [loop, setLoop] = useState([1, 2, 3, 4, 5, 6, 7])
   const OrdersStore = useStore('OrdersStore')
   const { rows: productRows, offset, count } = OrdersStore.products
   const [productsSelected, setProductsSelected] = useState<any[]>([])

   useEffect(() => {
      if (rows && rows?.length > 0) {
         const tag = rows.map((ro: any) => {
            return { value: ro?.id + '', label: ro?.name }
         })
         setTags(tag)
      }
   }, [rows])

   useEffect(() => {
      if (!rows || rows.length < 1) {
         const offset = getPage(0)
         const params = { offset, limit: 1000 }
         CommonConfigStore.getTagList(params)
      }
   }, [])

   useEffect(() => {
      if (OrdersStore.productsSelected.length) setProductsSelected(cloneDeep(OrdersStore.productsSelected))
   }, [])

   useEffect(() => {
      const offset = getPage(0)
      const params = { offset, limit: 100, status: 1, priceWithAccount: OrdersStore.sellerId }
      OrdersStore.getProducts(params)
   }, [])

   const handleChangeTag=(tag:any)=>{
      console.log('tag',tag);
      setTagSelected(tag)
      const offset = getPage(0)
      const params = { offset, limit: 100, status: 1, name: searchValue, ...{ tags: tag.toString() } }
      OrdersStore.getProducts(params)
   }
   const handleChangeTextSearch = (value: any) => {
      const offset = getPage(0)
      const textSearch = trim(value) || ''
      setSearchValue(textSearch)
      const params = { offset, limit: 100, status: 1, name: textSearch, ...{ tags: tagSelected.toString() } }
      OrdersStore.getProducts(params)
   }
   const handleChangeText = (value: any) => {
      console.log('value',value?.target?.value);
      
      const offset = getPage(0)
      const textSearch = trim(value?.target?.value) || ''
      setSearchValue(textSearch)
      const params = { offset, limit: 100, status: 1, name: textSearch, ...{ tags: tagSelected.toString() } }
      OrdersStore.getProducts(params)
   }

   const handSelectProduct = (item: any) => {
      handleSelectProducts(item)
   }

   const handleSelectProducts = (item: any) => {
      const isProductExist = productsSelected.findIndex((p: any) => p.id === item.id)

      if (isProductExist > -1) return setProductsSelected(productsSelected.filter((p: any) => p.id !== item.id))

      setProductsSelected((prev: any) => [...prev, item])
   }

   const onSave = () => {
      OrdersStore.setProductsSelected(productsSelected)
      onOk()
   }

   const renderContent = () => {
      return (
         <ModalSelectorWapper>
            <Form {...formItemLayout} form={form} name="SellectProduct" scrollToFirstError>
               <Row>
                  <Col span={24}>
                     <Input.Search placeholder="Tên sản phẩm" allowClear onChange={debounce(handleChangeText,700)} onSearch={handleChangeTextSearch} />
                  </Col>
               </Row>
               <Form.Item name="tags" label={'Tag'}>
                  <Select
                     mode="multiple"
                     placeholder="Chọn"
                     defaultValue={tagSelected}
                     options={tags}
                     onChange={(value) => handleChangeTag(value)}
                  />
               </Form.Item>
            </Form>
            <CardItems
               listCard={productRows}
               onSelect={handSelectProduct}
               style={{ maxHeight: '300px', overflowY: 'scroll' }}
               itemsSelected={productsSelected}
               classNameItem="gx-mx-0 cursor-pointer"
            />
         </ModalSelectorWapper>
      )
   }

   return !onlyView ? (
      <Modal
         width={'90%'}
         style={{ maxWidth: '700px' }}
         visible={visible}
         centered
         title={'Chọn sản phẩm'}
         okText={t(GLOBAL_CLIENT.save)}
         cancelText={t(GLOBAL_CLIENT.close)}
         onCancel={onCancel}
         onOk={onSave}
      >
         {renderContent()}
      </Modal>
   ) : (
      <Modal
         width={'90%'}
         style={{ maxWidth: '700px' }}
         visible={visible}
         centered
         title={'Chọn sản phẩm'}
         okText={t(GLOBAL_CLIENT.edit)}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={onCancel}
         onOk={onSave}
         footer={[
            <>
               <Button key="back" onClick={onCancel}>
                  Đóng
               </Button>
            </>
         ]}
      >
         {renderContent()}
      </Modal>
   )
})

export default SelectProductModal
