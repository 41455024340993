import { Button, Checkbox, Input, InputNumber, Modal, notification } from 'antd'
import { debounce, isNumber } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Images from 'src/common/Images'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { GiaMinMax, typeFund } from 'src/helpers/constants'
import { formatCurrency, showMessageSuccess } from 'src/helpers/functions'
import useModal from 'src/helpers/modal/useModal'
import theme from 'src/helpers/theme'
import { useStore } from 'src/hooks'

type TopupModalProps = {
   isTopupModalOpen: boolean
   handleCancel: () => void
}

const TopupModal: React.FC<TopupModalProps> = ({ isTopupModalOpen, handleCancel }) => {
   const FundStore = useStore('FundStore')
   const AuthStore = useStore('AuthStore')
   const CommonConfigStore = useStore('CommonConfigStore')
   const { confirm } = useModal()
   const [amount, setAmount] = useState<any>(null)
   const [showMessage, setShowMessage] = useState<any>(false)
   const [isUSD, setUSD] = useState<any>(false)
   const { t } = useTranslation()
   // https://www.vietqr.io/danh-sach-api/link-tao-ma-nhanh/

   const baseUrl: string = `https://img.vietqr.io/image/${CommonConfigStore.bankAccount.BANK_ID}-${CommonConfigStore.bankAccount.ACCOUNT_NO}-compact2.jpg?addInfo=${CommonConfigStore.bankAccount.DESCRIPTION}&accountName=${CommonConfigStore.bankAccount.ACCOUNT_NAME}`
   const [bankQRUrl, setBankQRUrl] = useState<string>(baseUrl)

   const handleClose = () => {
      //@ts-ignore
      confirm({
         title: 'Xác nhận',
         icon: null,
         content: 'Bạn thực sự muốn hủy lệnh chuyển tiền?',
         onOk() {
            onClose()
         },
         onCancel() {
            // console.log('Cancel')
         },
         okText: 'Có',
         cancelText: 'Không'
      })
   }

   const handleApplyTopup = async () => {
      if (amount) {
         setBankQRUrl(baseUrl + `&amount=${amount}`)
         if (amount < CommonConfigStore?.designerConfigList?.minRecharge) {
            notification.open({
               message: t(GLOBAL_CLIENT.thongbao),
               description: t(GLOBAL_CLIENT.errorNapTien).replace(
                  '{0}',
                  CommonConfigStore?.designerConfigList?.minRecharge
               )
               // onClick: () => {
               //    console.log('Notification Clicked!')
               // }
            })
         } else {
            const params = {
               price: parseInt(amount + '') / CommonConfigStore?.designerConfigList?.exchangeRate,
               priceVND: amount,
               code: CommonConfigStore.bankAccount?.DESCRIPTION || '',
               // accountId: AuthStore?.data_getUserInfo?.id || '',
               description: t(GLOBAL_CLIENT.sellernaptien),
               type: typeFund.RECHARGE
               // status:1
            }
            // console.log('params ', params, 'AuthStore?.data_getUserInfo;', AuthStore?.data_getUserInfo)
            const result = await FundStore.createFund(params)
            // console.log('result;2', result)
            if (result?.code == 200) {
               showMessageSuccess(t(GLOBAL_CLIENT.ckthanhcong), handleCancel)
               setShowMessage(false)
               setAmount(null)
            }
         }

         // setShowMessage(true)

         // handleCancel()
      } else {
      }
   }

   const handleChangeAmount = (value: any) => {
      if (!isUSD) {
         setAmount(value)
         if (isNumber(value)) {
            setBankQRUrl(baseUrl + `&amount=${value}`)
         }
      } else {
         setAmount(value)
         if (isNumber(value * CommonConfigStore?.designerConfigList?.exchangeRate)) {
            setBankQRUrl(baseUrl + `&amount=${value * CommonConfigStore?.designerConfigList?.exchangeRate}`)
         }
      }

      // console.log('bank', bankQRUrl, 'value', value)
   }

   const onClose = () => {
      setShowMessage(false)
      setAmount(null)
      handleCancel()
   }

   const copyClipboard = () => {
      if (typeof navigator.clipboard != 'undefined' && window.isSecureContext) {
         navigator.clipboard.writeText(CommonConfigStore.bankAccount.ACCOUNT_NO).then(
            () => {
               notification.open({
                  message: t(GLOBAL_CLIENT.thongbao),
                  description: t(GLOBAL_CLIENT.copyclipboard).replace('{0}', CommonConfigStore.bankAccount.ACCOUNT_NO)
                  // onClick: () => {
                  //    console.log('Notification Clicked!')
                  // }
               })
            },
            () => null // do something on fail
         )
      }
   }
   const x =
      !amount ||
      !isNumber(amount) ||
      (CommonConfigStore?.designerConfigList?.minRecharge &&
         parseInt(amount + '') < parseInt(CommonConfigStore?.designerConfigList?.minRecharge + ''))

   console.log('xxxx', x, '!amount', !amount, '  !isNumber(amount) ', !isNumber(amount))
   const onChangeStatus = (e: any) => {
      // const val = e.target.checked ? 1 : 0
      // console.log('val; ', val, 'e: ', e.target.checked)
      setUSD(e.target.checked)
   }
   return (
      <Modal
         maskClosable={false}
         closable={false}
         title={t(GLOBAL_CLIENT.deposit)}
         visible={isTopupModalOpen}
         onCancel={onClose}
         footer={null}
      >
         {x ? (
            <div
               className="ant-upload-hint"
               style={{
                  paddingTop: 12,
                  paddingBottom: 12,
                  fontSize: 12,
                  textAlign: 'center',
                  fontStyle: 'italic',
                  color: 'red'
               }}
            >
               Số tiền nạp tối thiểu là {formatCurrency(CommonConfigStore?.designerConfigList?.minRecharge, true)} đ
            </div>
         ) : (
            <>
               <img src={bankQRUrl} alt="" />
               <div
                  className="form__desc"
                  style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center', margin: 8 }}
                  onClick={copyClipboard}
               >
                  {t(GLOBAL_CLIENT.copystk)}
                  <img src={Images.ic_copy} alt="" style={{ width: '20px', height: '20px', marginLeft: 12 }} />
               </div>
            </>
         )}
         <div style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
            <Input.Group compact style={{ textAlign: 'center' }}>
               {/* <Input
               style={{ width: 'calc(100% - 200px)', textAlign: 'start' }}
               placeholder="Số tiền muốn nạp"
               onChange={handleChangeAmount}
               maxLength={254}
            /> */}
               <InputNumber
                  style={{ width: '50%' }}
                  placeholder={t(GLOBAL_CLIENT.sotienmuonnap) + `${isUSD ? '(USD)' : '(VNĐ)'}`}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  min={GiaMinMax.minNap}
                  max={GiaMinMax.maxNap}
                  step={1000}
                  onChange={(value: any) => handleChangeAmount(value)}
               />
               <Button type="primary" onClick={debounce(handleApplyTopup, 500)} disabled={x}>
                  {t(GLOBAL_CLIENT.xacnhancktc)}
               </Button>
               {showMessage && (
                  <p
                     className="ant-upload-hint"
                     style={{ paddingTop: 12, fontSize: 12, fontStyle: 'italic', color: theme.color.dark_0 }}
                  >
                     {t(GLOBAL_CLIENT.ckthanhcong)}
                  </p>
               )}
            </Input.Group>
            {/* <Checkbox name="USD" defaultChecked={isUSD} onChange={onChangeStatus} disabled>
               <label>CK tiền USD</label>
            </Checkbox> */}
            {!isUSD && (
               <div style={{ paddingTop: 8 }}>
                  {`Tỉ giá: ${formatCurrency(
                     CommonConfigStore?.designerConfigList?.exchangeRate,
                     true
                  )}đ  -  Quy đổi USD: ${
                     amount
                        ? ' ' +
                          '$' +
                          formatCurrency(parseFloat(amount + '') / CommonConfigStore?.designerConfigList?.exchangeRate)
                        : 0
                  }`}
               </div>
            )}
            {isUSD && (
               <div>
                  Quy đổi VND:
                  {amount
                     ? ' ' +
                       formatCurrency(
                          parseFloat(amount + '') * CommonConfigStore?.designerConfigList?.exchangeRate,
                          true
                       ) +
                       ' đ'
                     : 0}
               </div>
            )}
         </div>

         <div style={{ width: '100%', textAlign: 'center', marginTop: 16 }}>
            <Button type="dashed" onClick={debounce(handleClose, 500)}>
               {t(GLOBAL_CLIENT.huy)}
            </Button>
         </div>
      </Modal>
   )
}

export default TopupModal
