import { Card, Avatar, Button, Tag } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'

export const CartItemWrapper = styled.div`
   .card-item {
      margin: 2px 8px 2px 8px;
      flex: 1 1 auto;
   }
   .card-item .avatar {
      flex-shrink: 0;
   }
   .card-item .info {
      flex: 1 1 auto;
   }
   .card-item .info .name {
      font-size: 0.7rem;
      font-weight: 500;
   }
   .card-item .info .description {
      color: gray;
   }
   .card-item .info .price {
      color: green;
      font-size: 0.65rem;
      padding-right: 8px;
   }
   .card-item .quantity {
      flex-shrink: 0;
      font-size: 0.7rem;

      .quantity-input {
         width: 100%;
         text-align: center;
         border: 0.1px solid #cdcdcd80;

         &:focus {
            outline: 1px solid #038fde;
         }
      }
   }
   .trash-wrapper {
      i {
         cursor: pointer;
         color: #ff3b30;
         &:hover {
            &.icon-color {
               color: #ff3b30;
            }
         }
      }
   }

   .ant-btn-circle {
      min-width: 28px;
      // line-height: 27px;
   }

   .ant-btn-icon-only {
      width: 28px;
      height: 28px;
   }

   @media screen and (max-width: 575px) {
      .ant-btn {
         margin-bottom: 0;
      }
   }
`
const CardItem = ({ item, type = null, onClick, className, onDelete, onChange }: any) => {
   const [quantity, setQuantity] = useState(item.qty ? item.qty : 1)

   const increasement = () => {
      setQuantity(quantity + 1)
      onChange(quantity + 1)
   }
   const decreasement = () => {
      if (quantity === 0) return
      setQuantity(quantity - 1)
      onChange(quantity - 1)
   }

   const handleChangeQuantity = (value: any) => {
      if (value.length > 6) return
      if (value < 0) return
      setQuantity(value)
      onChange(value)
   }

   const handleOnBlur = (value: any) => {
      const quantity = value.replace(/^0+/, '')

      if (quantity === '') return setQuantity(1)

      setQuantity(quantity)
   }

   return (
      <CartItemWrapper>
         <div className="gx-d-flex gx-align-items-center" onClick={onClick}>
            <Card bodyStyle={{ padding: '4px 8px 4px 8px' }} className={`card-item ${className}`}>
               <div className="gx-d-flex gx-align-items-center ">
                  <Avatar
                     className="gx-mr-2 avatar"
                     shape="square"
                     size={36}
                     gap={4}
                     src={`${process.env.REACT_APP_IMAGE_ENDPOINT}/${item?.images?.length ? item?.images[0] : ''}`}
                  />
                  <div className="info">
                     <div className="name">
                        <span
                           style={{
                              textTransform: 'capitalize',
                              border: 1,
                              background: '#cdcdcd',
                              borderRadius: 2,
                              paddingLeft: 4,
                              paddingRight: 4,
                              marginRight: 4
                           }}
                        >
                           #{item?.id}
                        </span>
                        {item?.name}
                     </div>
                     <div className="description">{item?.description}</div>
                     <div style={{}}>
                        {item?.base_cost && <span className="price">{`${item?.base_cost}$`}</span>}
                        {item?.listTags &&
                           item?.listTags?.map((it: any, index: number) => {
                              return (
                                 <span
                                    style={{
                                       textTransform: 'capitalize',
                                       border: 1,
                                       background: '#cdcd',
                                       borderRadius: 2,
                                       paddingLeft: 4,
                                       paddingRight: 4,
                                       marginRight: 4,
                                       fontSize: 9
                                    }}
                                 >
                                    #{it?.name}
                                 </span>
                                 // <Tag color="gold" style={{ textTransform: 'capitalize', fontSize: 8 }} key={index}>
                                 //    {it?.name}
                                 // </Tag>
                              )
                           })}
                     </div>
                  </div>
                  {type === 'edit' ? (
                     <div
                        className="gx-d-flex gx-align-items-center"
                        style={{ marginLeft: 4, alignItems: 'center', justifyContent: 'center' }}
                     >
                        <Button
                           type="primary"
                           size="small"
                           // shape="circle"
                           icon={<MinusOutlined />}
                           style={{ marginRight: 4 }}
                           onClick={decreasement}
                           disabled={quantity <= 1}
                        ></Button>
                        <div className="quantity" style={{ width: '40px', textAlign: 'center' }}>
                           <input
                              type="number"
                              className="quantity-input"
                              value={quantity}
                              onChange={(e: any) => handleChangeQuantity(e.target.value)}
                              onBlur={(e: any) => handleOnBlur(e.target.value)}
                           ></input>
                        </div>
                        <Button
                           // shape="circle"
                           type="primary"
                           size="small"
                           // shape="circle"
                           icon={<PlusOutlined />}
                           style={{ marginLeft: 4 }}
                           onClick={increasement}
                           disabled={quantity >= 999999}
                        ></Button>
                     </div>
                  ) : null}
                  {type === 'view' ? <div className="quantity">Số lượng: {quantity}</div> : null}
               </div>
            </Card>

            {type === 'edit' ? (
               <div className="trash-wrapper">
                  <i
                     className={`icon icon-trash gx-fs-xlxl gx-d-flex icon-color`}
                     style={{ fontSize: 20 }}
                     onClick={onDelete}
                  />
               </div>
            ) : null}
         </div>
      </CartItemWrapper>
   )
}

export default CardItem
