import { useEffect, useState } from 'react'
import { Table, Space, Pagination, Col, Row, Input, Breadcrumb, DatePicker } from 'antd'
import { isEmpty, trim } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks'
import {
   DATE_FORMAT_CLIENT,
   DATE_FORMAT_CLIENT_PARAMS,
   PAGE_SIZE} from '../../helpers/constants'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { Loading } from '../../common'
import moment from 'moment'
import 'moment/locale/vi'
import locale from 'antd/es/date-picker/locale/vi_VN'
import useWindowDimensions from '../../common/useWindowDimensions'
import { formatCurrency } from 'src/helpers/functions'
import { getPage } from 'src/helpers/constants'
import TopupAdminModal from 'src/common/components/topup/TopupAdminModal'

const { RangePicker } = DatePicker
const ImportExportHistory = observer((props: any) => {
   const { t } = useTranslation()
   const WarehouseStore = useStore('WarehouseStore')
   const [textSearch, set_textSearch] = useState('')
   const { height } = useWindowDimensions()
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [isLoading, setIsLoading] = useState(false)
   const [startTime, setStartTime] = useState(moment().subtract(1, 'months'))
   const [endTime, setEndTime] = useState(moment())

   const [item, setItem] = useState(null)
   const [topup, setTopup] = useState(null)
   const [sortDateDesc, setSortDateDesc] = useState(true)
   const [isTopupModalOpen, setIsTopupModalOpen] = useState(false)

   const { rows, offset, count } = WarehouseStore.warehouseList
   const { before, collect, spend, after } = WarehouseStore.priceWarehouse

   useEffect(() => {
      getListData(0, {
         text: trim(textSearch),
         from: moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
         to: moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString()
      })
      WarehouseStore.getTotalPrice(
         moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
         moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString()
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [textSearch, startTime, endTime])

   const getListData = async (currentPage: number, param?: any) => {
      try {
         // setIsLoading(true)
         const offset = getPage(currentPage)
         const params = { offset, ...param }
         //  console.log('params', params)

         await WarehouseStore.getWarehouseList(params)
      } catch (error) {
         //  console.log(error)
      } finally {
         // setIsLoading(false)
      }
   }

   const onChange = (currentPage: number) => {
      getListData(currentPage - 1, { text: trim(textSearch) })
   }

   const handleChangeTextSearch = (value: any) => {
      set_textSearch(value)
   }
   const setDetailInfo = (value: any) => {
      setItem(value)
   }
 
   const showBill = (value: any) => {
      setDetailInfo(value)
   }
   const onRefreshList = () => {
      getListData(0, {
         text: trim(textSearch),
         from: moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
         to: moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString()
      })
   }

   const handleCloseTopup = () => {
      setIsTopupModalOpen(false)
   } 
   const columns = [
      {
         title: 'SKU',
         dataIndex: 'code',
         key: 'code',
         width: 120,
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
      },
      {
         title: 'Tên sản phẩm',
         dataIndex: 'name',
         key: 'name',
         width: 80,
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
      },
      {
         title: 'Nhập',
         dataIndex: 'price',
         key: 'price',
         width: 120,
         render: (value: any) => {
            return <span className="txtMax2Line">{formatCurrency(value, true)}</span>
         }
      },
      {
         title: 'Xuất',
         dataIndex: 'price',
         key: 'price',
         width: 120,
         render: (value: any) => {
            return <span className="txtMax2Line">{formatCurrency(value, true)}</span>
         }
      },
      {
         title: 'OrderID',
         dataIndex: 'price',
         key: 'price',
         width: 120,
         render: (value: any) => {
            return <span className="txtMax2Line">{formatCurrency(value, true)}</span>
         }
      },
      {
         title: 'Ngày',
         dataIndex: 'price',
         key: 'price',
         width: 120,
         render: (value: any) => {
            return <span className="txtMax2Line">{formatCurrency(value, true)}</span>
         }
      },
      {
         title: 'action',
         key: 'action',
         dataIndex: 'updatedAt',
         width: 120,
         align: 'center' as 'center',
         fixed: 'right' as 'right',
         render: (text: string, record: any) => {
            return (
               <span className="txtMax2Line">
                  <span onClick={() => showBill(record)} className="gx-link">
                     {'Chi tiết'}
                  </span>
               </span>
            )
         }
      }
   ]

   const renderPagination = () => {
      if (isEmpty(rows)) {
         return null
      }

      return (
         <div className="containerPagination">
            <Pagination
               onChange={onChange}
               pageSize={PAGE_SIZE}
               current={offset / PAGE_SIZE + 1}
               showSizeChanger={false}
               total={count}
            />
         </div>
      )
   }

   function onChangeTable(pagination: any, filters: any, sorter: any, extra: any) {
      // console.log('params', pagination, filters, sorter, extra)
      if (sorter.order === 'ascend') {
         setSortDateDesc(false)
      } else {
         setSortDateDesc(true)
      }
   }

   const handleRangePickerChange = (date: any) => {
      // set_dateRangerPicker(date);
      //  console.log('datee: ', date)
      setStartTime(date[0])
      setEndTime(date[1])
   }

   const renderHeader = () => {
      return (
         <Row
            className="row-container-head"
            style={{ background: '#fff', padding: 12, borderRadius: 6, marginLeft: 0, marginRight: 0 }}
         >
            <Col span={4}>
               <Col>
                  <span style={{ fontWeight: 'bold' }}>Đầu kỳ</span>
               </Col>
               <Col>
                  <span style={{ fontSize: 18, color: '#6998B9', fontWeight: 'bolder' }}>
                     {formatCurrency(before, true)}
                  </span>
               </Col>
            </Col>
            <Col span={1} style={{ alignSelf: 'center' }}>
               {/* <span style={{ fontWeight: 'bolder' }}>+</span> */}
            </Col>
            <Col span={4}>
               <Col>
                  <span style={{ fontWeight: 'bold' }}>Tổng nhập</span>
               </Col>
               <Col>
                  <span style={{ fontSize: 18, color: '#89AD88', fontWeight: 'bolder' }}>
                     {formatCurrency(collect, true)}
                  </span>
               </Col>
            </Col>
            <Col span={1} style={{ alignSelf: 'center' }}>
               {/* <span style={{ fontWeight: 'bolder' }}>-</span> */}
            </Col>
            <Col span={4}>
               <Col>
                  <span style={{ fontWeight: 'bold' }}>Tổng xuất</span>
               </Col>
               <Col>
                  <span style={{ fontSize: 18, color: '#B87986', fontWeight: 'bolder' }}>
                     {formatCurrency(spend, true)}
                  </span>
               </Col>
            </Col>
            <Col span={1} style={{ alignSelf: 'center' }}>
               {/* <span style={{ fontWeight: 'bolder' }}>=</span> */}
            </Col>
            <Col span={4}>
               <Col>
                  <span style={{ fontWeight: 'bold' }}>Tồn kho</span>
               </Col>
               <Col>
                  <span style={{ fontSize: 18, color: '#3F74A1', fontWeight: 'bolder' }}>
                     {formatCurrency(after, true)}
                  </span>
               </Col>
            </Col>
         </Row>
      )
   }

   return (
      <>
         <Loading isLoading={isLoading} />
         <Row>
            <Col span={24}>
               <Breadcrumb separator=">">
                  <Breadcrumb.Item>
                     <span
                        className="gx-link"
                        style={{ textDecoration: 'underline' }}
                        onClick={() => props.showWarehouse()}
                     >
                        Kho hàng
                     </span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className="gx-link">Kho 1</Breadcrumb.Item>
                  <Breadcrumb.Item className="gx-link" > <span
                        className="gx-link"
                        style={{ textDecoration: 'underline' }}
                        onClick={() => props.showBill()}
                     >
                        Danh sách phiếu
                     </span></Breadcrumb.Item>
                  <Breadcrumb.Item className="gx-link">IAWMPOUYS</Breadcrumb.Item>
                  <Breadcrumb.Item>
                     <span className="gx-link"> <span
                        className="gx-link"
                        style={{ textDecoration: 'underline' }}
                        onClick={() => props.showProduct()}
                     >
                        Danh sách sản phẩm
                     </span></span>
                  </Breadcrumb.Item>
               </Breadcrumb>
               <Row className="row-container-head">
                  <Col xs={24} md={12}>
                     <Input.Search
                        onSearch={handleChangeTextSearch}
                        placeholder={t(GLOBAL_CLIENT.txtSearch)}
                        allowClear
                     />
                  </Col>
                  <Col xs={24} md={12}>
                     <div className="text-right">
                        <Space>
                           <RangePicker
                              allowClear={false}
                              defaultValue={[startTime, endTime]}
                              format={DATE_FORMAT_CLIENT}
                              locale={locale}
                              onChange={handleRangePickerChange}
                           />
                           {/* <Button type="primary" className="btn-add" onClick={showCreate}>
                              <i className="icon icon-add icon-in-button" />

                              {t(GLOBAL_CLIENT.create)}
                           </Button> */}
                        </Space>
                     </div>
                  </Col>
               </Row>

               {renderHeader()}
               <Space className="total-items">
                  {t(GLOBAL_CLIENT.totalItems)}: {count}
               </Space>

               <Table
                  // scroll={{ x: 1200 }}
                  scroll={{ y: height - 280 }}
                  columns={columns}
                  dataSource={rows}
                  rowKey="id"
                  pagination={false}
                  onChange={onChangeTable}
               />

               {renderPagination()}
            </Col>
         </Row>
         {isTopupModalOpen && (
            <TopupAdminModal
               isTopupModalOpen={isTopupModalOpen}
               topup={topup}
               handleCancel={handleCloseTopup}
               onRefreshList={onRefreshList}
            />
         )}
      </>
   )
})

export default ImportExportHistory
