import React from 'react'
import { observer } from 'mobx-react-lite'
import CardItem from './item'
import styled from 'styled-components'

type CardItemProps = {
   listCard?: any[]
   type?: string
   style?: object
   onSelect?: any
   classNameItem?: string
   className?: string
   itemsSelected?: any[]
   onDelete?: any
}
export const ListCardStyle = styled.div`
   .highlight {
      background-color: #f6ffed40;
      border: 0.5px solid #b7eb8f;
   }
`
const CardItems: React.FC<CardItemProps> = observer(
   ({ listCard, type, style = {}, onSelect, classNameItem = '', className = '', itemsSelected, onDelete }) => {
      const cardHighlight = (ỉtem: any) => {
         if (itemsSelected && itemsSelected.find((itemSelect) => itemSelect.id === ỉtem.id)) {
            return 'highlight'
         }
         return ''
      }

      return (
         <ListCardStyle>
            <div style={style} className={className}>
               {listCard?.map((item: any, index: number) => (
                  <CardItem
                     item={item}
                     key={index}
                     type={type}
                     onClick={() => (onSelect ? onSelect(item) : null)}
                     className={`${classNameItem} ${cardHighlight(item)}`}
                     onDelete={() => (onDelete ? onDelete(index) : null)}
                     onChange={(value: any) => (item.qty = value)}
                  />
               ))}
            </div>
         </ListCardStyle>
      )
   }
)

export default CardItems
