import { Button, Layout } from 'antd'
import { observer } from 'mobx-react-lite'
import { useRouteMatch } from 'react-router-dom'
import Sidebar from '../sidebar/Sidebar'
import { useStore } from '../../../hooks'
import { TAB_SIZE } from '../../../helpers/constants'
import SidebarContent from '../sidebar/SidebarContent'
import UserProfile from '../sidebar/UserProfile'
import Images from '../../../common/Images'
import { accountantMeta } from '../../routes/metaData'
import { useEffect } from 'react'
import StickyChatButton from 'src/common/components/sticky-chat/StickyChatButton'
import AccountantRouters from '../../routes/AccountantRouters'
import UserNotification from '../sidebar/UserNotification'

const { Content } = Layout
const { Sider } = Layout

const LayoutAccountant = observer((props: any) => {
   const match = useRouteMatch()
   const AuthStore = useStore('AuthStore')
   const handleOpenSidebar = () => {
      AuthStore.action_openSidebar()
   }
   const UserStore = useStore('UserStore')

   useEffect(() => {
      // getListData()
   }, [])

   const getListData = async () => {
      try {
         await UserStore.getCreators()
      } catch (error) {
         // console.log(error)
      } finally {
      }
   }
   return (
      <Layout className="gx-app-layout layout-admin">
         {/* <StickyChatButton /> */}
         <Sider
            className={`gx-app-sidebar gx-layout-sider-dark ${AuthStore.navCollapsed ? '' : 'gx-collapsed-sidebar'} `}
            trigger={null}
            collapsed={false}
            theme={'dark'}
            collapsible
         >
            {AuthStore.width_screen < TAB_SIZE ? (
               <Sidebar {...props} sideBarContent={accountantMeta} />
            ) : (
               <SidebarContent {...props} sidebarCollapsed={true} sideBarContent={accountantMeta} />
            )}
         </Sider>
         <Layout>
            {AuthStore.width_screen < TAB_SIZE && (
               <div className="gx-linebar header-mobile-container">
                  <div>
                     <i className="gx-icon-btn icon icon-menu" onClick={handleOpenSidebar} />
                  </div>

                  <div className="center-img-content2">
                     <img src={Images.logo_white} className="img-responsive" alt="Logo" />
                  </div>

                  <div>
                     <UserProfile />
                  </div>
                  {/* <div>
                      <UserNotification />
                  </div> */}
               </div>
            )}
            <Content className={`gx-layout-content gx-main-content-wrapper`}>
               <AccountantRouters match={match} />
            </Content>
            {/* <CustomizerBar /> tam an di*/}
            <div
               style={{
                  position: 'absolute',
                  top: 4,
                  zIndex: 99,
                  background: '#272944',
                  width: 24,
                  height: 24,
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  display: 'flex',
                  borderTopRightRadius: 12,
                  borderBottomRightRadius: 12
               }}
               onClick={handleOpenSidebar}
            >
               <i className="icon icon-menu gx-d-block" style={{paddingTop:4,paddingRight:2,color:'#fff'}} />
            </div>
         </Layout>
      </Layout>
   )
})

export default LayoutAccountant
