import React, { Component, useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styled from 'styled-components'
import { CONFIG_URL } from 'src/helpers/constants'

type ImageProps = {
   images?: string[]
}

const ImageSliderStyle = styled.div`
   .slider-container {
      height: 500px;
   }
   .slider1 {
      .image {
         width: 100%;
         height: 400px;
         object-fit: contain;
      }
   }
   .slider2 {
      .slick-prev {
         left: -23px;
         z-index: 1;
         &:before {
            font-size: 22px;
         }
      }
      .slick-next {
         right: -22px;
         &:before {
            font-size: 22px;
         }
      }
      .slick-list {
         height: 62px !important;
         .slick-track {
            .slick-slide {
               border: 2px solid transparent;
               width: 62px !important;
               height: 62px !important;
               margin: 0 5px;
               cursor: pointer;
               &.slick-current {
                  border: 2px solid #038fde;
               }
               .image {
                  height: 60px !important;
                  width: 60px !important;
                  object-fit: contain;
               }
            }
         }
      }
   }
   @media only screen and (max-width: 1200px) {
      .slider-container {
         height: 400px;
      }
      .slider1 {
      }
      .slider2 {
         display: none;
      }
   }

   @media only screen and (max-width: 992px) {
      .slider-container {
         height: 400px;
      }
   }
   @media only screen and (max-width: 520px) {
      .slider-container {
         height: 270px;
      }
      .slider1 {
         .image {
            height: 270px;
         }
      }
      .slider2 {
         display: none;
      }
   }
`

const ImageSlick: React.FC<ImageProps> = ({ images }) => {
   console.log('images', images)

   const Image = [
      'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      'https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg',
      'https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg',
      'https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg',
      'https://gw.alipayobjects.com/zos/antfincdn/aPkFc8Sj7n/method-draw-image.svg',
      'https://gw.alipayobjects.com/zos/antfincdn/aPkFc8Sj7n/method-draw-image.svg',
      'https://gw.alipayobjects.com/zos/antfincdn/aPkFc8Sj7n/method-draw-image.svg',
      'https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp',
      'https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp',
      'https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp',
      'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
   ]

   const [nav1, setNav1] = useState(null)
   const [nav2, setNav2] = useState(null)
   let sliderRef1 = useRef(null)
   let sliderRef2 = useRef(null)

   useEffect(() => {
      setNav1(sliderRef1 as any)
      setNav2(sliderRef2 as any)
   }, [])

   const settings = {
      infinite: true,
      speed: 400,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      arrows: false
   }

   const responsive = {
      responsive: [
         {
            breakpoint: 1200,
            settings: {
               dots: false,
               arrows: false,
               infinite: false,
               swipe: false,
               focusOnSelect: false,
               centerMode: false
            }
         }
      ]
   }

   return (
      <ImageSliderStyle>
         <div className="slider-container">
            {images && images?.length > 1 ? (
               <Slider
                  className="slider1"
                  {...settings}
                  asNavFor={nav2 as any}
                  ref={(slider) => (sliderRef1 = slider as any)}
               >
                  {images &&
                     images?.map((item: any, index: any) => {
                        return (
                           <div key={index}>
                              <img className="image" src={item ? CONFIG_URL.IMAGE_URL + '/' + item : ''} />
                           </div>
                        )
                     })}
               </Slider>
            ) : images ? (
               <img className="image" src={images[0] ? CONFIG_URL.IMAGE_URL + '/' + images[0] : ''} />
            ) : (
               <></>
            )}
            {images && images?.length > 1 && (
               <Slider
                  className="slider2"
                  asNavFor={nav1 as any}
                  ref={(slider) => (sliderRef2 = slider as any)}
                  slidesToShow={images?.length}
                  dots={true}
                  arrows={true}
                  infinite={true}
                  swipe={false}
                  focusOnSelect={true}
                  centerMode={true}
                  {...responsive}
               >
                  {images &&
                     images?.map((item, index) => {
                        return (
                           <div key={index}>
                              <img className="image" src={item ? CONFIG_URL.IMAGE_URL + '/' + item : ''} />
                           </div>
                        )
                     })}
               </Slider>
            )}
         </div>
      </ImageSliderStyle>
   )
}

export default ImageSlick
