import { Route, Switch } from 'react-router-dom'
import NotFoundPage from '../404/NotFoundPage'
import DefaultAccountantScreen from '../default/DefaultAccountantScreen'
import { ROUTER_PATH } from './metaData'
import ProfileScreen from '../profile'
const AccountantRouters = (props: any) => {
   return (
      <Switch>
         <Route exact path={`/`} component={DefaultAccountantScreen} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.ProfileScreen}`} component={ProfileScreen} />

         <Route path="*" component={NotFoundPage} />
      </Switch>
   )
}

export default AccountantRouters
