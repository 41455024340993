import React from 'react'

import { useEffect, useState } from 'react'
import { Input, Select, Modal, Form, Checkbox, InputNumber, Button } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { validateEmail, validatePassword, validatePhone } from '../../helpers/functions'
import { useStore } from '../../hooks'
import { BANKS, ROLE, ROLES } from '../../helpers/constants'
import { debounce, trim } from 'lodash'
import { rule } from 'src/helpers/validateRule'
const { Option } = Select

const formItemLayout = {
   labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
   },
   wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
   }
}

const EmployeeDetailScreen = observer((props: any) => {
   const { t } = useTranslation()

   const { item, visible, onOk, onCancel, onlyView } = props
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [isChangePassword, setIsChangePassword] = useState(false)

   const [password, setPassword] = useState('')
   const [status, setStatus] = useState(parseInt(item?.status))
   const UserStore = useStore('UserStore')
   const [form] = Form.useForm()

   useEffect(() => {
      // getDataEmployee()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const onFinish = async (values: any) => {
      if (onlyView) {
         return
      }
      //  console.log('Received values of form: ', values, '\nitemne: ', JSON.stringify(item))
      // console.log('status ne: ', status)

      // console.log('Received values of form: ', values)

      let body: any = {
         role: values?.role || '',
         // email: values?.email || '',
         name: values?.name || '',
         phoneNumber: values?.phoneNumber || '',
         bankName: values?.bankName || '',
         bankNumber: values?.bankNumber || '',
         linkTelegram: values?.linkTelegram || '',
         note: values?.note || '',
         address: values?.address || '',
         linkFb: '',
         idCard: '',
         salary: values?.salary || 0,
         status
      }

      if (trim(values?.password)) {
         body.password = values?.password
      }
      const result = await UserStore.updateEmployees(body, item?.id)
      if (result) {
         form.resetFields()
         onOk()
      }
   }

   const onChangeIsChangePassword = (e: any) => {
      setIsChangePassword(e.target.checked)
   }

   const onChangeStatus = (e: any) => {
      const val = e.target.checked ? 1 : 0
      // console.log('val; ', val, 'e: ', e.target.checked)
      setStatus(val)
   }

   const changePass = (e: any) => {
      setPassword(e.target.value)
   }

   const prefixSelector = (
      <Form.Item name="prefix" noStyle>
         <Select disabled>
            <Option value="84">+84</Option>
         </Select>
      </Form.Item>
   )
   const renderContent = () => {
      return (
         <Form
            {...formItemLayout}
            form={form}
            name="CreatorDetail"
            onFinish={debounce(onFinish, 400)}
            initialValues={{
               // residence: ['zhejiang', 'hangzhou', 'xihu'],
               prefix: '+84'
            }}
            scrollToFirstError
         >
            <Form.Item name="role" initialValue={item?.role} label={t(GLOBAL_CLIENT.role)}>
               <Select placeholder="Chọn Loại tài khoản" disabled={onlyView}>
                  <Option value={ROLES.ACCOUNTANT}>{'Accountant'}</Option>
                  <Option value={ROLES.FULFILLMENT}>{'Fulfillment'}</Option>
                  <Option value={ROLES.MANAGER_ORDER}>{'Manager Order'}</Option>
                  <Option value={ROLES.MANAGER_STOCK}>{'Manager Stock'}</Option>
                  <Option value={ROLES.SELLER}>{'Seller'}</Option>
               </Select>
            </Form.Item>

            <Form.Item
               name="name"
               label={t(GLOBAL_CLIENT.fullname)}
               initialValue={item?.name}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.noName)
                  },
                  ({ getFieldValue }) => ({
                     validator(_, value) {
                        if (trim(value) !== '') {
                           return Promise.resolve()
                        }
                        return Promise.reject(t(GLOBAL_CLIENT.noTrim))
                     }
                  })
               ]}
            >
               <Input maxLength={30} />
            </Form.Item>
            <Form.Item
               name="phoneNumber"
               label={t(GLOBAL_CLIENT.phone)}
               initialValue={item?.phoneNumber}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.noPhone)
                  },
                  () => ({
                     validator(_, value) {
                        if (validatePhone(value)) {
                           return Promise.resolve()
                        }
                        return Promise.reject(t(GLOBAL_CLIENT.phoneValidate))
                     }
                  })
               ]}
            >
               <Input
                  // disabled
                  addonBefore={prefixSelector}
                  placeholder={t(GLOBAL_CLIENT.phoneEx)}
                  style={{ width: '100%' }}
                  maxLength={30}
               />
            </Form.Item>
            <Form.Item
               name="email"
               initialValue={item?.email}
               label={t(GLOBAL_CLIENT.txtEmail)}
               rules={[
                  {
                     required: true,
                     message: t(GLOBAL_CLIENT.noEmail)
                  },
                  () => ({
                     validator(_, value) {
                        if (validateEmail(value)) {
                           return Promise.resolve()
                        }
                        return Promise.reject(t(GLOBAL_CLIENT.emailValidate))
                     }
                  })
               ]}
            >
               <Input
                  disabled
                  // addonBefore={prefixSelector}
                  // placeholder={t(GLOBAL_CLIENT.)}
                  style={{ width: '100%' }}
                  maxLength={254}
               />
            </Form.Item>
            <Form.Item name="bankName" label={t(GLOBAL_CLIENT.bankName)} initialValue={item?.bankName}>
               <Select placeholder="Chọn ngân hàng" disabled={onlyView}>
                  {BANKS.map((item) => {
                     return (
                        <Option key={item?.id} value={item?.code}>
                           {item?.short_name}
                        </Option>
                     )
                  })}
               </Select>
            </Form.Item>
            <Form.Item
               name="accountBankName"
               label={t(GLOBAL_CLIENT.accountBankName)}
               initialValue={item?.accountBankName}
               // rules={[{ required: true, message: 'Vui lòng nhập tên chủ thẻ!' }]}
            >
               <Input placeholder={t(GLOBAL_CLIENT.noBankName)} maxLength={30} />
            </Form.Item>
            <Form.Item
               name="bankNumber"
               initialValue={item?.bankNumber}
               label={t(GLOBAL_CLIENT.bankAccount)}
               // rules={[{ required: true, message: 'Vui lòng nhập số tài khoản ngân hàng!' }]}
            >
               <Input placeholder={t(GLOBAL_CLIENT.noBankAccount)} maxLength={30} />
            </Form.Item>
            {/* <Form.Item
       name="cmtnd"
       initialValue={item?.cmtnd}
       label={t(GLOBAL_CLIENT.cmtnd)}
     >
       <Input maxLength={254}/>
     </Form.Item> */}
            <Form.Item
               name="linkTelegram"
               initialValue={item?.linkTelegram}
               label={'Telegram'}
               // rules={[{ required: true, message: 'Vui lòng nhập ngân hàng!' }]}
            >
               <Input maxLength={254} />
            </Form.Item>
            <Form.Item
               name="salary"
               initialValue={item?.salary}
               label={t(GLOBAL_CLIENT.basicSalary)}
               rules={[
                  {
                     required: false,
                     message: t(GLOBAL_CLIENT.vuilongnhapdungdinhdang)
                  }
               ]}
            >
               <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  max={100000000}
                  step={1000}
                  // onChange={() => onChangePrice}
               />
            </Form.Item>
            <Form.Item name="address" initialValue={item?.address} label={t(GLOBAL_CLIENT.address)}>
               <Input maxLength={254} />
            </Form.Item>
            <Form.Item name="note" initialValue={item?.note} label={t(GLOBAL_CLIENT.note)}>
               <Input maxLength={254} />
            </Form.Item>
            <Form.Item
               name="changePassword"
               label={t(GLOBAL_CLIENT.doimatkhau)}
               initialValue={isChangePassword}
               valuePropName="checked"
            >
               <Checkbox defaultChecked={isChangePassword ? true : false} onChange={onChangeIsChangePassword}>
                  {t(GLOBAL_CLIENT.ok)}
               </Checkbox>
            </Form.Item>
            {isChangePassword && (
               <Form.Item
                  name="password"
                  label={t(GLOBAL_CLIENT.password)}
                  rules={[
                     {
                        // required: true,
                        message: t(GLOBAL_CLIENT.noPassword)
                     },
                     () => ({
                        validator(_, value) {
                           if (value && validatePassword(value)) {
                              return Promise.resolve()
                           }
                           return Promise.reject(t(GLOBAL_CLIENT.dodaimatkhau))
                        }
                     })
                  ]}
                  hasFeedback
               >
                  <Input.Password onChange={changePass} placeholder={t(GLOBAL_CLIENT.enterPassword)} maxLength={30} />
               </Form.Item>
            )}
            {isChangePassword && password && (
               <Form.Item
                  name="confirm"
                  label={t(GLOBAL_CLIENT.xacnhanmatkhau)}
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                     rule.requiredConfirmPassword,
                     ({ getFieldValue }) => ({
                        validator(_, value) {
                           if (!value || getFieldValue('password') === value) {
                              return Promise.resolve()
                           }
                           return Promise.reject(new Error(t(GLOBAL_CLIENT.matkhaukhongtrungkhop)))
                        }
                     })
                  ]}
               >
                  <Input.Password placeholder={t(GLOBAL_CLIENT.reEnterPassword)} maxLength={30} />
               </Form.Item>
            )}
            {/* <Form.Item
                 name="address"
                 label={t(GLOBAL_CLIENT.address)}
             >
                 <Input maxLength={254}/>
             </Form.Item> */}
            <Form.Item
               name="status"
               label={t(GLOBAL_CLIENT.statusAccount)}
               initialValue={item?.status === 1 ? true : false}
               valuePropName="checked"
            >
               <Checkbox defaultChecked={item?.status === 1 ? true : false} onChange={onChangeStatus}>
                  {t(GLOBAL_CLIENT.active)}
               </Checkbox>
            </Form.Item>
         </Form>
      )
   }
   return !onlyView ? (
      <Modal
         width={'68%'}
         visible={visible}
         centered
         title={t(GLOBAL_CLIENT.detail)}
         okText={t(GLOBAL_CLIENT.edit)}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={onCancel}
         onOk={form.submit}
      >
         {renderContent()}
      </Modal>
   ) : (
      <Modal
         width={'68%'}
         visible={visible}
         centered
         title={t(GLOBAL_CLIENT.detail)}
         okText={t(GLOBAL_CLIENT.edit)}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={onCancel}
         onOk={form.submit}
         footer={[
            <>
               <Button key="back" onClick={onCancel}>
                  Đóng
               </Button>
            </>
         ]}
      >
         {renderContent()}
      </Modal>
   )
})

export default EmployeeDetailScreen
